import { useRef, useState } from "react";
import styled from "styled-components";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { IconButton, InputAdornment, Popover } from "@mui/material";
import dayjs from "dayjs";

import { CloseIcon as ClearIcon, CalendarIcon } from "shared/ui/icons";
import { useOpenableState } from "shared/lib/use-openable-state";

import { Input } from "./input";

export interface Range {
  startDate: Date | null;
  endDate: Date | null;
}

export interface DateRangeProps {
  value: Range | null;
  onChange(value: Range): void;
  className?: string;
  hideClearButton?: boolean;
  month?: number;
}

function DateRangeComponent(
  props: DateRangeProps & { month?: number }
): JSX.Element {
  const [focusedRange, setFocusedRange] = useState([0, 0]);
  const [range, setRange] = useState({
    startDate: props.value?.startDate ?? new Date(),
    endDate: props.value?.endDate ?? new Date(),
    key: "selection",
  });

  function handleChange(item) {
    setRange(item.selection);
    if (
      focusedRange[1] === 1 ||
      item.selection.startDate !== item.selection.endDate
    ) {
      props.onChange({
        startDate: item.selection.startDate,
        endDate: item.selection.endDate,
      });
    }
  }
  return (
    <Root>
      <DateRange
        ranges={[range]}
        months={props.month ?? 2}
        direction="horizontal"
        onChange={handleChange}
        focusedRange={focusedRange}
        onRangeFocusChange={setFocusedRange}
        moveRangeOnFirstSelection={false}
      />
      {!props.hideClearButton && <ClearButton {...props} />}
    </Root>
  );
}

function isEmptyRange(obj: Range | null): boolean {
  return !Object.values(obj).some((el) => el !== null);
}

function ClearButton(props: DateRangeProps): JSX.Element {
  const showClearButton = !isEmptyRange(props.value);
  return (
    <IconButton
      onClick={() =>
        props.onChange({
          startDate: null,
          endDate: null,
        })
      }
      sx={{
        display: () => (showClearButton ? "inline-flex" : "none"),
        paddingRight: "4px",
      }}
    >
      <ClearIcon />
    </IconButton>
  );
}

function DateRangeInputComponent(
  props: DateRangeProps & { label: string }
): JSX.Element {
  const dataRange = useOpenableState();
  const inputRef = useRef<HTMLElement>(null);
  const value = !isEmptyRange(props.value)
    ? `${
        props.value?.startDate
          ? dayjs(props.value.startDate).format("MM/DD/YYYY")
          : ""
      } - ${
        props.value?.endDate
          ? dayjs(props.value.endDate).format("MM/DD/YYYY")
          : ""
      }
  `
    : "";
  return (
    <>
      <Input
        className={props.className}
        label={props.label}
        ref={inputRef}
        readOnly={true}
        value={value}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <InputButtons>
                <IconButton
                  size="medium"
                  onClick={() => dataRange.open()}
                  sx={{ paddingRight: "4px" }}
                >
                  <CalendarIcon />
                </IconButton>
                <ClearButton {...props} />
              </InputButtons>
            </InputAdornment>
          ),
        }}
      />
      {dataRange.isOpened && (
        <Popover
          open={dataRange.isOpened}
          anchorEl={inputRef.current}
          onClose={dataRange.close}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <DateRangeSelector
            {...props}
            onChange={(value) => {
              props.onChange(value);
              dataRange.close();
            }}
          />
        </Popover>
      )}
    </>
  );
}

const InputButtons = styled.div`
  display: flex;
`;

const Root = styled.div`
  display: flex;
  align-items: start;
`;

export const DateRangeSelector = styled(DateRangeComponent)``;
export const DateRangeInput = styled(DateRangeInputComponent)``;
