import React from "react";
import styled from "styled-components";
import { ButtonBase, Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

import { useOpenableState } from "shared/lib/use-openable-state";
import { DowntownLogo } from "shared/ui/downtown-logo";
import { Avatar } from "shared/ui/avatar/avatar";

import { ReactComponent as LogoutIcon } from "./logout.svg";
import { ReactComponent as MenuIconSVG } from "./menu.svg";

import {
  NavigationContext,
  NavigationMenuIcon,
  NavigationMenuItem,
} from "./navigation-menu";
import { mediaPhone } from "../../styles/media";

interface Props {
  renderNavigation(): React.ReactNode;
  renderContent(): React.ReactNode;
  userLabel?: string;
  onSignOutClick(): void;
  user: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

export function AuthorizedPageTemplateMobile(props: Props): JSX.Element {
  const sideBar = useOpenableState();
  const userName = [props.user.firstName, props.user.lastName].join(" ");

  return (
    <NavigationContext.Provider value={{ onItemClick: sideBar.close }}>
      <Root>
        <Header>
          <Link to="/">
            <DowntownLogo $dark />
          </Link>

          <UserButton onClick={sideBar.open} name={userName} />
        </Header>
        <Content>{props.renderContent()}</Content>
      </Root>
      <Drawer anchor="right" open={sideBar.isOpened} onClose={sideBar.close}>
        <SideBar>
          <SideBarHeader>
            {props.userLabel ? (
              <UserTypeLabel>{props.userLabel}</UserTypeLabel>
            ) : (
              <div />
            )}
            <CloseButton onClose={sideBar.close}></CloseButton>
          </SideBarHeader>

          <UserInfo>
            <Avatar name={userName} />
            <div>
              <UserName>{userName}</UserName>
              <UserEmail>{props.user.email}</UserEmail>
            </div>
          </UserInfo>

          <Navigation>{props.renderNavigation()}</Navigation>

          <LogoutBlock>
            <NavigationMenuItem onClick={props.onSignOutClick}>
              <NavigationMenuIcon as={LogoutIcon} /> Logout
            </NavigationMenuItem>
          </LogoutBlock>
        </SideBar>
      </Drawer>
    </NavigationContext.Provider>
  );
}

const Header = styled.div`
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;

  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${DowntownLogo} {
    width: 160px;
    height: 50px;
  }
`;

const Content = styled.div`
  --pageContentPadding: 16px;
  padding: var(--pageContentPadding);
`;

const UserTypeLabel = styled.div`
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 4px 12px;
  color: #fbfcff;
  background: #5e88ef;
  border-radius: 0 600px 600px 0;
`;

const CloseButton = styled((props: { className?: string; onClose(): void }) => {
  return (
    <IconButton
      className={props.className}
      aria-label="close"
      onClick={props.onClose}
      sx={{
        size: 18,
        color: (theme) => theme.palette.primary.main,
      }}
    >
      <CloseIcon />
    </IconButton>
  );
})``;

const SideBarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 24px;

  ${CloseButton} {
    justify-self: end;
  }
`;

const UserName = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #0a1128;
`;

const UserEmail = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #5e616c;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;

  padding: 0 16px;

  ${Avatar} {
    width: 64px;
    height: 64px;

    font-size: 26px;

    margin-right: 16px;
  }
`;

const Navigation = styled.div``;

const LogoutBlock = styled.div`
  display: grid;
  border-top: 1px solid #e7ebf7;
  padding-top: 16px;

  ${mediaPhone} {
    margin-top: 40px;
  }
`;

const SideBar = styled.div`
  width: 100vw;
  padding-top: 24px;

  ${SideBarHeader} {
    margin-bottom: 32px;
  }

  ${UserInfo} {
    margin-bottom: 42px;
  }
`;

const Root = styled.div`
  background-color: #fff;
`;

function UserButton(props: { onClick(): void; name: string }) {
  return (
    <ButtonBase>
      <UserButtonRoot onClick={props.onClick}>
        <Avatar name={props.name} />
        <MenuIcon />
      </UserButtonRoot>
    </ButtonBase>
  );
}

const MenuIcon = styled(MenuIconSVG)`
  width: 24px;
  height: 24px;
`;

const UserButtonRoot = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 4px 15px 4px 4px;

  background: #f5fafa;
  border: 1px solid #cfe2f3;
  box-sizing: border-box;
  border-radius: 600px;

  color: #2d4fa1;

  ${Avatar} {
    width: 32px;
    height: 32px;

    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    color: #fbfcff;
  }
`;
