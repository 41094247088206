import { createEffect } from "effector";
import * as typed from "typed-contracts";

import { parseByStatus, requestFx } from "shared/api/request";
import { GenericErrors } from "shared/api/generated-api";

type GetReportCollection = {
  query?: {
    page?: number;
    itemsPerPage?: number;
    pagination?: boolean;
  };
};

const getReportCollectionOk = typed.object({
  id: typed.number,
  name: typed.string,
});

type GetReportCollectionDone = {
  status: "ok";
  answer: typed.Get<typeof getReportCollectionOk>;
};
type GetReportCollectionFail = GenericErrors;

export const getReportCollection = createEffect<
  GetReportCollection,
  GetReportCollectionDone,
  GetReportCollectionFail
>({
  async handler({ query }) {
    const name = "getReportCollection.body";
    const response = await requestFx({
      path: "/api/reservation-reports",
      method: "GET",
      query,
    });
    return parseByStatus(name, response, {
      200: ["ok", getReportCollectionOk],
    });
  },
});
