import { createForm } from "effector-react-form";
import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";
import { object, string } from "yup";

import { putAddonItem } from "@admin-app/shared/api/addons";
import { createValidator } from "shared/lib/form";
import { createApiErrorStatusStore, fromApi } from "shared/api";

import { requiredFieldValidationError } from "shared/config/error-text";

interface Addon {
  id: number;
  name: string;
}

export const editAddonForm = createForm({
  name: "Edit Addon Form",
  validate: createValidator(
    object({
      name: string().required(requiredFieldValidationError),
    })
  ),
  onSubmit: ({ values }) => editAddonFormFx(values),
});

export const $addon = createStore(null);

export const $formSubmitting = putAddonItem.pending;

const editAddonFormFx = attach({
  effect: createEffect(fromApi(putAddonItem)),
  source: $addon,
  // @ts-ignore
  mapParams: (formData: Addon, addon: Addon) => ({
    path: { id: addon.id },
    body: formData,
  }),
});

export const $serverErrorStatus = createApiErrorStatusStore(putAddonItem);

export const addonEdited = editAddonFormFx.done;

export const editButtonClicked = createEvent<Addon>();
export const modalEditClosed = createEvent();

export const $isModalEditOpened = createStore(false);

$isModalEditOpened
  .on(editButtonClicked, () => true)
  .on(addonEdited, () => false)
  .reset(modalEditClosed);

$addon.on(editButtonClicked, (_, addon) => addon);

sample({
  clock: editButtonClicked,
  source: $addon,
  // @ts-ignore
  fn: (data: Addon) =>
    data && {
      name: data.name,
    },
  target: editAddonForm.$values,
});
