import { createGate } from "effector-react";
import { attach, createEvent, createStore, sample } from "effector";

import { getListFromApi, getCompanyCollection } from "shared/api";
import { spreadListDataData } from "shared/lib/effector-paginatoin";
import { Company } from "@admin-app/shared/api/companies";

type CompanyType = Company;

export const Gate = createGate();
export const $companiesList = createStore<CompanyType[]>([]);

export const companyCreated = createEvent<CompanyType>();

const getCompaniesFx = attach({
  // @ts-ignore
  effect: getListFromApi(getCompanyCollection),
  mapParams: () => ({}),
});

$companiesList.on(companyCreated, (companies, newCompany) => [
  newCompany,
  ...companies,
]);

spreadListDataData({
  $items: $companiesList,
  effect: getCompaniesFx,
});

sample({
  clock: Gate.open,
  target: getCompaniesFx,
});
