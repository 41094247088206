import { useUnit } from "effector-react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { Form } from "shared/lib/form";
import {
  FloatInputField,
  InputField,
  PhoneField,
  SelectField,
  SubmitButton,
} from "shared/ui/ecosystems/forms";
import { mediaPhone } from "shared/ui/styles/media";

import {
  $formSubmitting,
  newCompanyForm,
  successModal,
  successModalActions,
  unSuccessModal,
  unSuccessModalActions,
  $isTypePercent,
} from "../model";

import { SuccessModal, UnSuccessModal } from "../../ui/modal";

export function NewCompanyForm(): JSX.Element {
  const [
    isFormSubmitting,
    isSuccessModalOpened,
    isUnSuccessModalOpened,
    isTypePercent,
  ] = useUnit([$formSubmitting, successModal, unSuccessModal, $isTypePercent]);

  const history = useHistory();

  return (
    <>
      <Form form={newCompanyForm}>
        <FormRoot>
          <FormSection>
            <FormLine>
              <InputField
                name="name"
                type="string"
                label="Enter company name *"
                maxLength={50}
              />
            </FormLine>
            <FormLine>
              <FloatInputField name="bookingFee" label="Booking fee *" />
              <SelectField
                name="bookingFeeType"
                label="Price type"
                options={[
                  { label: "Dollar", value: "constant" },
                  { label: "Percent", value: "percent" },
                ]}
              />
            </FormLine>
            <FormLine>
              {isTypePercent && (
                <FloatInputField
                  name="minimumBookingFee"
                  label="Minimal booking fee"
                />
              )}
              <InputField name="stripeAccountId" label="Stripe Account ID *" />
            </FormLine>
          </FormSection>
          <FormSection>
            <SubTitle>Create company manager</SubTitle>
            <FormLine>
              <InputField
                name="firstName"
                type="string"
                label="Enter first name *"
              />
              <InputField
                name="lastName"
                type="string"
                label="Enter last name *"
              />
            </FormLine>
            <FormLine>
              <InputField name="email" type="email" label="Enter email *" />
              <InputField
                name="password"
                type="password"
                label="Enter password *"
              />
              <PhoneField name="phone" label="Enter phone number *" />
            </FormLine>
          </FormSection>
          <Actions>
            <SubmitButton isSubmitting={isFormSubmitting}>
              Create company
            </SubmitButton>
          </Actions>
        </FormRoot>
      </Form>
      <SuccessModal
        isOpened={isSuccessModalOpened}
        onClose={() => {
          history.push(`/companies-list`);
          successModalActions.close();
        }}
        message="Company created successfully!"
      />
      <UnSuccessModal
        isOpened={isUnSuccessModalOpened}
        onClose={unSuccessModalActions.close}
      />
    </>
  );
}

const Actions = styled.div`
  display: flex;
  justify-content: center;
  ${SubmitButton} {
    width: 322px;
  }
`;

const SubTitle = styled.div`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
`;

const FormLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  ${mediaPhone} {
    grid-template-columns: 1fr;
  }
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const FormRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
