import { createEffect, sample } from "effector";
import { createForm } from "effector-react-form";
import { createGate } from "effector-react";

import { apiRequestFx } from "shared/api";

export const pageGate = createGate();

export const form = createForm({
  initialValues: {
    terms: "",
  },
  onSubmit: async ({ values }) => {
    await postTermsFx(values.terms);
  },
});

export const getTermFx = createEffect(async () => {
  const terms: { content: string } = await apiRequestFx({
    method: "GET",
    path: "/api/pages/terms",
  });

  return terms.content;
});

const postTermsFx = createEffect(async (content: string) => {
  await apiRequestFx({
    method: "PUT",
    path: "/api/pages/terms",
    body: {
      title: "terms",
      content,
    },
  });
});

sample({
  clock: pageGate.open,
  target: getTermFx,
});

sample({
  clock: getTermFx.doneData,
  target: form.setValue.prepend((content) => ({
    field: "terms",
    value: content,
  })),
});

export const $isFormSubmitting = postTermsFx.pending;
