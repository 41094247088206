import styled from "styled-components";
import { useGate, useStoreMap } from "effector-react";

import {
  createFormField,
  Select,
  SelectProps,
} from "shared/ui/ecosystems/forms";

import { $docksList, Gate } from "../model";
import { Dock } from "../types";

export type DockSelectorProps = Omit<SelectProps<string>, "options"> & {
  active?: boolean;
  company?: number | null;
  valueSelector?(dock: Dock): any;
};

export function DockSelectorComponent(props: DockSelectorProps): JSX.Element {
  useGate(Gate, props);
  const options = useStoreMap($docksList, (docks) =>
    docks.map((dock) => ({
      label: dock.name,
      value: props.valueSelector?.(dock) ?? dock["@id"],
    }))
  );

  return <Select {...props} options={options} />;
}

export const DockSelector = styled(DockSelectorComponent)``;

export const DockField = createFormField(DockSelector);
