import { RouteConfig } from "react-router-config";

import { redirect } from "shared/lib/react-router";

import { SignInPage } from "../pages/sign-in";
import { UsersListPage } from "../pages/users-list";
import { UsersListCustomersListPage } from "../pages/users-list/customers-list";
import { UsersListManagersListPage } from "../pages/users-list/managers-list";
import { CustomersListByIdPage } from "../pages/users-list/customers-list/$customerId";
import { ReservationsListPage } from "../pages/reservations-list";
import { AddUserPage } from "../pages/add-user";
import { AddOnsPage } from "../pages/add-ons";
import { CompaniesPage } from "../pages/companies-list";
import { CompaniesListAddPage } from "../pages/companies-list/add";
import { CompaniesListByIdEditPage } from "../pages/companies-list/$companyId/edit";
import { TermsAndConditionsEditPage } from "../pages/terms-and-conditions-edit";
import { ReportsPage } from "../pages/reports";
import { CitiesPage } from "../pages/cities";
import { AddCityPage } from "../pages/cities/add";
import { CityByIdEditPage } from "../pages/cities/$cityId/edit";

export const noAuthRoutes: RouteConfig[] = [
  {
    exact: true,
    path: "/",
    component: SignInPage,
  },
  {
    exact: true,
    path: "/sign-in",
    component: SignInPage,
  },

  {
    component: redirect("/"),
  },
];

export const authRoutes: RouteConfig[] = [
  {
    path: "/users-list",
    component: UsersListPage,
    routes: [
      {
        exact: true,
        path: "/users-list/managers-list",
        component: UsersListManagersListPage,
      },

      {
        exact: true,
        path: "/users-list/customers-list",
        component: UsersListCustomersListPage,
      },
      {
        exact: true,
        path: "/users-list",
        component: redirect("/users-list/customers-list"),
      },
    ],
  },

  {
    exact: true,
    path: "/customers-list/:customerId",
    component: CustomersListByIdPage,
  },
  {
    exact: true,
    path: "/reservations-list",
    component: ReservationsListPage,
  },
  {
    exact: true,
    path: "/add-user",
    component: AddUserPage,
  },
  {
    exact: true,
    path: "/add-ons",
    component: AddOnsPage,
  },
  {
    exact: true,
    path: "/companies-list",
    component: CompaniesPage,
  },
  {
    exact: true,
    path: "/companies-list/add",
    component: CompaniesListAddPage,
  },
  {
    exact: true,
    path: "/companies-list/:companyId/edit",
    component: CompaniesListByIdEditPage,
  },
  {
    exact: true,
    path: "/terms-and-conditions/edit",
    component: TermsAndConditionsEditPage,
  },
  {
    exact: true,
    path: "/reports",
    component: ReportsPage,
  },
  {
    exact: true,
    path: "/cities",
    component: CitiesPage,
  },
  {
    exact: true,
    path: "/cities/add",
    component: AddCityPage,
  },
  {
    exact: true,
    path: "/cities/:cityId/edit",
    component: CityByIdEditPage,
  },
  {
    component: redirect("/users-list/customers-list"),
  },
];
