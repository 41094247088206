import styled from "styled-components";
import { useGate, useStoreMap, useUnit } from "effector-react";
import qs from "qs";
import { Button as MaterialButton } from "@mui/material";

import { CompanySelector } from "@admin-app/entities/companies";
import { mediaPhone, useMedia } from "shared/ui/styles/media";
import { Card, CardTitle } from "shared/ui/card";
import { MetaTags } from "shared/lib/metaTags";
import { DateRangeInput as DateRangeInputComponent } from "shared/ui/ecosystems/forms";
import { DockSelector } from "entities/docks";
import { ReportTable } from "entities/reports";

import {
  $reportLinesTotalCount,
  $currentPage,
  $reportLines,
  $itemsPerPage,
  pageChanged,
  pageGate,
  itemsPerPageChanged,
  $dateOfReservation,
  dateOfReservationChanged,
  $dockId,
  dockIdChanged,
  $companyId,
  companyIdChanged,
  $filters,
} from "./model";

export function ReportsPage(): JSX.Element {
  const { isPhone } = useMedia();
  useGate(pageGate);
  const [
    reportLines,
    total,
    currentPage,
    rowsPerPage,
    dateOfReservation,
    dockId,
    companyId,
    filters,
  ] = useUnit([
    $reportLines,
    $reportLinesTotalCount,
    $currentPage,
    $itemsPerPage,
    $dateOfReservation,
    $dockId,
    $companyId,
    $filters,
  ]);
  const companySelected = useStoreMap($companyId, Boolean);

  return (
    <>
      <MetaTags title="Reports" />
      <Root as={Card}>
        <HeaderWrap>
          <Header>Reports</Header>
          <a
            href={`${
              process.env.RAZZLE_APP_API
            }/api/reservation-reports/download?${qs.stringify(filters, {
              skipNulls: true,
            })}`}
            target="_blank"
          >
            <ExportButton disabled={!(total > 0)}>Export</ExportButton>
          </a>
        </HeaderWrap>
        <Filters>
          <CompanyFilter
            label={"Company Name"}
            valueSelector={(company) => company.id}
            value={companyId}
            onChange={companyIdChanged}
            setFirstValueAsDefault={true}
          />
          <DockFilter
            label="Dock Name"
            valueSelector={(dock) => dock.id}
            value={dockId}
            onChange={dockIdChanged}
            company={companyId}
            active={companySelected}
            clearable
          />
          <DateRangeInput
            label="Date of reservation"
            month={isPhone ? 1 : 2}
            value={dateOfReservation}
            onChange={dateOfReservationChanged}
            hideClearButton
          />
        </Filters>

        <ReportTable
          reportLines={reportLines}
          total={total}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          pageChanged={pageChanged}
          itemsPerPageChanged={itemsPerPageChanged}
        />
      </Root>
    </>
  );
}

const Header = styled(CardTitle)`
  font-size: 36px;
  line-height: 48px;
  ${mediaPhone} {
    font-size: 24px;
    line-height: 28px;
  }
`;
const ExportButton = styled(MaterialButton)`
  white-space: nowrap;
  width: 180px;
  ${mediaPhone} {
    width: 160px;
    min-width: 160px;
  }
`;

const DockFilter = styled(DockSelector)`
  min-width: 140px;
  ${mediaPhone} {
    width: 100%;
  }
`;

const CompanyFilter = styled(CompanySelector)`
  min-width: 140px;
  ${mediaPhone} {
    width: 100%;
  }
`;

const DateRangeInput = styled(DateRangeInputComponent)`
  ${mediaPhone} {
    width: 100%;
  }
`;
const Filters = styled.div`
  display: flex;
  gap: 24px;
  align-items: baseline;
  ${mediaPhone} {
    flex-direction: column;
    gap: 12px;
  }
`;

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  ${mediaPhone} {
    flex-direction: column;
  }
`;

const Root = styled.div`
  ${Header} {
    margin-bottom: 16px;
  }
  ${mediaPhone} {
    ${Header} {
      margin-bottom: 24px;
    }
  }
`;
