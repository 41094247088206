import { createGate } from "effector-react";
import { createFieldArray, createForm } from "effector-react-form";
import { attach, createEffect, createEvent, sample } from "effector";

import { createOpenAbleState } from "shared/lib/effector-openable-state";

import { object, string } from "yup";
import { putCity, getCity } from "@admin-app/shared/api/cities";
import { createValidator } from "shared/lib/form";
import { fromApi } from "shared/api";
import { requiredFieldValidationError } from "shared/config/error-text";
import { createRouteParamStore } from "shared/lib/effector-router-params";

import {
  addImage as addHeroImage,
  imageUploaded as heroImageUploaded,
} from "../ui/upload-hero-image.model";
import {
  addImage as addCardImage,
  imageUploaded as cardImageUploaded,
} from "../ui/upload-card-image.model";

interface City {
  id: number;
  name: string;
  state: string;
  description: string;
  heroImage: {
    id: number;
    url: string;
  };
  cardImage: {
    id: number;
    url: string;
  };
}

export const editFormGate = createGate();

const $cityId = createRouteParamStore({
  name: "cityId",
  gate: editFormGate,
});

export const editCityForm = createForm({
  name: "Edit City Form",
  validate: createValidator(
    object({
      name: string().required(requiredFieldValidationError),
      state: string().required(requiredFieldValidationError),
      description: string(),
      heroImage: string().required("Please, upload an image."),
      cardImage: string().required("Please, upload an image."),
    })
  ),
  onSubmit: ({ values }) => editCityFx(values),
});

export const fieldArray = createFieldArray({
  form: editCityForm,
});

export const $formSubmitting = putCity.pending;

const getCityFx = attach({
  effect: createEffect(fromApi(getCity)),
  source: $cityId,
  mapParams: (_, cityId) => ({ path: { id: cityId! + "" } }),
});

const editCityFx = attach({
  effect: createEffect(fromApi(putCity)),
  source: $cityId,
  mapParams: (formData: City, cityId) => ({
    path: { id: cityId! + "" },
    body: {
      ...formData,
    },
  }),
});

editCityForm.$values.on(getCityFx.doneData, (_, data) => data);

export const [successModal, successModalActions] = createOpenAbleState();
export const [unSuccessModal, unSuccessModalActions] = createOpenAbleState();

export const cityEdited = editCityFx.done;

export const editButtonClicked = createEvent<City>();

editCityForm.$values.on(getCityFx.doneData, (_, data) => ({
  ...data,
  heroImage: (data.heroImage as any)["@id"],
  cardImage: (data.cardImage as any)["@id"],
}));

sample({
  // @ts-ignore
  clock: getCityFx.doneData,
  filter: (city) => !!city.heroImage,
  fn: (city) => city.heroImage,
  target: addHeroImage,
});

sample({
  // @ts-ignore
  clock: getCityFx.doneData,
  filter: (city) => !!city.cardImage,
  fn: (city) => city.cardImage,
  target: addCardImage,
});

sample({
  clock: heroImageUploaded,
  fn: (value) => ({ field: "heroImage", value }),
  target: editCityForm.setValue,
});

sample({
  clock: cardImageUploaded,
  fn: (value) => ({ field: "cardImage", value }),
  target: editCityForm.setValue,
});

sample({
  clock: cityEdited,
  target: successModalActions.open,
});

sample({
  clock: editCityFx.fail,
  target: unSuccessModalActions.open,
});

sample({
  clock: editFormGate.open,
  target: getCityFx,
});
