import styled from "styled-components";

import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as LogoDark } from "./logo-dark.svg";

function DowntownLogoComponent(props: {
  className?: string;
  $dark?: boolean;
}): JSX.Element {
  return <Logo className={props.className} />;
}

export const DowntownLogo = styled(DowntownLogoComponent).attrs((props) => ({
  as: props.$dark ? LogoDark : Logo,
}))`
  width: 140px;
  height: 44px;
`;
