import React from "react";
import styled from "styled-components";
import { Dialog } from "@mui/material";
import { Form as FormType } from "effector-react-form/types/ts";

import { Form } from "shared/lib/form";
import { CloseButton } from "shared/ui/modals";
import { mediaDesktop, mediaPhone, useMedia } from "shared/ui/styles/media";

import { ArrowLeftIcon } from "shared/ui/icons";

import { SubmitButton } from "../../atoms/button";

interface Props {
  form?: FormType;
  isOpened: boolean;
  onClose(): void;
  renderTitle(): React.ReactNode;
  renderSubtitle?(): React.ReactNode;
  renderContent(): React.ReactNode;
  renderActions?(): React.ReactNode;
  className?: string;
}

export function FormModalTemplate(props: Props): JSX.Element {
  const { isPhone } = useMedia();

  return (
    <ModalForm
      className={props.className}
      open={props.isOpened}
      onClose={props.onClose}
      fullScreen={isPhone}
    >
      {props.form ? (
        <Form form={props.form}>
          <ContentWrapper {...props} />
        </Form>
      ) : (
        <ContentWrapper {...props} />
      )}
    </ModalForm>
  );
}

function ContentWrapper(props: Omit<Props, "form">): JSX.Element {
  const { isPhone } = useMedia();
  return (
    <>
      {!isPhone ? (
        <CloseButton onClick={props.onClose} />
      ) : (
        <MobileCloseButton as={ArrowLeftIcon} onClick={props.onClose} />
      )}
      <Title>{props.renderTitle()}</Title>
      {props.renderSubtitle && <SubTitle>{props.renderSubtitle()}</SubTitle>}
      <Content>{props.renderContent()}</Content>
      <Actions>{props.renderActions?.()}</Actions>
    </>
  );
}

const MobileCloseButton = styled.div`
  width: 28px;
  height: 28px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  font-family: "Kanit", sans-serif;
`;

const SubTitle = styled.div`
  max-width: 400px;
  color: #5e616c;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

const Content = styled.div``;

const Actions = styled.div`
  display: flex;
  justify-content: center;

  ${SubmitButton}:only-child {
    width: 254px;

    ${mediaPhone} {
      width: 100%;
    }
  }
`;

const ModalForm = styled(Dialog)`
  .MuiDialog-paper {
    ${mediaDesktop} {
      width: 580px;
      border-radius: 32px;
      padding: 24px;
    }

    ${mediaPhone} {
      background: #f5fafa;
      padding: 16px;
    }
  }
  position: relative;

  ${CloseButton} {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  ${Title} {
    margin-bottom: 16px;
  }
  ${SubTitle} {
    margin-bottom: 32px;
  }
  ${Content} {
    margin-bottom: 40px;
  }

  ${mediaPhone} {
    ${MobileCloseButton} {
      margin-bottom: 24px;
    }

    ${Title} {
      margin-bottom: 12px;
    }
  }
`;
