import { useGate, useUnit } from "effector-react";

import {
  InputField,
  StateField,
  SubmitButton,
  TextAreaField,
} from "shared/ui/ecosystems/forms";
import { Form } from "shared/lib/form";

import styled from "styled-components";
import { mediaPhone } from "shared/ui/styles/media";

import {
  $formSubmitting,
  editCityForm,
  editFormGate,
  successModal,
  successModalActions,
  unSuccessModal,
  unSuccessModalActions,
} from "../model";
import { SuccessModal, UnSuccessModal } from "../../ui/modal";
import { HeroImageField } from "../../ui/image-field/hero-image-field";
import { CardImageField } from "../../ui/image-field/card-image-field";

export function EditCityForm(): JSX.Element {
  useGate(editFormGate);

  const [isFormSubmitting, isSuccessModalOpened, isUnSuccessModalOpened] =
    useUnit([$formSubmitting, successModal, unSuccessModal]);

  return (
    <>
      <Form form={editCityForm}>
        <FormRoot>
          <FormLine>
            <InputField
              name="name"
              type="string"
              label="Enter city name *"
              maxLength={50}
            />
            <StateField name="state" label="Select state *" />
          </FormLine>

          <TextAreaField
            name="description"
            label="City description"
            minRows={3}
            maxRows={3}
          />

          <HeroImageField />
          <CardImageField />
        </FormRoot>
        <Actions>
          <SubmitButton isSubmitting={isFormSubmitting}>
            Update city
          </SubmitButton>
        </Actions>
      </Form>
      <SuccessModal
        isOpened={isSuccessModalOpened}
        onClose={successModalActions.close}
        message="City updated successfully!"
      />
      <UnSuccessModal
        isOpened={isUnSuccessModalOpened}
        onClose={unSuccessModalActions.close}
      />
    </>
  );
}

const Actions = styled.div`
  display: flex;
  justify-content: center;
  ${SubmitButton} {
    margin-top: 32px;
    width: 322px;
  }
`;

const FormLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  ${mediaPhone} {
    grid-template-columns: 1fr;
  }
`;

const FormRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
