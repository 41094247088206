import { attach, createEvent, sample } from "effector";

import { deleteCompanyItem } from "@admin-app/shared/api/companies";

export const deleteCompany = createEvent<number>();
export const companyDeleted = createEvent<number>();

const deleteCompanyFx = attach({
  effect: deleteCompanyItem,
  // @ts-ignore
  mapParams: (id: number) => ({ path: { id } }),
});

sample({
  clock: deleteCompany,
  target: deleteCompanyFx,
});

sample({
  clock: deleteCompanyFx.done,
  fn: ({ params }) => params,
  target: companyDeleted,
});
