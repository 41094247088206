import { EditCompanyForm } from "@admin-app/features/manage-companies/edit";
import { CompanyFormTemplate } from "@admin-app/features/manage-companies/ui/company-form-template";
import { MetaTags } from "shared/lib/metaTags";

export function CompaniesListByIdEditPage(): JSX.Element {
  return (
    <CompanyFormTemplate title="Edit company">
      <MetaTags title="Edit company" />
      <EditCompanyForm />
    </CompanyFormTemplate>
  );
}
