import { useGate, useStore } from "effector-react";
import styled from "styled-components";

import { mediaDesktop, mediaPhone, useMedia } from "shared/ui/styles/media";
import { AddIcon, TrashIcon } from "shared/ui/icons";
import { Button } from "shared/ui/button";
import { Card as CardComponent } from "shared/ui/card";
import { ActionDelete, ActionEdit, Table, TableActions } from "shared/ui/table";
import { deleteAddon } from "@admin-app/features/manage-addons/delete";

import {
  $isModalEditOpened,
  modalEditClosed,
  EditAddonModal,
  editButtonClicked,
} from "@admin-app/features/manage-addons/edit";

import {
  $isModalAddOpened,
  buttonAddClicked,
  modalAddClosed,
  NewAddonModal,
} from "@admin-app/features/manage-addons/add";

import { MetaTags } from "shared/lib/metaTags";
import { ConfirmModal, useConfirmModal } from "shared/ui/modals";

import {
  $addonsList,
  pageGate,
  itemsPerPageChanged,
  pageChanged,
  $currentPage,
  $addonsTotalCount,
  $itemsPerPage,
} from "./model";

export function AddOnsPage(): JSX.Element {
  useGate(pageGate);
  const { isDesktop } = useMedia();
  const isAddModalOpened = useStore($isModalAddOpened);
  const isModalEditOpened = useStore($isModalEditOpened);

  const addons = useStore($addonsList);
  const total = useStore($addonsTotalCount);
  const currentPage = useStore($currentPage);
  const rowsPerPage = useStore($itemsPerPage);
  const [confirmDeleting, confirmModalProps] = useConfirmModal(
    (addon: typeof addons[number]) => deleteAddon(addon.id),
    (addon) => ({
      title: "Delete add-on?",
      message: `Are you sure you want to delete add\u2011on - ${addon.name} ?`,
      Icon: TrashIcon,
      confirmText: "Yes, delete",
    })
  );

  return (
    <>
      <MetaTags title="Add-ons" />
      <Root>
        <Card>
          <Header>
            <TitleBlock>
              <Title>Add-ons</Title>
              <SubTitle>Create add-ons for managers</SubTitle>
            </TitleBlock>
            <AddButton Icon={AddIcon} onClick={buttonAddClicked}>
              {isDesktop && "Create add-on"}
            </AddButton>
          </Header>
          {addons && (
            <Table
              items={addons}
              columns={[
                {
                  header: "ID",
                  renderCell: (addon) => addon?.id ?? "",
                },
                {
                  header: "Add-on name",
                  renderCell: (addon) => addon.name,
                  align: "left",
                },
                {
                  header: "Action",
                  align: "right",
                  renderCell: (addon) => (
                    <TableActions>
                      <ActionEdit onClick={() => editButtonClicked(addon)} />
                      <ActionDelete onClick={() => confirmDeleting(addon)} />
                    </TableActions>
                  ),
                },
              ]}
              pagination={{
                total: total,
                onPageChange: pageChanged,
                onItemsPerPageChange: itemsPerPageChanged,
                currentPage: currentPage,
                itemsPerPage: rowsPerPage,
                rowsPerPageOptions: [5, 10, 20],
              }}
            />
          )}
        </Card>
      </Root>
      <ConfirmModal {...confirmModalProps} />
      <NewAddonModal isOpened={isAddModalOpened} onClose={modalAddClosed} />
      <EditAddonModal isOpened={isModalEditOpened} onClose={modalEditClosed} />
    </>
  );
}

const Title = styled.div`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  ${mediaPhone} {
    font-size: 24px;
    line-height: 28px;
  }
`;
const SubTitle = styled.div``;

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AddButton = styled(Button)`
  white-space: nowrap;
  ${mediaDesktop} {
    width: 180px;
  }
`;

const Card = styled(CardComponent)`
  ${mediaPhone} {
    padding: 0;
  }
`;
const Root = styled.div`
  ${Header} {
    margin-bottom: 32px;
  }
`;
