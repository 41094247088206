import { useGate, useStore } from "effector-react";
import styled from "styled-components";

import { EditClientReservationModal } from "features/edit-client-reservation";
import { createReservationsTable } from "features/reservation-searching";
import { CustomerPageTemplate } from "shared/ui/templates/customer-detailed-page-template";
import { dayjs } from "shared/lib/dayjs-ext/dayjs-ext";
import { Client } from "shared/api/types";
import { MetaTags } from "shared/lib/metaTags";

import { $client, clientPageGate, getClientsReservationsFx } from "./model";

const ReservationsTable = createReservationsTable({
  getReservationsFx: getClientsReservationsFx,
  hidePagination: true,
});

export function CustomersListByIdPage(): JSX.Element {
  useGate(clientPageGate);
  const client = useStore($client);

  return (
    <>
      <MetaTags title="Customer" />
      <CustomerPageTemplate
        client={client}
        renderReservationsList={() => <ReservationsTable />}
        renderClientCard={() => <ClientCard client={client} />}
        renderModalWindows={() => <EditClientReservationModal />}
      />
    </>
  );
}

function ClientCard(props: { client: Client | null }): JSX.Element {
  if (!props.client) return <></>;
  return (
    <>
      <ClientInfo>
        <Name>{`${props.client.firstName} ${props.client.lastName}`}</Name>
        <Lines>
          <Line name="Phone number" value={props.client.phone} />
          <Line name="Email" value={props.client.email} />
          <Line
            name="Registration date"
            value={
              props.client.createdAt
                ? dayjs(props.client.createdAt).format("MM/DD/YYYY")
                : ""
            }
          />
          <Line name="Spent money" value={`$ ${0}`} />
        </Lines>
      </ClientInfo>
    </>
  );
}

function Line(props: { name: string; value: string }) {
  return (
    <LineRoot>
      <LineName>{props.name}</LineName>
      <LineValue>{props.value}</LineValue>
    </LineRoot>
  );
}

const LineName = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #969899;
`;

const LineValue = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0a1128;
`;

const LineRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const Lines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Name = styled.div`
  color: #5e88ef;
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
`;

const ClientInfo = styled.div`
  ${Name} {
    margin-bottom: 24px;
  }
`;
