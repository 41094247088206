import { renderRoutes, RouteConfigComponentProps } from "react-router-config";
import styled from "styled-components";
import { useStore } from "effector-react";

import { Card as CardComponent } from "shared/ui/card";
import { mediaDesktop, mediaPhone, useMedia } from "shared/ui/styles/media";
import { NavTab, NavTabs } from "shared/ui/nav-tabs";
import { AddIcon } from "shared/ui/icons";
import { Button } from "shared/ui/button";
import {
  $isModalAddOpened,
  buttonAddClicked,
  modalAddClosed,
} from "features/new-customer/model";
import { NewCustomerModal } from "features/new-customer";

export function UsersListPage(props: RouteConfigComponentProps): JSX.Element {
  const { isDesktop } = useMedia();
  const isAddModalOpened = useStore($isModalAddOpened);
  return (
    <>
      <Root>
        <Card>
          <Header>
            <Title>List of Users</Title>
            <AddButton Icon={AddIcon} onClick={buttonAddClicked}>
              {isDesktop && "Add Customer"}
            </AddButton>
          </Header>

          <NavTabs centered>
            <NavTab
              label="Customers"
              value="/users-list/customers-list"
              to="/users-list/customers-list"
            />
            <NavTab
              label="Managers"
              value="/users-list/managers-list"
              to="/users-list/managers-list"
            />
          </NavTabs>

          {renderRoutes(props.route?.routes)}
        </Card>
      </Root>
      <NewCustomerModal isOpened={isAddModalOpened} onClose={modalAddClosed} />
    </>
  );
}
const Title = styled.div`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  ${mediaPhone} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AddButton = styled(Button)`
  white-space: nowrap;
  ${mediaDesktop} {
    width: 180px;
  }
`;

const Card = styled(CardComponent)`
  ${mediaPhone} {
    padding: 0;
  }
`;
const Root = styled.div`
  ${Header} {
    margin-bottom: 32px;
  }

  ${NavTabs} {
    margin-bottom: 30px;
  }
`;
