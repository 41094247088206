import React, { useRef } from "react";
import styled from "styled-components";

import {
  Collapse,
  IconButton,
  Popover as MaterialPopover,
  Table as MaterialTable,
  TableBody as MaterialTableBody,
  TableCell as MaterialTableCell,
  TableContainer as MaterialTableContainer,
  TableFooter as MaterialTableFooter,
  TableHead as MaterialTableHead,
  TablePagination as MaterialTablePagination,
  TableRow as MaterialTableRow,
  TableSortLabel as MaterialTableSortLabel,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { useOpenableState } from "shared/lib/use-openable-state";

import { TableProps, Pagination } from "./table";

import { ReactComponent as FilterIconSvg } from "./filter-icon.svg";

export function TableDesktop<T>(props: TableProps<T>): JSX.Element {
  const refs = useRef({});
  return (
    <TableContainer className={props.className}>
      <MaterialTable>
        <TableHead>
          <TableRow>
            {props.renderCollapsedContent && <TableCell />}
            {props.columns.map((column) => (
              <TableCell
                ref={(el) => (refs.current[column.id] = el)}
                key={column.header}
                align={column.align}
              >
                <CellTitle align={column?.align}>
                  {props.sorting && column.sortable ? (
                    <TableSortLabel
                      active={props.sorting.orderBy === column.id}
                      direction={
                        props.sorting.orderBy === column.id
                          ? props.sorting.order
                          : "asc"
                      }
                      onClick={() => props.onSortChange(column.id)}
                    >
                      {column.header}
                    </TableSortLabel>
                  ) : (
                    <>{column.header}</>
                  )}
                  {props.filtering && column.renderFilter && (
                    <FilterLabel
                      active={props?.activeFilters[column?.id]}
                      anchorEl={() => refs.current[column.id]}
                      renderContent={column.renderFilter}
                    />
                  )}
                </CellTitle>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map((item, index) => (
            <Row
              key={`Row${index}`}
              item={item}
              columns={props.columns}
              onRowClick={props.onRowClick}
              renderCollapsedContent={props.renderCollapsedContent}
              getRowStyle={props.getRowStyle}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            {props.pagination && <TablePagination {...props.pagination} />}
          </TableRow>
        </TableFooter>
      </MaterialTable>
    </TableContainer>
  );
}

interface FilterLabel {
  className?: string;
  active: boolean;
  renderContent: React.ReactNode;
}

function FilterLabel(props: FilterLabel): JSX.Element {
  const filter = useOpenableState();

  return (
    <>
      <IconButton size={"small"} onClick={filter.open}>
        <FilterIcon active={props.active} />
      </IconButton>
      {filter.isOpened && (
        <Popover
          open={filter.isOpened}
          anchorEl={props.anchorEl}
          onClose={filter.close}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Filter>{props.renderContent(filter.close)}</Filter>
        </Popover>
      )}
    </>
  );
}

function TablePaginationComponent(props: Pagination): JSX.Element {
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.onItemsPerPageChange(parseInt(event.target.value, 10));
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    props.onPageChange(newPage + 1);
  };

  return (
    <MaterialTablePagination
      rowsPerPageOptions={props.rowsPerPageOptions}
      count={props.total}
      rowsPerPage={props.itemsPerPage}
      page={props.currentPage - 1}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage={"Result per page"}
    />
  );
}

type RowProps<T> = Pick<
  TableProps<T>,
  "columns" | "onRowClick" | "renderCollapsedContent" | "getRowStyle"
> & { item: T; className?: string };

function RowComponent<T>(props: RowProps<T>) {
  const collapsedContent = useOpenableState();
  return (
    <>
      <TableRow
        className={props.className}
        onClick={() => props.onRowClick?.(props.item)}
        sx={props.getRowStyle?.(props.item)}
      >
        {props.renderCollapsedContent && (
          <TableCell onClick={collapsedContent.toggle}>
            <IconButton aria-label="expand row" size="small">
              {collapsedContent.isOpened ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
        )}
        {props.columns.map((column, index) => (
          <TableCell key={`${column.header}_${index}`} align={column.align}>
            {column.renderCell(props.item)}
          </TableCell>
        ))}
      </TableRow>
      {props.renderCollapsedContent && (
        <TableCell
          style={{ padding: "0 4px" }}
          colSpan={props.columns.length + 1}
        >
          <Collapse in={collapsedContent.isOpened} timeout="auto" unmountOnExit>
            <CollapsedContent>
              {props.renderCollapsedContent(props.item)}
            </CollapsedContent>
          </Collapse>
        </TableCell>
      )}
    </>
  );
}

const Popover = styled(MaterialPopover)`
  .MuiPopover-paper {
    overflow: visible;
  }
`;

const CollapsedContent = styled.div`
  background-color: #fff;
  padding: 25px 16px 8px;
`;

const TableCell = styled(MaterialTableCell)``;

const TableSortLabel = styled(MaterialTableSortLabel)<{ active?: boolean }>`
  & > .MuiTableSortLabel-icon {
    opacity: 1;
    color: ${(props) => (props.active ? "#3BB7B6 !important" : "#AFB9BB")};
  }
`;

const Filter = styled.div`
  min-width: 240px;
  background: #ffffff;
  box-shadow: 0 2px 20px rgba(25, 38, 69, 0.16);
  overflow: visible;
  padding: 9px 12px 6px;
  z-index: 10;
  display: flex;
  & > * {
    flex-grow: 1;
  }
  .MuiAutocomplete-paper {
    display: inline-flex;
    min-width: 216px;
  }
`;

const FilterIcon = styled(FilterIconSvg)<{ active: boolean }>`
  path {
    fill: ${(props) => (props.active ? "#3BB7B6" : "#AFB9BB")};
  }
`;
const CellTitle = styled.div<{
  align?: "left" | "right" | "center" | "justify";
}>`
  display: flex;
  position: relative;
  justify-content: ${(props) => {
    switch (props.align) {
      case "left":
        return "flex-start";
      case "center":
        return "center";
      case "right":
        return "flex-end";
      default:
        return "unset";
    }
  }};
`;

const Row = styled(RowComponent)`
  cursor: ${(props) => (props.onRowClick ? "pointer" : "auto")};
`;

const TableRow = styled(MaterialTableRow)``;

const TableHead = styled(MaterialTableHead)`
  height: 56px;
  ${TableCell} {
    color: #969899;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid #2f3895;
  }
`;

const TableBody = styled(MaterialTableBody)`
  background-color: #f7f9fc;
  ${TableRow} {
    height: 64px;
  }
  ${TableCell} {
    color: #0a1128;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid #e7ecf6;
  }
`;

const TableFooter = styled(MaterialTableFooter)`
  & .MuiTableCell-footer {
    border: none;
  }
`;

const TablePagination = styled(TablePaginationComponent)`
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  background-color: #f7f9fc;

  & .MuiSelectTablePagination-select {
    padding: 1px;
    border: 1px solid #2d4fa1;
    border-radius: 600px;
  }

  & .MuiTablePagination-selectLabel {
    color: #5e616c;
  }
`;

const TableContainer = styled(MaterialTableContainer)`
  border-radius: 0 0 24px 24px;
`;
