import { createEffect } from "effector";
import axios, { AxiosRequestConfig, AxiosError } from "axios";
import * as typed from "typed-contracts";

interface RequestParams {
  path: AxiosRequestConfig["url"];
  method: AxiosRequestConfig["method"];
  body?: AxiosRequestConfig["data"];
  query?: AxiosRequestConfig["params"];
}

export const http = axios.create({
  baseURL: process.env.RAZZLE_APP_API,
  withCredentials: true,
});

export const requestFx = createEffect(
  async ({ path, method, body, query }: RequestParams) => {
    return http({
      url: path,
      method,
      data: body,
      params: query,
    }).then(({ data, ...response }) => {
      return {
        ...response,
        body: data === "" ? null : data,
      };
    });
  }
);

export const apiRequestFx = createEffect<RequestParams, any, ApiError>(
  async ({ path, method, body, query }: RequestParams) => {
    try {
      const response = await requestFx({ path, method, body, query });
      return response.body;
    } catch (error: any) {
      if (error && error.isAxiosError) {
        const { response } = error as AxiosError;
        throw {
          status: response?.status,
          message: response?.data["hydra:description"] ?? response?.data,
        };
      }
    }
  }
);

export type ApiError<T = any> = {
  status: ErrorCodes;
  response: T;
};

type ErrorCodes =
  | 400
  | 401
  | 402
  | 403
  | 404
  | 405
  | 406
  | 422
  | 500
  | 501
  | 502
  | 503
  | 505;

export function parseByStatus<
  Variants extends string,
  Contracts extends Record<number, [Variants, typed.Contract<any>]>,
  Result extends {
    [Code in keyof Contracts]: Contracts[Code] extends [
      infer Status,
      typed.Contract<infer T>
    ]
      ? { status: Status; answer: T }
      : never;
  }
>(
  name: string,
  response: { status: number; body?: unknown },
  contracts: Contracts
): Result[Exclude<keyof Result, ErrorCodes>] {
  const contractObject = contracts[response.status];
  if (!contractObject) {
    throw {
      status: "unknown_status",
      error: {
        status: response.status,
        body: response.body,
      },
    };
  }
  const [status, contract] = contractObject;
  const answer = contract(name, response.body);
  if (answer instanceof typed.ValidationError) {
    throw { status: "validation_error", error: answer };
  }
  if (response.status >= 400) {
    throw { status, error: answer };
  }
  return { status, answer } as Result[Exclude<keyof Result, ErrorCodes>];
}
