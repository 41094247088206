import styled from "styled-components";
import { Button as MaterialButton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import { ModalLink } from "@client-app/shared/lib/modal-routes";

export interface ButtonProps {
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  type?: "button" | "submit";
  variant?: "text" | "outlined" | "contained";
  color?: "danger";
  Icon?: React.FunctionComponent;
  startIcon?: React.ReactNode;
}

function ButtonComponent(props: ButtonProps): JSX.Element {
  return (
    <MaterialButton
      classes={{
        root: props.className,
      }}
      onClick={props.onClick}
      type={props.type ?? "button"}
      variant={props.variant}
      color={props.color}
      startIcon={props.startIcon}
      disabled={props.disabled}
    >
      {props.Icon && <ButtonIcon as={props.Icon} />}
      {props.children}
    </MaterialButton>
  );
}

const ButtonIcon = styled.div`
  width: 24px;
  height: 24px;
  :not:last-child {
    margin-right: 8px;
  }
`;
export const Button = styled(ButtonComponent)``;

function LinkButtonComponent(props: ButtonProps & { to: string }) {
  return (
    <LinkButtonRoot as={Link} to={props.to} className={props.className}>
      <Button {...props}>{props.children}</Button>
    </LinkButtonRoot>
  );
}

export const LinkButton = styled(LinkButtonComponent)`
  text-decoration: none;
`;
const LinkButtonRoot = styled.div`
  ${Button} {
    width: 100%;
  }
`;

function ModalLinkButtonComponent(props: ButtonProps & { modal: string }) {
  return (
    <LinkButtonRoot
      as={ModalLink}
      modal={props.modal}
      className={props.className}
    >
      <Button {...props}>{props.children}</Button>
    </LinkButtonRoot>
  );
}

export const ModalLinkButton = styled(ModalLinkButtonComponent)`
  text-decoration: none;
  color: red;
`;
