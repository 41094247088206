import React from "react";
import styled from "styled-components";
import { Tab as MaterialTab, Tabs as MaterialTabs } from "@mui/material";
import { Link, matchPath, useLocation } from "react-router-dom";

export const NavTab: React.FunctionComponent<
  React.ComponentProps<typeof MaterialTab> & { to: string }
> = styled(MaterialTab).attrs({
  component: Link,
})``;

function NavTabsComponent(props: {
  children: React.ReactChild[];
  centered?: boolean;
  className?: string;
}) {
  if (!props.children) {
    return null;
  }

  // @ts-ignore
  const patterns = props.children.map((child) => child.props?.to);

  const routeMatch = useRouteMatch(patterns);
  const currentTab = routeMatch?.path;

  return (
    <MaterialTabs
      className={props.className}
      value={currentTab}
      centered={props.centered}
    >
      {props.children}
    </MaterialTabs>
  );
}

export const NavTabs = styled(NavTabsComponent)``;

function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}
