import React from "react";
import styled from "styled-components";

import { mediaPhone, useMedia } from "shared/ui/styles/media";
import { Sorting } from "shared/api/types";

import { TableDesktop } from "./table.desktop";
import { TableMobile } from "./table.mobile";
import { DeleteIcon, EditIcon } from "../icons";

export interface Pagination {
  total: number;
  currentPage: number;
  itemsPerPage: number;
  rowsPerPageOptions: number[];
  onPageChange(newPage: number): void;
  onItemsPerPageChange(newItemsPerPage: number | null): void;
}

export interface TableProps<T> {
  columns: {
    id?: string;
    header: string;
    align?: "left" | "right" | "center" | "justify";
    mobileAlign?: "start";
    renderCell(item: T): React.ReactNode | void;
    sortable?: boolean;
    renderFilter?(close: () => void): React.ReactNode;
    hide?: boolean;
  }[];
  items: T[];
  className?: string;
  moreLinkLabel?: string;
  moreLink?(item: T): string;
  renderCollapsedContent?(item: T): React.ReactNode;
  onRowClick?(item: T): void;
  pagination?: Pagination;
  getRowStyle?(item: T): { background: string };
  onSortChange?(id: string): void;
  sorting?: Sorting;
  filtering?: boolean;
  activeFilters?: Record<string, boolean>;
}

function TableComponent<T>(props: TableProps<T>): JSX.Element {
  const { isPhone } = useMedia();

  const columns = props.columns.filter((item) => !item.hide);
  return isPhone ? (
    <TableMobile {...props} columns={columns} />
  ) : (
    <TableDesktop {...props} columns={columns} />
  );
}

export const Table: typeof TableComponent = styled(TableComponent)``;

export const ActionDelete = styled(DeleteIcon)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  ${mediaPhone} {
    width: 40px;
    height: 40px;
  }
`;

export const ActionEdit = styled(EditIcon)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  ${mediaPhone} {
    width: 40px;
    height: 40px;
  }
`;

export const TableActions = styled.div.attrs({
  onClick: (e) => e.stopPropagation(),
})`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  gap: 12px;

  ${mediaPhone} {
    gap: 8px;
  }
`;
