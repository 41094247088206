import { attach } from "effector";

import { getReservationCollection, getListFromApi } from "shared/api";

export const getReservationsListFx = attach({
  // @ts-ignore
  effect: getListFromApi(getReservationCollection, {
    ignoreValidation: true,
  }),
  mapParams: (query) => ({
    query,
  }),
});
