import React from "react";
import styled from "styled-components";
import { Alert, FormHelperText } from "@mui/material";

interface Props {
  className?: string;
  children: React.ReactNode;
}

export function FormErrorComponent(props: Props): JSX.Element {
  return (
    <FormErrorRoot severity="error" className={props.className}>
      {props.children}
    </FormErrorRoot>
  );
}

export const FormError = styled(FormErrorComponent)``;
export const FormInlineError = styled(FormHelperText).attrs({ error: true })``;

const FormErrorRoot = styled(Alert)``;
