import { Suspense, useEffect, useState } from "react";
import styled from "styled-components";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

interface Props {
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

function toDraft(value: string) {
  const contentBlock = htmlToDraft(value);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const editorState = EditorState.createWithContent(contentState);
  return editorState;
}

export function RichEditInputComponent(props: Props): JSX.Element {
  const [editorState, setState] = useState(toDraft(""));

  function getRawValue() {
    if (editorState) {
      return draftToHtml(convertToRaw(editorState.getCurrentContent()));
    } else {
      return "";
    }
  }

  useEffect(() => {
    if (props.value && props.value !== getRawValue()) {
      setState(toDraft(props.value));
    }
  }, [props.value]);

  const debouncedEditorState = useDebounce(editorState, 500);
  useEffect(() => {
    props.onChange(getRawValue());
  }, [debouncedEditorState]);

  return (
    <Suspense fallback={() => null}>
      <EditorRoot className={props.className}>
        <Editor
          editorState={editorState}
          toolbarClassName="toolbar"
          wrapperClassName="wrapper"
          editorClassName={"editor"}
          onEditorStateChange={setState}
          toolbar={toolbarOptions}
        />
      </EditorRoot>
    </Suspense>
  );
}

export const RichEditInput = styled(RichEditInputComponent)``;

const EditorRoot = styled.div`
  border: 1px solid #e9ecef;
  border-radius: 6px;

  .wrapper {
    border-radius: 6px;
  }

  .toolbar {
    border: none;
    border-radius: 6px;
  }

  .editor {
    padding-inline: 16px;
    max-height: 500px;
    overflow: auto;
  }

  .rdw-dropdown-wrapper,
  .rdw-option-wrapper {
    border: 1px solid transparent;
    box-shadow: none !important;
    box-sizing: border-box;
  }

  .rdw-option-active {
    border-color: #e9ecef;
    border-radius: 6px;
  }

  .rdw-dropdown-optionwrapper {
    box-shadow: none !important;
    overflow-y: auto;
  }

  .DraftEditor-editorContainer,
  .rdw-text-align-dropdown {
    z-index: auto;
  }
`;

const toolbarOptions = {
  options: ["history", "blockType", "textAlign", "inline", "list", "link"],
  inline: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["bold", "italic", "underline"],
  },
  blockType: {
    inDropdown: true,
    options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"],
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
  },
  textAlign: {
    inDropdown: true,
  },
  list: {
    inDropdown: false,
    options: ["unordered", "ordered"],
  },
  link: {
    defaultTargetOption: "_blank",
    options: ["link"],
  },
};

function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}
