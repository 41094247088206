import styled from "styled-components";
import { useGate, useStoreMap } from "effector-react";

import {
  createFormField,
  Select,
  SelectProps,
} from "shared/ui/ecosystems/forms";
import { Company } from "@admin-app/shared/api/companies";

import { $companiesList, Gate } from "../model";

type Props = Omit<SelectProps<string>, "options"> & {
  valueSelector?(company: Company): any;
};

export function CompanySelectorComponent(props: Props): JSX.Element {
  useGate(Gate);
  const options = useStoreMap($companiesList, (companies) =>
    companies.map((company) => ({
      label: company.name,
      value: props.valueSelector?.(company) ?? company["@id"],
    }))
  );

  return <Select {...props} options={options} />;
}

export const CompanySelector = styled(CompanySelectorComponent)``;

export const CompanyField = createFormField(CompanySelector);
