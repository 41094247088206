import styled from "styled-components";
import { useGate, useStore } from "effector-react";

import { mediaPhone } from "shared/ui/styles/media";
import { SearchInput } from "shared/ui";
import { ActionDelete, Table, TableActions } from "shared/ui/table";
import { useConfirmModal, ConfirmModal } from "shared/ui/modals/confirm-modal";
import { TrashIcon } from "shared/ui/icons";
import { deleteManager } from "@admin-app/features/manage-managers";
import { MetaTags } from "shared/lib/metaTags";

import {
  $managersTotalCount,
  $currentPage,
  $managersList,
  $itemsPerPage,
  pageChanged,
  pageGate,
  itemsPerPageChanged,
  $searchQuery,
  searchQueryChanged,
} from "./model";

export function UsersListManagersListPage(): JSX.Element {
  useGate(pageGate);
  const searchQuery = useStore($searchQuery);
  const pageData = useStore($managersList);
  const total = useStore($managersTotalCount);
  const currentPage = useStore($currentPage);
  const rowsPerPage = useStore($itemsPerPage);

  const [confirmDeleting, confirmModalProps] = useConfirmModal(
    (manager: typeof pageData[number]) => deleteManager(manager.id),
    (manager) => ({
      message: `Are you sure you want to delete ${manager.firstName} ${manager.lastName}?`,
    })
  );

  return (
    <>
      <MetaTags title="Managers" />
      <Root>
        <Search
          searchQuery={searchQuery}
          searchQueryChanged={searchQueryChanged}
        />
        {pageData && (
          <Table
            columns={[
              {
                header: "ID",
                renderCell: (manager) => manager.id,
              },
              {
                header: "Name",
                renderCell: (manager) =>
                  `${manager.firstName} ${manager.lastName}`,
              },
              {
                header: "Dock Company",
                align: "justify",
                renderCell: (manager) =>
                  manager.company ? manager.company.name : null,
              },
              {
                header: "Email",
                align: "left",
                renderCell: (manager) => manager.email,
              },
              {
                header: "Phone",
                align: "left",
                renderCell: (manager) => manager.phone ?? "",
              },
              {
                header: "Action",
                align: "center",
                renderCell: (manager) => (
                  <TableActions>
                    <ActionDelete onClick={() => confirmDeleting(manager)} />
                  </TableActions>
                ),
              },
            ]}
            items={pageData}
            pagination={{
              total: total,
              onPageChange: pageChanged,
              onItemsPerPageChange: itemsPerPageChanged,
              currentPage: currentPage,
              itemsPerPage: rowsPerPage,
              rowsPerPageOptions: [5, 10, 20],
            }}
          />
        )}

        <ConfirmModal
          {...confirmModalProps}
          title={"Delete manager?"}
          Icon={TrashIcon}
          confirmText="Yes, delete"
        />
      </Root>
    </>
  );
}

function SearchComponent(props: {
  className?: string;
  searchQuery: string | null;
  searchQueryChanged(): void;
}): JSX.Element {
  const searchQuery = props.searchQuery;
  return (
    <SearchInput
      className={props.className}
      label="Search"
      value={searchQuery ? searchQuery : ""}
      onChange={props.searchQueryChanged}
    />
  );
}

const Search = styled(SearchComponent)`
  width: 290px;
  ${mediaPhone} {
    width: 100%;
  }
`;

const Root = styled.div`
  ${mediaPhone} {
    ${Table} {
      margin: 0 calc(var(--pageContentPadding) * -1);
    }
  }
  display: flex;
  flex-direction: column;

  ${Search} {
    margin-bottom: 24px;
    ${mediaPhone} {
      margin-bottom: 16px;
    }
  }
`;
