import { createEffect, Effect } from "effector";

export * from "./generated-api";
export * from "./payment-api";
export {
  loginApiErrorsByStatuses,
  signUpApiErrorsByStatuses,
} from "./authentication-api-errors-by-statuses";
export { addUserApiErrorsByStatuses } from "./add-user-api-errors-by-statuses";
export { addReservationApiErrorsByStatuses } from "./add-reservation-api-errors-by-statuses";

export { createApiErrorStatusStore } from "./get-server-errors";
export { http, apiRequestFx } from "./request";
export type { ApiError } from "./request";

export function fromApi<T, D, E>(
  effect: Effect<T, { answer: D; status: any }, E>
): Effect<T, D> {
  return createEffect(async (args1: T, args2?: T): Promise<D> => {
    const args: T = args2 ?? args1;
    try {
      const data = await effect(args);
      return data.answer;
    } catch (e: any) {
      if (
        e.error?.name === "ObjectValidationError" ||
        e.error?.name === "ValidationError"
      ) {
        return e.error.value;
      } else {
        throw e;
      }
    }
  });
}

interface HydraPagination<D> {
  "hydra:member": D[];
  totalItems?: number;
}

interface Pagination<T> {
  items: T[];
  totalCount: number;
}

export function getListFromApi<T, D, E>(
  effect: Effect<
    T,
    {
      answer: HydraPagination<D>;
      status: any;
    },
    E
  >
): (params: T, params2?: T) => Promise<Pagination<D>> {
  return async (args1: T, args2?: T): Promise<Pagination<D>> => {
    const args: T = args2 ?? args1;

    try {
      const data = await effect(args);

      return convertHydraPaginationToPagination(data.answer);
    } catch (e: any) {
      if (e.error?.name === "ObjectValidationError") {
        return convertHydraPaginationToPagination(e.error.value);
      } else {
        throw e;
      }
    }
  };
}

function convertHydraPaginationToPagination<T>(
  data: HydraPagination<T>
): Pagination<T> {
  return {
    items: data["hydra:member"],
    totalCount: data["hydra:totalItems"] ?? 0,
  };
}
