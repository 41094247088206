import { Event } from "effector";
import { dateFormat, timeFormat } from "shared/lib/date-format";

import { Table } from "shared/ui/table";
import styled from "styled-components";

import { ReportLine } from "./types";

interface Props {
  reportLines: ReportLine[];
  total: number;
  rowsPerPage: number;
  currentPage: number;
  pageChanged: Event<number>;
  itemsPerPageChanged: Event<number>;
}

export function ReportTable(props: Props): JSX.Element {
  return (
    <>
      <Table
        columns={[
          {
            id: "id",
            header: "ID",
            renderCell: (reportLine: ReportLine) => {
              return reportLine.id;
            },
          },
          {
            id: "created_at",
            header: "Created At",
            renderCell: (reportLine) => dateFormat(reportLine.createdAt),
          },
          {
            id: "status",
            header: "Status",
            renderCell: (reportLine) => {
              return reportLine.status;
            },
          },
          {
            id: "time_from",
            header: "Time From",
            renderCell: (reportLine) =>
              `${dateFormat(reportLine.timeFrom)} ${timeFormat(
                reportLine.timeFrom
              )}`,
          },
          {
            id: "time_to",
            header: "Time To",
            renderCell: (reportLine) =>
              `${dateFormat(reportLine.timeTo)} ${timeFormat(
                reportLine.timeTo
              )}`,
          },
          {
            id: "dock_id",
            header: "Dock Id",
            renderCell: (reportLine) => {
              return reportLine.dockId;
            },
          },
          {
            id: "dock_name",
            header: "Dock Name",
            renderCell: (reportLine) => reportLine.dockName,
          },
          {
            id: "dock_price",
            header: "Dock Price",
            renderCell: (reportLine) => `$${reportLine.dockPrice}`,
          },
          {
            id: "booking_fee",
            header: "Booking Fee",
            renderCell: (reportLine) => `$${reportLine.bookingFee}`,
          },

          {
            id: "add_on",
            header: "Add On",
            align: "right",
            renderCell: (reportLine) => (
              <Addons>
                {reportLine.addons.map((addon) => (
                  <AddonLine>
                    {addon.name} ${addon.price}
                  </AddonLine>
                ))}
              </Addons>
            ),
          },
          {
            id: "dock_tax",
            header: "Dock Tax",
            renderCell: (reportLine) => `$${reportLine.dockTax}`,
          },
          {
            id: "total_price",
            header: "Total Price",
            renderCell: (reportLine) => `$${reportLine.totalPrice}`,
          },
        ]}
        items={props.reportLines}
        pagination={{
          total: props.total,
          onPageChange: props.pageChanged,
          onItemsPerPageChange: props.itemsPerPageChanged,
          itemsPerPage: props.rowsPerPage,
          currentPage: props.currentPage,
          rowsPerPageOptions: [5, 10, 20],
        }}
      />
    </>
  );
}

const Addons = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddonLine = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
