import React from "react";
import styled, { css } from "styled-components";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

export interface CheckBoxProps {
  children: React.ReactNode;
  className?: string;
  value: boolean;
  onChange(value: boolean): void;
  error?: string | null;
  align?: "start" | "center";
}

export const CheckBox = styled((props: CheckBoxProps) => (
  <FormControl error={!!props.error} className={props.className}>
    <FormControlLabel
      classes={{
        root: props.className,
      }}
      control={
        <>
          <Checkbox
            checked={props.value}
            onChange={(e) => props.onChange(e.target.checked)}
          />
        </>
      }
      label={props.children as string}
      labelPlacement="end"
    />
    <FormHelperText>{props.error}</FormHelperText>
  </FormControl>
))`
  &.MuiFormControlLabel-root {
    align-items: center;
    margin-right: 0;
  }

  ${(props) =>
    props.align === "start" &&
    css`
      .MuiCheckbox-root {
        padding-top: 2px;
      }

      &.MuiFormControlLabel-root {
        align-items: start;
      }
    `}
`;
