import styled from "styled-components";
import { useHistory } from "react-router";
import { useGate, useStore } from "effector-react";

import { dayjs } from "shared/lib/dayjs-ext";
import { mediaPhone } from "shared/ui/styles/media";
import { SearchInput } from "shared/ui";
import { Table } from "shared/ui/table";

import { MetaTags } from "shared/lib/metaTags";

import {
  $clientsTotalCount,
  $currentPage,
  $clientsList,
  $itemsPerPage,
  pageChanged,
  pageGate,
  itemsPerPageChanged,
  $searchQuery,
  searchQueryChanged,
} from "./model";

export function UsersListCustomersListPage(): JSX.Element {
  useGate(pageGate);
  const history = useHistory();
  const clients = useStore($clientsList);
  const total = useStore($clientsTotalCount);
  const currentPage = useStore($currentPage);
  const rowsPerPage = useStore($itemsPerPage);
  useStore($searchQuery);
  return (
    <Root>
      <MetaTags title="Customers" />
      <Search />
      {clients && (
        <Table
          columns={[
            {
              header: "ID",
              renderCell: (client) => client?.id ?? "",
            },
            {
              header: "Name",
              renderCell: (client) => `${client.firstName} ${client.lastName}`,
            },
            {
              header: "Registration Date",
              align: "justify",
              renderCell: (client) =>
                client.createdAt
                  ? dayjs(client.createdAt).format("MM/DD/YYYY")
                  : "",
            },
            {
              header: "Money Spent",
              align: "left",
              renderCell: (client) =>
                client.spentMoney ? `$ ${client.spentMoney}` : "",
            },
            {
              header: "Notes",
              align: "left",
              renderCell: (client) => client?.comment ?? "",
            },
          ]}
          items={clients}
          onRowClick={(client) => {
            history.push(`/customers-list/${client.id}`);
          }}
          pagination={{
            total: total,
            onPageChange: pageChanged,
            onItemsPerPageChange: itemsPerPageChanged,
            currentPage: currentPage,
            itemsPerPage: rowsPerPage,
            rowsPerPageOptions: [5, 10, 20],
          }}
          moreLink={(client) => `/customers-list/${client.id}`}
          moreLinkLabel="More information"
        />
      )}
    </Root>
  );
}

function SearchComponent(props: { className?: string }): JSX.Element {
  const searchQuery = useStore($searchQuery);
  return (
    <SearchInput
      className={props.className}
      label="Search"
      value={searchQuery ? searchQuery : ""}
      onChange={searchQueryChanged}
    />
  );
}

const Search = styled(SearchComponent)`
  max-width: 290px;
  ${mediaPhone} {
    max-width: none;
    width: 100%;
  }
`;

const Root = styled.div`
  ${mediaPhone} {
    ${Table} {
      margin: 0 calc(var(--pageContentPadding) * -1);
    }
  }
  display: flex;
  flex-direction: column;

  ${Search} {
    margin-bottom: 8px;
    ${mediaPhone} {
      margin-bottom: 16px;
    }
  }
`;
