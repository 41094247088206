import { createForm } from "effector-react-form";
import { createEffect, createEvent, createStore } from "effector";
import { object, string } from "yup";

import { createValidator } from "shared/lib/form";
import {
  createApiErrorStatusStore,
  fromApi,
  postClientCollection,
} from "shared/api";
import {
  phoneValidationError,
  requiredFieldValidationError,
} from "shared/config/error-text";
import { Client } from "shared/api/types";

export const newCustomerForm = createForm({
  name: "New Customer Form",
  initialValues: {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
  },
  validate: createValidator(
    object({
      email: string()
        .email("Please, use a valid email address.")
        .required(requiredFieldValidationError),
      firstName: string().required(requiredFieldValidationError),
      lastName: string().required(requiredFieldValidationError),
      phone: string()
        .required(requiredFieldValidationError)
        .phone("US", phoneValidationError),
    })
  ),
  onSubmit: ({ values }) => createNewCustomerFx({ body: values }),
});

export const buttonAddClicked = createEvent();
export const modalAddClosed = createEvent();

export const $newCustomer = createStore<Client | null>(null);

export const $formSubmitting = postClientCollection.pending;

const createNewCustomerFx = createEffect(fromApi(postClientCollection));

export const $serverErrorStatus =
  createApiErrorStatusStore(postClientCollection);

export const newCustomerCreated = createNewCustomerFx.done;

export const $isModalAddOpened = createStore(false);

$newCustomer.on(createNewCustomerFx.doneData, (_, data) => data);

$isModalAddOpened.on(newCustomerCreated, () => false);

$isModalAddOpened.on(buttonAddClicked, () => true);
$isModalAddOpened.reset(modalAddClosed);
