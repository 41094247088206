import { lazy, object } from "yup";

export function createValidator(schema) {
  return function ({ values }) {
    try {
      schema.validateSync(values, {
        abortEarly: false,
      });
    } catch (err) {
      return (err.inner ?? []).reduce((formError, innerError) => {
        formError[innerError.path.replaceAll(/\[(.*)\]/g, ".$1")] =
          innerError.message;
        return formError;
      }, {});
    }
    return {};
  };
}

const mapRules = (map, rule) =>
  Object.keys(map).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {});

export function keyValueObject(schema) {
  return lazy((map) => object(mapRules(map, object(schema).nullable())));
}
