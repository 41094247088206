import "yup-phone-lite";
import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { ThemeAppProvider } from "./ui/theme";

export function AppContext(props: { children: React.ReactNode }): JSX.Element {
  return (
    <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeAppProvider>{props.children}</ThemeAppProvider>
      </LocalizationProvider>
    </HelmetProvider>
  );
}
