import React from "react";
import { Link as LinkComponent } from "react-router-dom";
import styled, { css } from "styled-components";
import {
  Collapse,
  IconButton,
  Pagination as MaterialPagination,
} from "@mui/material";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { Label } from "shared/ui/ecosystems/forms/atoms/label";
import { useOpenableState } from "shared/lib/use-openable-state";

import { TableProps, Pagination } from "./table";
import { Select, StyledOption } from "../molecules/select/select";

export function TableMobile<T>(props: TableProps<T>): JSX.Element {
  return (
    <TableRoot className={props.className}>
      {props.pagination && (
        <TableHeader>
          <PaginationParams {...props.pagination} />
        </TableHeader>
      )}
      <List>
        {props.items.map((item, index) => (
          <ListItem
            key={`listItem ${index}`}
            item={item}
            columns={props.columns}
            moreLink={props.moreLink}
            moreLinkLabel={props.moreLinkLabel}
            renderCollapsedContent={props.renderCollapsedContent}
            getRowStyle={props.getRowStyle}
          />
        ))}
      </List>
      <Footer>
        {props.pagination && <PageSelect {...props.pagination} />}
      </Footer>
    </TableRoot>
  );
}

function PaginationParams(props: Pagination): JSX.Element {
  const { total, currentPage, itemsPerPage } = props;

  const range = {
    from: total === 0 ? 0 : (currentPage - 1) * itemsPerPage + 1,
    to: Math.min(total, currentPage * itemsPerPage),
  };

  const handleChangeRowsPerPage = (value: number | null) => {
    props.onItemsPerPageChange(value);
  };

  return (
    <>
      <ResultPerPage>
        <Label>Result per page</Label>
        <Select value={props.itemsPerPage} onChange={handleChangeRowsPerPage}>
          {props.rowsPerPageOptions.map((option) => (
            <StyledOption key={option} value={option}>
              {option}
            </StyledOption>
          ))}
        </Select>
      </ResultPerPage>
      <DisplayedRows>
        <Range> {`${range.from} - ${range.to}`} </Range> of
        <Total> {props.total} </Total> results
      </DisplayedRows>
    </>
  );
}

function PageSelect(props: Pagination): JSX.Element {
  function handleChangePage(
    _: React.ChangeEvent<unknown> | null,
    page: number
  ) {
    props.onPageChange(page);
  }
  return (
    <MaterialPagination
      color="primary"
      size="large"
      page={props.currentPage}
      onChange={handleChangePage}
      siblingCount={0}
      boundaryCount={1}
      count={Math.ceil(props.total / props.itemsPerPage)}
    />
  );
}

type ListItemProps<T> = Pick<
  TableProps<T>,
  | "columns"
  | "moreLink"
  | "moreLinkLabel"
  | "renderCollapsedContent"
  | "getRowStyle"
> & { item: T };

export function ListItem<T>(props: ListItemProps<T>): JSX.Element {
  const collapsedContent = useOpenableState();
  return (
    <Item>
      <Content rowStyle={props.getRowStyle?.(props.item)}>
        {props.columns.map((column) => (
          <>
            <Line key={column.header} align={column.mobileAlign}>
              <Title>{column.header}</Title>
              <Value>{column.renderCell(props.item)} </Value>
            </Line>
          </>
        ))}
        {props.moreLink && (
          <MoreInformation to={props.moreLink(props.item)}>
            {props.moreLinkLabel}
          </MoreInformation>
        )}
        {props.renderCollapsedContent && (
          <CollapseToggleButton onClick={collapsedContent.toggle}>
            <IconButton aria-label="expand row" size="small">
              {collapsedContent.isOpened ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </CollapseToggleButton>
        )}
      </Content>
      {props.renderCollapsedContent && (
        <Collapse in={collapsedContent.isOpened} timeout="auto" unmountOnExit>
          <CollapsedContent>
            {props.renderCollapsedContent(props.item)}
          </CollapsedContent>
        </Collapse>
      )}
    </Item>
  );
}

const CollapseToggleButton = styled.div`
  text-align: center;
`;

const CollapsedContent = styled.div`
  padding: 16px 16px 16px 32px;
`;

const ResultPerPage = styled.div`
  display: flex;
  align-items: center;
`;

const Range = styled.div`
  color: #0a1128;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  padding: 0 4px;
`;

const Total = styled.div`
  color: #0a1128;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  padding: 0 4px;
`;

const DisplayedRows = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 23px;
  font-weight: 500;
  color: #5b5b5b;
`;

const Title = styled.div`
  color: #969899;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
`;

const Value = styled.div`
  color: #0a1128;
  text-align: right;
  font-size: 16px;
  line-height: 26px;
  white-space: normal;
  text-overflow: ellipsis;
`;
const MoreInformation = styled(LinkComponent)``;

const Line = styled.div<{ align?: "start" }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: ${(props) => props.align ?? "center"};
  gap: 12px;
  width: 100%;
  justify-content: space-between;
`;

const Content = styled.div<{ rowStyle?: { background: string } }>`
  padding: 24px 16px 16px;
  ${(props) =>
    props.rowStyle &&
    css`
      ${props.rowStyle}
    `}
`;

const Item = styled.div`
  width: 100%;
  position: relative;
  :after {
    content: "";
    position: absolute;
    left: 16px;
    bottom: -8px;
    width: calc(100% - 32px);
    height: 0;
    border-bottom: 1px dashed #2f3895;
  }

  ${Line} {
    margin-bottom: 8px;
  }
  ${MoreInformation} {
    padding-left: 0;
    color: #2d4fa1;
    text-decoration: underline;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
  }
`;

const List = styled.div`
  ${Item} {
    margin-bottom: 15px;
  }
`;

const TableHeader = styled.div`
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
  min-height: 54px;
  border-bottom: 1px solid #e7ebf7;
  ${Label} {
    margin-right: 12px;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
`;

const TableRoot = styled.div`
  padding: 16px 0;
  background-color: #f7f9fc;
  border-radius: 32px;
`;
