import { ModalIcon, SmallModalTemplate } from "shared/ui/modals";
import { DirectSendIcon } from "shared/ui/icons";
import styled from "styled-components";

import { Button } from "shared/ui/button";

export function InviteSentModal(props: {
  isOpened: boolean;
  onClose(): void;
  email: string;
}): JSX.Element {
  return (
    <SmallModalTemplate
      onClose={props.onClose}
      isOpened={props.isOpened}
      renderTitle={() => "Sending an invitation"}
      renderContent={() => (
        <InfoModalContent>
          <ModalIcon as={DirectSendIcon} />
          <EmailInfo>
            <EmailTitle>Recipient's email</EmailTitle>
            <Email>{props.email}</Email>
          </EmailInfo>

          <div>An email with instructions has been sent.</div>
        </InfoModalContent>
      )}
      renderActions={({ close }) => <Button onClick={close}>Back</Button>}
    />
  );
}
const EmailTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5e88ef;
`;

const Email = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #0a1128;
`;
const EmailInfo = styled.div`
  ${EmailTitle} {
    margin-bottom: 4px;
  }
`;

const InfoModalContent = styled.div`
  ${EmailInfo} {
    margin-bottom: 16px;
  }
`;
