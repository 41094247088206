import { createEffect } from "effector";
import { apiRequestFx } from "shared/api";

type CreateCityParams = {
  name: string;
  state: string;
  description: string;
  heroImage: string;
  cardImage: string;
};

export const createNewCityFx = createEffect(async (body: CreateCityParams) => {
  await apiRequestFx({
    method: "POST",
    path: "/api/cities",
    body,
  });
});
