import { createEffect } from "effector";
import { parseByStatus, requestFx } from "shared/api/request";
import { GenericErrors } from "shared/api";
import * as typed from "typed-contracts";

type GetCities = {
  query?: {
    page?: number;
    itemsPerPage?: number;
    pagination?: boolean;
  };
};

const getCitiesOk = typed.object({
  id: typed.number,
  name: typed.string,
  state: typed.string,
  description: typed.string,
  heroImage: typed.object({
    id: typed.number,
    url: typed.string,
  }),
  cardImage: typed.object({
    id: typed.number,
    url: typed.string,
  }),
});

type GetCitiesDone = {
  status: "ok";
  answer: typed.Get<typeof getCitiesOk>;
};
type GetCitiesFail = GenericErrors;

export const getCities = createEffect<GetCities, GetCitiesDone, GetCitiesFail>({
  async handler({ query }) {
    const name = "getCities.body";
    const response = await requestFx({
      path: "/api/cities",
      method: "GET",
      query,
    });
    return parseByStatus(name, response, {
      200: ["ok", getCitiesOk],
    });
  },
});

type PostCity = {
  body: {
    name: string;
    state: string;
    description: string;
    heroImage: string;
    cardImage: string;
  };
};
type PostCityDone = {
  status: "created";
  answer: typed.Get<typeof postCityCreated>;
};

type PostCityFail =
  | {
      status: "bad_request";
      error: typed.Get<typeof postCityBadRequest>;
    }
  | {
      status: "unprocessable_entity";
      error: typed.Get<typeof postCityUnprocessableEntity>;
    }
  | GenericErrors;

const postCityCreated = typed.object({
  id: typed.number,
  name: typed.string,
  state: typed.string,
  description: typed.string,
  heroImage: typed.object({
    id: typed.number,
    url: typed.string,
  }),
  cardImage: typed.object({
    id: typed.number,
    url: typed.string,
  }),
});

const postCityBadRequest = typed.nul;
const postCityUnprocessableEntity = typed.nul;

export const postCity = createEffect<PostCity, PostCityDone, PostCityFail>({
  async handler({ body }) {
    const name = "postCity.body";
    const response = await requestFx({
      path: "/api/cities",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      201: ["created", postCityCreated],
      400: ["bad_request", postCityBadRequest],
      422: ["unprocessable_entity", postCityUnprocessableEntity],
    });
  },
});

type GetCity = {
  path: {
    id: string;
  };
};

const getCityOk = typed.object({
  id: typed.number,
  name: typed.string,
  state: typed.string,
  description: typed.string,
  heroImage: typed.object({
    id: typed.number,
    url: typed.string,
  }),
  cardImage: typed.object({
    id: typed.number,
    url: typed.string,
  }),
});

export type GetCityDone = {
  status: "ok";
  answer: typed.Get<typeof getCityOk>;
};

export const getCityNotFound = typed.nul;
export type GetCityFail =
  | {
      status: "not_found";
      error: typed.Get<typeof getCityNotFound>;
    }
  | GenericErrors;

export const getCity = createEffect<GetCity, GetCityDone, GetCityFail>({
  async handler({ path }) {
    const name = "getCity.body";
    const response = await requestFx({
      path: `/api/cities/${path.id}`,
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getCityOk],
      404: ["not_found", getCityNotFound],
    });
  },
});

type PutCity = {
  body: {
    name: string;
  };
  path: {
    id: string;
  };
};

export const putCityOk = typed.object({
  id: typed.number,
  name: typed.string,
});

type PutCityDone = {
  status: "ok";
  answer: typed.Get<typeof putCityOk>;
};

export const putCityBadRequest = typed.nul;

export const putCityNotFound = typed.nul;

export const putCityUnprocessableEntity = typed.nul;
export type PutCityFail =
  | {
      status: "bad_request";
      error: typed.Get<typeof putCityBadRequest>;
    }
  | {
      status: "not_found";
      error: typed.Get<typeof putCityNotFound>;
    }
  | {
      status: "unprocessable_entity";
      error: typed.Get<typeof putCityUnprocessableEntity>;
    }
  | GenericErrors;

export const putCity = createEffect<PutCity, PutCityDone, PutCityFail>({
  async handler({ body, path }) {
    const name = "putCity.body";
    const response = await requestFx({
      path: `/api/cities/${path.id}`,
      method: "PUT",
      body,
    });
    return parseByStatus(name, response, {
      200: ["ok", putCityOk],
      400: ["bad_request", putCityBadRequest],
      404: ["not_found", putCityNotFound],
      422: ["unprocessable_entity", putCityUnprocessableEntity],
    });
  },
});

export type DeleteCity = {
  path: {
    id: string;
  };
};

export const deleteCityNoContent = typed.nul;
export type DeleteCityDone = {
  status: "no_content";
  answer: typed.Get<typeof deleteCityNoContent>;
};

export const deleteCityNotFound = typed.nul;
export type DeleteCityFail =
  | {
      status: "not_found";
      error: typed.Get<typeof deleteCityNotFound>;
    }
  | GenericErrors;

export const deleteCity = createEffect<
  DeleteCity,
  DeleteCityDone,
  DeleteCityFail
>({
  async handler({ path }) {
    const name = "deleteCity.body";
    const response = await requestFx({
      path: `/api/cities/${path.id}`,
      method: "DELETE",
    });
    return parseByStatus(name, response, {
      204: ["no_content", deleteCityNoContent],
      404: ["not_found", deleteCityNotFound],
    });
  },
});

export type City = typed.Get<typeof getCitiesOk>;
