import styled from "styled-components";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import SelectUnstyled, {
  selectUnstyledClasses,
  SelectUnstyledProps,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";

export function Select(props: SelectUnstyledProps<number>): JSX.Element {
  const components: SelectUnstyledProps<number>["components"] = {
    Root: StyledButton,
    Listbox: StyledListBox,
    Popper: StyledPopper,
    ...props.components,
  };

  return (
    <>
      <SelectUnstyled {...props} components={components} />
    </>
  );
}

const StyledButton = styled.button`
  font-size: 16px;
  line-height: 26px;
  color: #0a1128;
  min-width: 45px;
  height: 30px;
  background: #f7f9fc;
  padding: 0 8px 0 12px;
  border: 1px solid #2d4fa1;
  border-radius: 600px;

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid blue;
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: "▴";
    }
  }

  &::after {
    margin-left: 6px;
    content: "▾";
    float: right;
  }
`;
const StyledListBox = styled.ul`
  padding: 0 8px 0 12px;
  margin: 10px 0;
  min-width: 45px;
  background: #fff;
  border: 1px solid #e7ebf7;
  border-radius: 8px;
  overflow: auto;
  outline: 0;
`;

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

export const StyledOption = styled(OptionUnstyled)`
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;
  text-align: center;
  &:last-of-type {
    border-bottom: none;
  }
  /*todo убрать лишнее*/
  /*&.${optionUnstyledClasses.selected} {
    background-color: blue;
    color: blue};
  }*/

  /* &.${optionUnstyledClasses.highlighted} {
    background-color: white;
    color: grey;
  }*/

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: white;
    color: blue;
  }

  &.${optionUnstyledClasses.disabled} {
    color: grey;
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: #f7f9fc;
    color: #0a1128;
  }
`;
