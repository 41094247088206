import { createGate } from "effector-react";
import { attach, createEffect, createStore, sample } from "effector";

import { getListFromApi, getDockCollection, apiRequestFx } from "shared/api";
import { spreadListDataData } from "shared/lib/effector-paginatoin";

import { Dock } from "./types";

export const Gate = createGate();
export const $docksList = createStore<Dock[]>([]);

export const getDockById = createEffect(async (dockId: string) => {
  const dock: Dock = await apiRequestFx({
    method: "GET",
    path: `/api/docks/${dockId}`,
  });
  return dock;
});
const $active = Gate.state.map((props) => props.active ?? true);
const $company = Gate.state.map((props) => props.company ?? null);

export const submitDockTermsAndConditionsFx = createEffect(
  async (params: { terms: string; dockId: string }) => {
    await apiRequestFx({
      method: "PUT",
      path: `/api/docks/${params.dockId}/terms`,
      body: { terms: params.terms },
    });
  }
);

const getDocksFx = attach({
  // @ts-ignore
  effect: getListFromApi(getDockCollection),
  source: $company,
  mapParams: (_, company) => ({
    query: { pagination: false, company: company },
  }),
});

spreadListDataData({
  $items: $docksList,
  effect: getDocksFx,
});

sample({
  clock: [Gate.open, $company],
  filter: $active,
  target: getDocksFx,
});
