import React from "react";
import { Card } from "shared/ui/card";
import styled from "styled-components";

import { Boat, Client } from "shared/api/types";
import { mediaPhone } from "shared/ui/styles/media";
import { ArrowLeftIcon } from "shared/ui/icons";

import { BoatCard } from "entities/boats";
import { LinkButton } from "shared/ui/button";

interface Props {
  client: Client | null;
  renderClientCard(): React.ReactNode;
  renderReservationsList(): React.ReactNode;
  renderModalWindows?(): React.ReactNode;
  renderSuspiciousButton?(): React.ReactNode;
}

export function CustomerPageTemplate(props: Props): JSX.Element {
  if (!props.client) return <></>;

  return (
    <>
      <Root>
        <Header>
          <BackButton as={LinkButton} to="/customers-list/">
            <BackIcon as={ArrowLeftIcon} />
          </BackButton>
          {props.renderSuspiciousButton && props.renderSuspiciousButton()}
        </Header>
        <ClientDetailed>
          <ClientCard>{props.renderClientCard()}</ClientCard>
          <ClientBoats>
            <Title>Client's boats</Title>
            <BoatsScrollContainer>
              <BoatsList>
                {/* @ts-ignore*/}
                {props.client.boats.map((boat: Boat) => (
                  <BoatCard key={boat.id} boat={boat} />
                ))}
              </BoatsList>
            </BoatsScrollContainer>
          </ClientBoats>
        </ClientDetailed>
        <CustomerReservationsList>
          <Title>List of customer reservations</Title>
          <>{props.renderReservationsList()}</>
        </CustomerReservationsList>
      </Root>
      {props.renderModalWindows && <>{props.renderModalWindows()}</>}
    </>
  );
}

const BackIcon = styled.div`
  width: 24px;
  height: 24px;
`;

const BackButton = styled.div`
  width: 44px !important;
  & > * {
    min-width: 0 !important;
    padding: 0 !important;
  }
  ${mediaPhone} {
    display: none;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ClientDetailed = styled.div`
  display: flex;
  gap: 24px;
  ${mediaPhone} {
    flex-direction: column;
  }
`;

const ClientCard = styled.div`
  flex: 1;
  padding: 24px;
  border-radius: 32px;
  background: #ffffff;
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  ${mediaPhone} {
    display: flex;
    flex-direction: column;
    padding: 0 0 16px;
  }
`;

const BoatsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  border-radius: 16px 16px 0 0;
  :last-child {
    padding-bottom: 16px;
  }
`;

const Title = styled.div`
  font-family: "Kanit", sans-serif;
  font-size: 24px;
  line-height: 28px;
`;

const ClientBoats = styled.div`
  border-radius: 32px;
  background: #ffffff;
  width: 386px;
  display: flex;
  flex-direction: column;
  max-height: 380px;
  padding-top: 24px;
  ${Title} {
    margin-left: 24px;
    margin-bottom: 16px;
    ${mediaPhone} {
      margin-left: 16px;
    }
  }
  ${mediaPhone} {
    max-height: none;
    width: 100%;
  }
`;

const BoatsScrollContainer = styled.div`
  padding: 0 24px 0;
  overflow-y: auto;
  ${mediaPhone} {
    padding: 0 0 16px;
    border-bottom: 1px solid #e7ebf7;
    width: auto;
  }
`;

const CustomerReservationsList = styled(Card)`
  ${Title} {
    margin-bottom: 24px;
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${mediaPhone} {
    gap: 16px;
  }
`;
