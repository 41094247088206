import dayjs, { Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isBetween from "dayjs/plugin/isBetween";
import relativeTime from "dayjs/plugin/relativeTime";
import isToday from "dayjs/plugin/isToday";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(advancedFormat);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.locale("en");
dayjs.tz.setDefault("America/Chicago");

export { dayjs };

const DATE_FORMAT = "YYYY-MM-DD";

export function convertToApi(date?: string | Dayjs): string {
  return dayjs(date).format(`${DATE_FORMAT} HH:mm`);
}

export function convertDateToApi(date?: string | Dayjs): string {
  return dayjs(date).format(DATE_FORMAT);
}

export function removeTimeZone(date?: string): string {
  if (date) {
    return date.split("+")[0];
  }
  return "";
}
