import { Reservation } from "shared/api/types";
import dayjs from "dayjs";

export function isPastValidation(reservation: Reservation): boolean {
  const today = dayjs();
  return dayjs.tz(reservation.timeFrom).isBefore(today);
}

export function isFutureValidation(reservation: Reservation): boolean {
  const today = dayjs();
  return dayjs.tz(reservation.timeFrom).isAfter(today);
}

export function isReservationCanceled(reservation: Reservation): boolean {
  return reservation.status === "cancelled";
}

export function isOverrideReservation(reservation: Reservation): boolean {
  return !Boolean(reservation.client);
}

export function getReservationDuration(reservation: Reservation): number {
  return dayjs(reservation.timeTo).diff(reservation.timeFrom, "hour");
}
