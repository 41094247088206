import { Store, Event, createStore, createEvent } from "effector";

export type OpenAbleState = [
  Store<boolean>,
  {
    close: Event<void>;
    open: Event<void>;
    toggle: Event<void>;
  }
];

export function createOpenAbleState(): OpenAbleState {
  const $state = createStore(false);

  const open = createEvent();
  const close = createEvent();
  const toggle = createEvent();

  $state
    .on(open, () => true)
    .on(close, () => false)
    .on(toggle, (state) => !state);

  return [$state, { open, close, toggle }];
}
