import { createForm } from "effector-react-form";
import { createEffect, createEvent, createStore, sample } from "effector";
import { object, string } from "yup";
import { createValidator } from "shared/lib/form";
import { createApiErrorStatusStore, postAdminCollection } from "shared/api";

import { createGate } from "effector-react";

export const newAdminForm = createForm({
  name: "New Admin Form",
  initialValues: {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
  },
  validate: createValidator(
    object({
      email: string().email(" ").required(" "),
      firstName: string().required(" "),
      lastName: string().required(" "),
      password: string().required(" "),
    })
  ),
  onSubmit: ({ values }) => submitFormFx(values),
});

export const $formSubmitting = postAdminCollection.pending;

const submitFormFx = createEffect(
  async (formData: {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
  }) => {
    await postAdminCollection({ body: formData });
  }
);

export const formGate = createGate();
export const $invitedAdmin = createStore<{ email: string } | null>(null);
export const resetInvitedAmin = createEvent();

$invitedAdmin
  .on(submitFormFx.done, (_, { params }) => params)
  .reset([resetInvitedAmin, formGate.close]);

export const $serverErrorStatus =
  createApiErrorStatusStore(postAdminCollection);

sample({
  clock: submitFormFx.done,
  target: newAdminForm.reset,
});
