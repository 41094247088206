import styled from "styled-components";

import { mediaPhone } from "../styles/media";

export const CardTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #0a1128;
  font-family: "Kanit", sans-serif;
`;

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0 0 20px rgba(188, 198, 207, 0.08);
  border-radius: 32px;
  padding: 24px;

  ${mediaPhone} {
    padding: 24px 0;
  }

  ${CardTitle} {
    margin-bottom: 16px;
  }
`;
