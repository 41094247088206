import styled from "styled-components";
import {
  Table as MaterialTable,
  TableBody as MaterialTableBody,
  TableCell as MaterialTableCell,
  TableHead as MaterialTableHead,
  TableRow as MaterialTableRow,
} from "@mui/material";
import React from "react";

interface Props<T> {
  columns: {
    header: string;
    align?: "left" | "right" | "center" | "justify";
    renderCell(item: T): React.ReactNode;
  }[];
  items: T[];
  name: string;
  className?: string;
}

export function SmallTableComponent<T>(props: Props<T>): JSX.Element {
  return (
    <SmallTableComponentRoot className={props.className}>
      <Name>{props.name}</Name>
      <Table size="small" aria-label="purchases">
        <MaterialTableHead>
          <MaterialTableRow>
            {props.columns.map((column) => (
              <HeadCell key={column.header} align={column.align}>
                {column.header}
              </HeadCell>
            ))}
          </MaterialTableRow>
        </MaterialTableHead>
        <MaterialTableBody>
          {props.items.map((item, indexRow) => (
            <MaterialTableRow key={`${props.name}row${indexRow}`}>
              {props.columns.map((column, indexColumn) => (
                <TableCell
                  key={`${props.name}row${indexRow}column${indexColumn}`}
                  align={column.align}
                >
                  {column.renderCell(item)}
                </TableCell>
              ))}
            </MaterialTableRow>
          ))}
        </MaterialTableBody>
      </Table>
    </SmallTableComponentRoot>
  );
}

const Name = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: #0a1128;
  font-family: "Kanit", sans-serif;
`;

const TableCell = styled(MaterialTableCell)``;
const HeadCell = styled(TableCell)``;

const Table = styled(MaterialTable)`
  ${TableCell} {
    font-size: 16px;
    line-height: 24px;
    padding: 16px;
  }

  ${HeadCell} {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #969899;
  }
`;

const SmallTableComponentRoot = styled.div`
  ${Name} {
    margin-bottom: 16px;
  }
`;

export const SmallTable: typeof SmallTableComponent = styled(
  SmallTableComponent
)``;
