import { createGate } from "effector-react";
import { createForm } from "effector-react-form";
import { attach, createEffect, createEvent, sample } from "effector";

import { createOpenAbleState } from "shared/lib/effector-openable-state";

import { number, object, string } from "yup";
import {
  putCompaniesItem,
  getCompanyItem,
} from "@admin-app/shared/api/companies";
import { createValidator } from "shared/lib/form";
import { fromApi } from "shared/api";
import { requiredFieldValidationError } from "shared/config/error-text";
import { createRouteParamStore } from "shared/lib/effector-router-params";
import { not } from "patronum";

interface Company {
  id: number;
  name: string;
  bookingFee: number;
  bookingFeeType: string;
  minimumBookingFee: string;
}

export const editFormGate = createGate();
const $companyId = createRouteParamStore({
  name: "companyId",
  gate: editFormGate,
});

export const editCompanyForm = createForm({
  name: "Edit Company Form",
  validate: createValidator(
    object({
      name: string().required(requiredFieldValidationError),
      bookingFee: number()
        .max(999, "This value should be less than or equal to 999.")
        .typeError(requiredFieldValidationError)
        .required(requiredFieldValidationError),
      bookingFeeType: string()
        .required(requiredFieldValidationError)
        .typeError(" "),
      minimumBookingFee: number()
        .nullable()
        .optional()
        .max(999, "This value should be less than or equal to 999.")
        .typeError(" "),
      stripeAccountId: string().required(requiredFieldValidationError),
    })
  ),
  onSubmit: ({ values }) => editCompanyFx(values),
});

export const $formSubmitting = putCompaniesItem.pending;

const getCompanyFx = attach({
  effect: createEffect(fromApi(getCompanyItem)),
  source: $companyId,
  mapParams: (_, companyId) => ({ path: { id: companyId as string } }),
});

const editCompanyFx = attach({
  effect: createEffect(fromApi(putCompaniesItem)),
  source: $companyId,
  mapParams: (formData: Company, companyId) => ({
    path: { id: companyId as string },
    body: {
      ...formData,
      bookingFee: formData.bookingFee.toString(),
      minimumBookingFee: formData.minimumBookingFee?.toString() ?? null,
    },
  }),
});

editCompanyForm.$values.on(getCompanyFx.doneData, (_, data) => data);

export const $isTypePercent = editCompanyForm.$values.map(
  (values) => values.bookingFeeType === "percent"
);
export const [successModal, successModalActions] = createOpenAbleState();
export const [unSuccessModal, unSuccessModalActions] = createOpenAbleState();

export const companyEdited = editCompanyFx.done;

export const editButtonClicked = createEvent<Company>();

sample({
  clock: $isTypePercent,
  filter: not($isTypePercent),
  fn: () => ({ field: "minimumBookingFee", value: null }),
  target: editCompanyForm.setValue,
});

sample({
  clock: companyEdited,
  target: successModalActions.open,
});

sample({
  clock: editCompanyFx.fail,
  target: unSuccessModalActions.open,
});

sample({
  clock: editFormGate.open,
  target: getCompanyFx,
});
