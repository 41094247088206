import { attach, createStore, sample, Store } from "effector";
import { getBoatCollection, getListFromApi } from "shared/api";

import { Boat } from "shared/api/types";

export function createClientsBoatsList(
  $clientId: Store<number | null>
): Store<Boat[]> {
  const $boatsList = createStore<Boat[]>([]);

  const getClientsBoatsFx = attach({
    effect: getListFromApi(getBoatCollection),
    mapParams: (clientId: number) => ({
      query: {
        client: clientId.toString(),
      },
    }),
  });

  $boatsList.on(getClientsBoatsFx.doneData, (_, { items }) => items);

  sample({
    source: $clientId,
    filter: (clientId): clientId is number => Boolean(clientId),
    target: getClientsBoatsFx,
  });

  return $boatsList;
}
