import { attach, createEvent, sample } from "effector";

import { deleteAddonItem } from "@admin-app/shared/api/addons";

export const deleteAddon = createEvent<number>();
export const addonDeleted = createEvent<number>();

const deleteAddonFx = attach({
  effect: deleteAddonItem,
  // @ts-ignore
  mapParams: (id: number) => ({ path: { id } }),
});

sample({
  clock: deleteAddon,
  target: deleteAddonFx,
});

sample({
  clock: deleteAddonFx.done,
  fn: ({ params }) => params,
  target: addonDeleted,
});
