import styled from "styled-components";
import {
  DateRangeInput as DateRangeInputComponent,
  FormModalTemplate,
} from "shared/ui/ecosystems/forms";
import { Button as ButtonComponent } from "shared/ui/button";
import { Select } from "shared/ui/ecosystems/forms";

import { DockSelector } from "entities/docks/ui/dock-selector";
import { ReservationAuthorSelector } from "entities/reservation-author";
import { Order, Sorting } from "shared/api/types";

import { ReservationFilters } from "../types";

interface Props {
  className?: string;
  isOpened: boolean;
  onClose(): void;
  fieldsList: { value: string; label: string }[];
  filters: ReservationFilters;
  sorting: Sorting;
  onSortChange(value: string): void;
  onSortOrderChange(value: Order): void;
}

export function ModalFilter(props: Props): JSX.Element {
  return (
    <FormModalTemplate
      isOpened={props.isOpened}
      onClose={props.onClose}
      renderTitle={() => "Filter & Sort"}
      renderContent={() => (
        <RootContent>
          <Filters>
            <SectionTitle>Filter</SectionTitle>
            <ReservationAuthorSelector
              className={props.className}
              label="Created by"
              onChange={(value) => {
                props.filters.onAuthorChange(value);
              }}
              value={props.filters.author ?? {}}
            />
            <DateRangeInput
              label="Date of reservation"
              month={1}
              value={props.filters.dateOfReservation}
              onChange={props.filters.onDateOfReservationChanged}
            />
            <DockSelector
              className={props.className}
              label="Dock Name"
              value={props.filters.dock ?? ""}
              onChange={props.filters.onDockChange}
              clearable
            />
            <DateRangeInput
              label="Reservation paid"
              month={1}
              value={props.filters.paidAt}
              onChange={props.filters.onPaidAtChanged}
            />
          </Filters>
          <SortSection>
            <SectionTitle>Sort</SectionTitle>
            <FieldSelect
              className={props.className}
              label="Select field"
              options={props.fieldsList}
              onChange={props.onSortChange}
              value={props.sorting.orderBy ?? ""}
              clearable
            />
            <FieldSelect
              className={props.className}
              label="Sort type"
              options={[
                { value: "asc", label: "Ascending" },
                { value: "desc", label: "Descending" },
              ]}
              // @ts-ignore
              onChange={props.onSortOrderChange}
              value={props.sorting.order ?? ""}
              disabled={!props.sorting.orderBy}
            />
          </SortSection>
        </RootContent>
      )}
      renderActions={() => <Button onClick={props.onClose}>Save</Button>}
    />
  );
}

const Button = styled(ButtonComponent)`
  width: 100%;
`;

const FieldSelect = styled(Select)`
  width: 100%;
`;
const DateRangeInput = styled(DateRangeInputComponent)`
  width: 100%;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SortSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 33px;
`;

const SectionTitle = styled.div`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
`;

const RootContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 8px;
  ${SectionTitle} {
    margin-top: 24px;
  }
`;
