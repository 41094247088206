import styled from "styled-components";

import { useField } from "shared/lib/form";

import { CheckBox, CheckBoxProps } from "../atoms/checkbox";
import { FieldProps } from "./types";

type Props = FieldProps<CheckBoxProps>;

function CheckBoxFieldComponent(props: Props): JSX.Element {
  const field = useField({ name: props.name });
  return (
    <CheckBox
      {...field.input}
      error={field.isShowError ? field.error : ""}
      {...props}
    />
  );
}

export const CheckBoxField = styled(CheckBoxFieldComponent)``;
