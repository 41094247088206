import { Dayjs } from "dayjs";

import { dayjs } from "./dayjs-ext";

export function time(time: string | Dayjs, timeFormat = "HH:mm"): Dayjs {
  const timeString = dayjs.isDayjs(time)
    ? dayjs(time).format(timeFormat)
    : time;
  return dayjs(`2020/01/01 ${timeString}`);
}

export function displayTime(data: Dayjs | string): string {
  return dayjs(data).format("h:mm A");
}
