import {
  combine,
  createEvent,
  createStore,
  Effect,
  sample,
  Store,
  Event,
} from "effector";
import dayjs, { Dayjs } from "dayjs";
import { and } from "patronum";

import {
  convertDateToApi,
  convertToApi,
  displayTime,
  removeTimeZone,
} from "shared/lib/dayjs-ext";
import { Dock } from "shared/api/types";

import { ReservationsDateTimeModel } from "../types";
import { getMaxDayDuration } from "../../docks";

type BoatBySize = { loa: string } | null;
type BoatById = { boatId: number } | null;

interface Params {
  $dock: Store<Dock | null>;
  $boat: Store<BoatBySize> | Store<BoatById>;
  $duration: Store<number | null>;
  $selectedDate: Store<string | null>;
  $selectedTime: Store<{ from: string; to: string } | null>;
  $isSuperOverride: Store<boolean>;
  timeChanged: Event<{ from: string; to: string } | null>;
  getAvailableItemsFx: Effect<
    { hours: number; monthStartDate: string },
    Record<string, { from: string; to: string }[]>
  >;
  getAvailableItemsTrigger: Event<any>;
}

export function createReservationsDateTimeModel(
  params: Params
): ReservationsDateTimeModel {
  const {
    $selectedDate,
    $selectedTime,
    timeChanged,
    getAvailableItemsFx,
    $dock,
    $boat,
    $duration,
    $isSuperOverride,
    getAvailableItemsTrigger,
  } = params;

  const $monthStartDate = createStore(getFirstDayOfMonth());

  const $availableDates = createStore<
    Record<string, { from: string; to: string }[]>
  >({});

  const selectedMonthChanged = createEvent<Dayjs>();

  const $maxAvailableDurations = $dock.map((dock) => {
    return dock ? getMaxDayDuration(dock) : 0;
  });

  const $dockId = $dock.map((dock) => dock?.id ?? null);
  const $availableTimes = combine(
    $availableDates,
    $selectedDate,
    (availableDates, selectedDate) =>
      availableDates[convertDateToApi(selectedDate as string)] ?? []
  );
  const $timeFrom = $selectedTime.map((time) =>
    time?.from ? convertToApi(removeTimeZone(time.from)) : null
  );
  const $timeTo = $selectedTime.map((time) =>
    time?.to ? convertToApi(removeTimeZone(time.to)) : null
  );

  $monthStartDate.on(selectedMonthChanged, (_, date) =>
    getFirstDayOfMonth(date)
  );
  $availableDates.on(getAvailableItemsFx.doneData, (items, newItems) => ({
    ...items,
    ...newItems,
  }));

  const $getItemsData = combine({
    dockId: $dockId,
    hours: $duration,
    monthStartDate: $monthStartDate,
    boat: $boat,
    isSuperOverride: $isSuperOverride ?? createStore(false),
  });

  sample({
    // @ts-ignore
    clock: [$getItemsData, getAvailableItemsTrigger],
    source: $getItemsData,
    filter: and($dock, $boat, $duration, $monthStartDate),
    fn: ({ boat, ...params }) => ({
      query: {
        ...params,
        ...boat,
      },
    }),
    target: getAvailableItemsFx,
  });

  sample({
    clock: [getAvailableItemsFx.done],
    source: { time: $selectedTime, availableTimes: $availableTimes },
    fn({ time, availableTimes }) {
      if (!time) return null;
      const newTime =
        availableTimes.find(
          (availableTime) =>
            displayTime(availableTime.from) === displayTime(time.from) &&
            displayTime(availableTime.to) === displayTime(time.to)
        ) ?? null;
      return newTime;
    },
    target: timeChanged,
  });

  return {
    $maxAvailableDurations,
    $duration,
    $availableDates,
    $selectedDate,
    $selectedTime,
    selectedMonthChanged,
    $availableTimes,

    $timeFrom,
    $timeTo,
  };
}

function getFirstDayOfMonth(date?: string | Dayjs): string {
  return convertDateToApi(dayjs(date).set("date", 1));
}
