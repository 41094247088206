import React from "react";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { createGlobalStyle, StyleSheetManager } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Hind Siliguri', sans-serif;
  }
`;

declare module "@mui/material/styles" {
  interface Palette {
    danger: Palette["primary"];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    danger?: PaletteOptions["primary"];
  }
}

// Update the Button's color prop options
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    danger: true;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: "Hind Siliguri",
  },
  palette: {
    primary: {
      main: "#2D4FA1",
      dark: "#070E54",
    },
    secondary: { main: "#3BB7B6" },
    danger: {
      main: "#DE2016",
      contrastText: "#FBFCFF",
    },
    error: {
      main: "#DE2016",
    },
  },
  components: {
    MuiFormControl: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        root: {
          borderRadius: "50px",
          textTransform: "none",
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "26px",
          height: "44px",
        },
      },
    },
    MuiInput: {
      styleOverrides: {},
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
      styleOverrides: {},
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: "secondary",
        textColor: "secondary",
      },
      styleOverrides: {},
    },
    MuiDialog: {
      defaultProps: {
        scroll: "body",
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: "600px",
          padding: "8px 8px 8px 12px",
          backgroundColor: "#F5E7E8",
        },
        icon: {
          padding: "0",
        },
        message: {
          padding: "0",
          overflow: "hidden",
          color: "#DE2016",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1rem",
          fontWeight: "400",
          padding: "16px",
        },
      },
    },
  },
});

export function ThemeAppProvider(props: {
  children: React.ReactNode;
}): JSX.Element {
  return (
    <StyleSheetManager
      disableVendorPrefixes={process.env.NODE_ENV === "development"}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyle />
        {props.children}
      </ThemeProvider>
    </StyleSheetManager>
  );
}
