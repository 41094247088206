import { createEffect } from "effector";

import { apiRequestFx } from "../../shared/api";

export const managerCanceledReservationFx = createEffect(
  async (params: { reservationId: number; refundAmount: string }) => {
    await apiRequestFx({
      method: "POST",
      path: `/api/manager/reservations/${params.reservationId}/cancel`,
      body: {
        refundAmount: params.refundAmount,
      },
    });
  }
);
