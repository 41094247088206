import styled from "styled-components";

import { Button, ButtonProps } from "shared/ui/button";

export interface SubmitButtonProps extends ButtonProps {
  isSubmitting: boolean;
  className?: string;
}

function SubmitButtonComponent(props: SubmitButtonProps): JSX.Element {
  const { isSubmitting, ...buttonProps } = props;
  return (
    <Button
      className={props.className}
      type={props.type ?? "submit"}
      {...buttonProps}
      disabled={props.disabled || isSubmitting}
    >
      {isSubmitting ? "Submitting" : props.children}
    </Button>
  );
}

export const SubmitButton = styled(SubmitButtonComponent)``;
