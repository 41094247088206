import {
  CheckBoxField,
  DateField,
  FormError,
  FormModalTemplate,
  PriceInput,
  SelectField,
  SubmitButton,
} from "shared/ui/ecosystems/forms";
import { useUnit, useStoreMap, useStore } from "effector-react";
import styled from "styled-components";

import { DockField } from "entities/docks";
import { addReservationApiErrorsByStatuses } from "shared/api";

import {
  createDateSelector,
  createDurationSelector,
  createTimeSelector,
} from "entities/reservations";
import { TaxInput } from "shared/ui/ecosystems/forms/atoms/input";

import {
  $bookingFee,
  $charterFee,
  $clientBoats,
  $price,
  $selectedDock,
  durationModel,
  editForm,
  editModal,
  editModalActions,
  $isReservationUpdating,
  $errorStatus,
} from "../model";

export function EditClientReservationModal(): JSX.Element {
  const isOpened = useUnit(editModal);
  const isReservationUpdating = useUnit($isReservationUpdating);

  const selectedDock = useStore($selectedDock);
  const price = useStore($price);
  const bookingFee = useStore($bookingFee);
  const charterFee = useStore($charterFee);
  const errorStatus = useStore($errorStatus);

  const taxRate = selectedDock && parseFloat(selectedDock.taxRate);
  const priceNumber = parseFloat(price);
  const bookingFeeNumber = parseFloat(bookingFee);
  const charterFeeNumber = parseFloat(charterFee ?? "0");

  const error =
    (errorStatus && addReservationApiErrorsByStatuses[errorStatus]) ??
    "Unknown error";

  const totalPrice =
    (taxRate / 100) * priceNumber +
    priceNumber +
    bookingFeeNumber +
    charterFeeNumber;

  return (
    <FormModalTemplate
      form={editForm}
      isOpened={isOpened}
      renderTitle={() => "Edit Reservation"}
      renderContent={() => (
        <FormContent>
          {errorStatus && <FormError>{error}</FormError>}
          <FormFields>
            <DockField name="dock" label="Dock" />
            <BoatSelector />

            <DurationSelector
              renderElement={(props) => (
                <SelectField name="duration" label="Duration" {...props} />
              )}
            />

            <DateSelector
              renderElement={(props) => (
                <DateField name="date" label="Date" {...props} />
              )}
            />

            <TimeSelector
              renderElement={(props) => (
                <SelectField name="time" label="Time" {...props} />
              )}
            />
          </FormFields>
          <FormFields>
            <CheckBoxField name="isCharter">Is this a charter?</CheckBoxField>
          </FormFields>
          <FormFields>
            <PriceInput
              value={price}
              name="totalPrice"
              label="Before Tax *"
              disabled
            />

            <TaxInput value={taxRate?.toFixed(2)} label="Tax Rate" disabled />
            <PriceInput
              value={bookingFeeNumber?.toFixed(2)}
              disabled
              label="Booking Fee"
            />
            {charterFee && (
              <PriceInput
                value={charterFeeNumber.toFixed(2)}
                disabled
                label="Charter Fee"
              />
            )}

            <PriceInput
              value={selectedDock && totalPrice.toFixed(2)}
              disabled
              label="Total Price"
            />
          </FormFields>

          <Note>
            <NoteStar>*</NoteStar>

            <div>
              If changes to the reservation affect the cost, the new cost will
              be withdrawn and the previous cost will be refunded to client's
              credit card. Please ensure the funds are sufficient. If withdrawal
              attempt fails,{" "}
              <b>the changes to the reservation will not take place</b>.
            </div>
          </Note>
        </FormContent>
      )}
      renderActions={() => (
        <SubmitButton isSubmitting={isReservationUpdating}>Save</SubmitButton>
      )}
      onClose={editModalActions.close}
    />
  );
}

const FormFields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const NoteStar = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #de2016;
`;

const Note = styled.div`
  display: flex;
  align-items: start;
  gap: 4px;
  margin-top: 16px;

  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #0a1128;
`;

function BoatSelector() {
  const options = useStoreMap($clientBoats, (boats) =>
    boats.map((boat) => ({
      label: boat.name,
      value: boat["@id"],
    }))
  );

  return <SelectField name="boat" label="Boat" options={options} />;
}

const DurationSelector = createDurationSelector(durationModel);
const DateSelector = createDateSelector(durationModel);
const TimeSelector = createTimeSelector(durationModel);

const FormContent = styled.div`
  ${FormError} {
    margin-bottom: 24px;
  }
`;
