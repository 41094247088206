import React from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

interface Props {
  modal: string;
  children?: React.ReactNode;
  className?: string;
}

function ModalLinkComponent(props: Props): JSX.Element {
  const location = useLocation();

  return (
    <Link
      className={props.className}
      to={{
        pathname: location.pathname,
        search: `m=${props.modal}`,
      }}
    >
      {props.children}
    </Link>
  );
}

export const ModalLink = styled(ModalLinkComponent)``;
