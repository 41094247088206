import { useCallback, useState } from "react";

export interface OpenableState {
  isOpened: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

export function useOpenableState(defaultValue = false): OpenableState {
  const [isOpened, setIsOpened] = useState(defaultValue);
  const open = useCallback(() => setIsOpened(true), []);
  const close = useCallback(() => setIsOpened(false), []);
  const toggle = useCallback(() => setIsOpened(!isOpened), [isOpened]);

  return { isOpened, open, close, toggle };
}
