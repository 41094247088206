import React from "react";
import styled, { css } from "styled-components";

import { Boat } from "shared/api/types";
import { TrashIcon, StarIcon } from "shared/ui/icons";
import { useOpenableState } from "shared/lib/use-openable-state";

import { Button } from "shared/ui/button";

import boatImg from "./boat.svg";
import flipImg from "./flip-icon.svg";

interface Props {
  className?: string;
  boat: Boat;
  onDeleteClick?(): void;
  onMainClick?(): void;
  onClick?(): void;
  transparent?: boolean;
}

export function BoatCardComponent(props: Props): JSX.Element {
  const actions = useOpenableState();

  return (
    <FlipAbleContent
      flipped={actions.isOpened}
      className={props.className}
      transparent={props.transparent}
      renderFront={() => (
        <BoatCardRoot
          onClick={props.onDeleteClick ? actions.open : props.onClick}
        >
          <Header>
            <Name>{props.boat.name}</Name>

            {props.onMainClick && (
              <MainBoat
                isMain={props.boat.isMain}
                onClick={(e) => {
                  e.stopPropagation();
                  !props.boat.isMain && props.onMainClick();
                }}
              />
            )}
          </Header>
          <Manufacturer>
            <ManufacturerName>{props.boat.manufacturer}</ManufacturerName>
            <Model>{props.boat.model}</Model>
          </Manufacturer>

          <Year>{props.boat.year}</Year>

          {props.boat.image ? (
            <BoatCustomImage src={props.boat.image.url} />
          ) : (
            <BoatImage>
              <img src={boatImg} alt="" />
            </BoatImage>
          )}

          <SpecificationsList>
            <Specification name="LOA" value={`${props.boat.loa} ft`} />
            <Specification name="Beam" value={`${props.boat.beam} ft`} />
            {props.boat.draft ? (
              <Specification name="Draft" value={`${props.boat.draft} ft`} />
            ) : (
              <div />
            )}
            {props.boat.height ? (
              <Specification name="Height" value={`${props.boat.height} ft`} />
            ) : (
              <div />
            )}
          </SpecificationsList>
        </BoatCardRoot>
      )}
      renderBack={() => (
        <BoatCardRoot onClick={actions.close}>
          <Actions>
            <Button
              onClick={(e) => {
                // @ts-ignore
                e.stopPropagation();
                props.onDeleteClick();
              }}
              color="danger"
            >
              <DeleteIcon as={TrashIcon} />
              Delete
            </Button>
          </Actions>
        </BoatCardRoot>
      )}
    />
  );
}

export const BoatCard = styled(BoatCardComponent)``;

const Name = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #0a1128;
`;

const MainBoat = styled(StarIcon)<{ isMain?: boolean }>`
  width: 26px;
  height: 26px;
  path {
    fill: ${(props) => (props.isMain ? "#3BB7B6" : "#a5adb3")};
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Model = styled.div``;

const ManufacturerName = styled.div``;

const Manufacturer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Year = styled.div``;

const BoatImage = styled.div`
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;
  justify-content: end;

  img {
    height: 61%;
  }
`;

const BoatCustomImage = styled.img`
  max-width: 100%;
`;

const SpecificationsList = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DeleteIcon = styled.div`
  width: 18px;
  height: 22px;
  margin-right: 12px;
`;

const Actions = styled.div`
  ${Button} {
    width: 180px;
  }
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
`;

const BoatCardRoot = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px 24px 16px;

  background: var(--background);
  border-radius: 16px;

  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #5e616c;

  ${Header} {
    margin-bottom: 20px;
  }

  ${Manufacturer} {
    margin-bottom: 8px;
  }

  ${BoatImage}, ${BoatCustomImage} {
    margin-bottom: 12px;
  }

  width: 100%;
  height: 100%;

  position: relative;
  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
      :before {
        content: url(${flipImg});
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    `}
`;

function Specification(props: { name: string; value: string }) {
  return (
    <SpecificationRoot>
      <SpecificationName>{props.name}</SpecificationName>
      <SpecificationValue>{props.value}</SpecificationValue>
    </SpecificationRoot>
  );
}

const SpecificationName = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #5e88ef;
`;

const SpecificationValue = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #5e616c;
`;

const SpecificationRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function FlipAbleContent(props: {
  flipped: boolean;
  renderFront(): React.ReactNode;
  renderBack(): React.ReactNode;
  className?: string;
  transparent?: boolean;
}): JSX.Element {
  return (
    <FlipAbleContentRoot
      className={props.className}
      flipped={props.flipped}
      transparent={props.transparent}
    >
      <Front>{props.renderFront()}</Front>
      <Back>{props.renderBack()}</Back>
    </FlipAbleContentRoot>
  );
}

const Front = styled.div`
  backface-visibility: hidden;
  position: relative;
  z-index: 1;
`;

const Back = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  z-index: 2;
`;

const FlipAbleContentRoot = styled.div.attrs<{
  flipped: boolean;
  transparent: boolean;
}>((props) => ({
  style: props.flipped ? { transform: "rotateY(180deg)" } : {},
}))`
  --background: #f1f3fa;

  ${(props) =>
    props.transparent &&
    css`
      --background: transparent; ;
    `}
  position: relative;
  transition: transform 0.3s;
  transform-style: preserve-3d;
`;
