import styled from "styled-components";
import { useGate, useStore } from "effector-react";
import { Box } from "@mui/material";

import {
  SubmitButton,
  InputField,
  PhoneField,
} from "shared/ui/ecosystems/forms";
import { Form } from "shared/lib/form";

import { mediaPhone } from "shared/ui/styles/media";
import { CompanyField } from "@admin-app/entities/companies";
import { InviteSentModal } from "@admin-app/shared/ui/invite-sent-modal";

import {
  newManagerForm,
  $formSubmitting,
  $invitedManager,
  resetInvitedManager,
  formGate,
} from "../add-manager-form.model";

export function NewManagerForm(): JSX.Element {
  useGate(formGate);
  const isSubmitting = useStore($formSubmitting);
  const invitedManager = useStore($invitedManager);

  return (
    <Root>
      <Form form={newManagerForm}>
        <FormFields>
          <InputField
            name="firstName"
            type="string"
            label="First name"
            placeholder="Enter first name *"
          />
          <InputField
            name="lastName"
            type="string"
            label="Last Name"
            placeholder="Enter last name"
          />
        </FormFields>
        <FormFields>
          <InputField
            name="email"
            type="email"
            label="Email"
            placeholder="Enter email"
          />
          <InputField
            name="password"
            type="password"
            label="Password"
            placeholder="Enter password"
          />
        </FormFields>
        <FormFields>
          <PhoneField name="phone" label="Enter phone number *" />
        </FormFields>

        <FormFields>
          <Company>
            <CompanyField name="company" label="Select dock company" />
          </Company>
        </FormFields>

        <Actions>
          <SubmitButton isSubmitting={isSubmitting}>
            Send invitation
          </SubmitButton>
        </Actions>
      </Form>

      {invitedManager && (
        <InviteSentModal
          isOpened
          onClose={resetInvitedManager}
          email={invitedManager.email}
        />
      )}
    </Root>
  );
}

const Company = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;

  a {
    cursor: pointer;
  }
`;

export const FormFields = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  ${mediaPhone} {
    grid-template-columns: 1fr;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;

  ${FormFields} {
    margin-bottom: 24px;
  }

  ${Actions} {
    margin-top: 24px;
  }

  ${SubmitButton} {
    width: 322px;
    align-self: center;
  }
`;
