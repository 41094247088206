import { NewCityForm } from "@admin-app/features/manage-cities/add";
import { CityFormTemplate } from "@admin-app/features/manage-cities/ui/form-template";
import { MetaTags } from "shared/lib/metaTags";

export function AddCityPage(): JSX.Element {
  return (
    <CityFormTemplate title="Create city">
      <MetaTags title="Create city" />
      <NewCityForm />
    </CityFormTemplate>
  );
}
