import { attach, createStore, sample, split } from "effector";
import { createForm } from "effector-react-form";
import { number, object, string } from "yup";

import { createOpenAbleState } from "shared/lib/effector-openable-state";
import { createValidator } from "shared/lib/form";
import {
  phoneValidationError,
  requiredFieldValidationError,
} from "shared/config/error-text";
import { createNewCompanyFx } from "@admin-app/entities/companies";

import { not } from "patronum";

export const newCompanyForm = createForm({
  name: "Create Company Form",
  initialValues: {
    name: "",
    bookingFee: null,
    bookingFeeType: "",
    minimumBookingFee: "",
    stripeAccountId: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
  },
  validate: createValidator(
    object({
      name: string().required(requiredFieldValidationError),
      bookingFee: number()
        .max(999, "This value should be less than or equal to 999.")
        .required(requiredFieldValidationError)
        .typeError(requiredFieldValidationError),
      bookingFeeType: string()
        .required(requiredFieldValidationError)
        .typeError(" "),
      minimumBookingFee: number()
        .nullable()
        .optional()
        .max(999, "This value should be less than or equal to 999.")
        .typeError(" "),
      stripeAccountId: string().required(requiredFieldValidationError),
      firstName: string().required(requiredFieldValidationError),
      lastName: string().required(requiredFieldValidationError),
      email: string().email().required(requiredFieldValidationError),
      password: string().required(requiredFieldValidationError),
      phone: string().phone("US", phoneValidationError),
    })
  ),
  onSubmit: ({ values }) => createCompanyFx(values),
});

export const $formSubmitting = createStore(false);

const createCompanyFx = attach({
  effect: createNewCompanyFx,
  mapParams: (formData) => ({
    ...formData,
    bookingFee: formData.bookingFee.toString(),
    minimumBookingFee: formData.minimumBookingFee?.toString() ?? null,
  }),
});
export const $isTypePercent = newCompanyForm.$values.map(
  (values) => values.bookingFeeType === "percent"
);

export const [successModal, successModalActions] = createOpenAbleState();
export const [unSuccessModal, unSuccessModalActions] = createOpenAbleState();

export const companyCreated = createCompanyFx.done;

sample({
  clock: $isTypePercent,
  filter: not($isTypePercent),
  fn: () => ({ field: "minimumBookingFee", value: null }),
  target: newCompanyForm.setValue,
});

sample({
  clock: companyCreated,
  target: successModalActions.open,
});

split({
  // @ts-ignore
  source: createCompanyFx.failData,
  match: {
    emailAlreadyTaken: (error: { status: number; message: string }) =>
      error.status === 422 &&
      error.message === "email: The email has already been taken.",
  },
  cases: {
    emailAlreadyTaken: newCompanyForm.setOrDeleteOuterError.prepend(() => ({
      field: "email",
      error: " The email has already been taken.",
    })),
    __: unSuccessModalActions.open,
  },
});
