import React from "react";
import { useStoreMap, useUnit } from "effector-react";
import { Dayjs } from "dayjs";

import { convertDateToApi, displayTime } from "shared/lib/dayjs-ext";

import { ReservationsDateTimeModel } from "../types";

interface DurationSelectorProp {
  renderElement(props: {
    options: { value: number; label: string }[];
    disabled: boolean;
  }): JSX.Element;
}

export function createDurationSelector(
  model: ReservationsDateTimeModel
): React.FunctionComponent<DurationSelectorProp> {
  return function DurationSelector(props: DurationSelectorProp): JSX.Element {
    const options = useStoreMap(model.$maxAvailableDurations, (maxDurations) =>
      new Array(maxDurations)
        .fill(0)
        .map((_, index) => index + 1)
        .map((duration) => ({
          value: duration,
          label: `${duration} hr`,
        }))
    );

    return props.renderElement({ options, disabled: options.length === 0 });
  };
}

interface DateSelectorProp {
  renderElement(props: {
    disabled: boolean;
    onMonthChange(data: Dayjs): void;
    shouldDisableDate: (date: Dayjs) => boolean;
  }): JSX.Element;
}

export function createDateSelector(
  model: ReservationsDateTimeModel
): React.FunctionComponent<DateSelectorProp> {
  return function DateSelector(props) {
    const [availableDates] = useUnit([model.$availableDates]);
    const durationSelected = useStoreMap(model.$duration, Boolean);

    return props.renderElement({
      disabled: !durationSelected,
      onMonthChange: model.selectedMonthChanged,
      shouldDisableDate: (date: Dayjs) => {
        return !availableDates[convertDateToApi(date)];
      },
    });
  };
}

interface TimeSelectorProps {
  renderElement(props: {
    options: {
      value: { from: string; to: string };
      label: string;
      key: string;
    }[];
    disabled: boolean;
  }): JSX.Element;
}

export function createTimeSelector(
  model: ReservationsDateTimeModel
): React.FunctionComponent<TimeSelectorProps> {
  return function TimeSelector(props) {
    const dateSelected = useStoreMap(model.$selectedDate, Boolean);

    const options = useStoreMap(model.$availableTimes, (times) =>
      times.map((time) => {
        const timeFrom = displayTime(time.from);
        const timeTo = displayTime(time.to);

        const label = `${timeFrom} - ${timeTo}`;

        return {
          value: time,
          label,
          key: label,
        };
      })
    );

    return props.renderElement({
      disabled: !dateSelected,
      options,
    });
  };
}
