import styled from "styled-components";
import AvatarMUI from "@mui/material/Avatar";

interface Props {
  name: string;
  className?: string;
}

function AvatarComponent(props: Props) {
  return (
    <AvatarMUI
      {...stringAvatar(props.name)}
      classes={{ root: props.className }}
    />
  );
}

export const Avatar = styled(AvatarComponent)`
  max-width: 100%;
  max-height: 100%;

  font-weight: 500;
  line-height: 130%;
  color: #fbfcff;
`;
function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${
      name.split(" ")[1]?.[0] ?? ""
    }`.toUpperCase(),
  };
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
