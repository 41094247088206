import { attach, createEvent, createStore, sample } from "effector";

import { getListFromApi, getReservationAuthorCollection } from "shared/api";
import { ReservationAuthor } from "shared/api/types";
import { debounce } from "patronum";

export const inputChanged = createEvent();
export const valueChanged = createEvent();
const authorListUpdated = createEvent();
const authorListReset = createEvent();

export const $inputValue = createStore("");
export const $authorsList = createStore<ReservationAuthor[]>([]);

const getAuthorsFx = attach({
  // @ts-ignore
  effect: getListFromApi(getReservationAuthorCollection),
  mapParams: (input) => ({
    query: { pagination: false, search: input },
  }),
});

sample({
  clock: getAuthorsFx.done,
  source: $inputValue,
  filter: (inputValue, { params }) => {
    return inputValue === params;
  },
  fn: (_, { result }) => result.items,
  target: authorListUpdated,
});

$inputValue.on(inputChanged, (_, value) => value);

$authorsList.on(authorListUpdated, (_, value) => value).reset(authorListReset);

const minCharsToUpdate = 3;

sample({
  clock: debounce({ source: $inputValue, timeout: 500 }),
  filter: (inputValue) => inputValue.length >= minCharsToUpdate,
  target: getAuthorsFx,
});

sample({
  clock: $inputValue,
  filter: (inputValue) => inputValue.length < minCharsToUpdate,
  target: authorListReset,
});
