import styled from "styled-components";
import { ButtonBase } from "@mui/material";

import { CloseIcon } from "shared/ui/icons";

export const CloseButton = styled(
  (props: { onClick(): void; className?: string }): JSX.Element => {
    return (
      <CloseButtonRoot classes={{ root: props.className }}>
        <Close onClick={props.onClick} />
      </CloseButtonRoot>
    );
  }
)``;

const CloseButtonRoot = styled(ButtonBase)`
  width: 24px;
  height: 24px;
`;

const Close = styled(CloseIcon)`
  color: #070e54;
  width: 100%;
  height: 100%;
`;
