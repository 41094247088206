import React, { useRef, useState } from "react";
import styled from "styled-components";

import { useOpenableState } from "shared/lib/use-openable-state";

import { SmallModalTemplate } from "../small-modal/small-modal";
import { Button } from "../../button";

interface Props {
  title: React.ReactNode;
  message: React.ReactNode;
  secondaryMessage?: React.ReactNode;
  Icon?: React.FunctionComponent;
  cancelText?: string;
  confirmText?: string;
  isOpened: boolean;
  onCancel(): void;
  onConfirm(): void;
}

export function ConfirmModal(props: Props): JSX.Element {
  return (
    <ConfirmModalRoot
      as={SmallModalTemplate}
      isOpened={props.isOpened}
      onClose={props.onCancel}
      renderTitle={() => props.title}
      renderContent={() => (
        <>
          {props.Icon && <Icon as={props.Icon} />}
          <Message>{props.message}</Message>
          {props.secondaryMessage && (
            <SecondaryMessage>{props.secondaryMessage}</SecondaryMessage>
          )}
        </>
      )}
      renderActions={() => (
        <Actions>
          <Button variant="outlined" onClick={props.onCancel}>
            {props.cancelText ?? "Cancel"}
          </Button>
          <Button color="danger" onClick={props.onConfirm}>
            {props.confirmText ?? "Confirm"}
          </Button>
        </Actions>
      )}
    />
  );
}

const Icon = styled.div`
  width: 48px;
  height: 48px;
  color: #f1928d;
`;

const Message = styled.div`
  text-align: center;
  overflow-wrap: anywhere;
`;

const SecondaryMessage = styled(Message)`
  font-size: 12px;
  line-height: 16px;
`;

const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const ConfirmModalRoot = styled.div`
  ${Icon} {
    margin-bottom: 20px;
  }

  ${SecondaryMessage} {
    margin-top: 16px;
  }
`;

export function useConfirmModal<
  T,
  S extends Partial<Omit<Props, "isOpened" | "onCancel" | "onConfirm">>
>(
  callback: (...args: T[]) => unknown,
  props: ((...args: T[]) => S) | S
): [
  (...args: T[]) => void,
  S & { isOpened: boolean; onCancel(): void; onConfirm(): void }
] {
  const modal = useOpenableState();
  const argsRef = useRef<T[]>();
  // @ts-ignore
  const [passedProps, setProps] = useState<S>({});

  const confirmModalProps = {
    ...passedProps,
    isOpened: modal.isOpened,
    onCancel: modal.close,
    onConfirm() {
      callback(...(argsRef.current as T[]));
      modal.close();
    },
  };

  function askConfirmation(...passedArgs: T[]) {
    argsRef.current = passedArgs;
    setProps(typeof props === "function" ? props(...passedArgs) : props);
    modal.open();
  }

  return [askConfirmation, confirmModalProps];
}
