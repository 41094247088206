import { NewCompanyForm } from "@admin-app/features/manage-companies/add";
import { CompanyFormTemplate } from "@admin-app/features/manage-companies/ui/company-form-template";
import { MetaTags } from "shared/lib/metaTags";

export function CompaniesListAddPage(): JSX.Element {
  return (
    <CompanyFormTemplate title="Create company">
      <MetaTags title="Create company" />
      <NewCompanyForm />
    </CompanyFormTemplate>
  );
}
