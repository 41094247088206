import styled from "styled-components";

import React from "react";

interface Props<T> {
  columns: {
    header: string;
    mobileAlign?: "start";
    renderCell(item: T): React.ReactNode;
  }[];
  items: T[];
  name: string;
  className?: string;
}

export function SmallTableMobile<T>(props: Props<T>): JSX.Element {
  return (
    <TableRoot className={props.className}>
      <Header>{props.name}</Header>
      <List>
        {props.items.map((item, index) => (
          <ListItem
            key={`smallTableListItem ${index}`}
            item={item}
            columns={props.columns}
          />
        ))}
      </List>
    </TableRoot>
  );
}

type ListItemProps<T> = Pick<Props<T>, "columns"> & { item: T };

export function ListItem<T>(props: ListItemProps<T>): JSX.Element {
  return (
    <Item>
      {props.columns.map((column) => (
        <>
          <Line key={column.header} align={column.mobileAlign}>
            <Title>{column.header}</Title>
            <Value>{column.renderCell(props.item)} </Value>
          </Line>
        </>
      ))}
    </Item>
  );
}

const Header = styled.div`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
`;

const Title = styled.div`
  color: #969899;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
`;

const Value = styled.div`
  color: #0a1128;
  text-align: right;
  font-size: 16px;
  line-height: 26px;
  white-space: normal;
  text-overflow: ellipsis;
`;

const Line = styled.div<{ align?: "start" }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: ${(props) => props.align ?? "center"};
  gap: 12px;
  width: 100%;
  justify-content: space-between;
`;

const Item = styled.div`
  width: 100%;
  padding-top: 12px;
  ${Line} {
    margin-bottom: 8px;
  }
`;

const List = styled.div``;

const TableRoot = styled.div`
  //padding: 16px;
  background-color: #f7f9fc;
`;
