import styled from "styled-components";
import {
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup as MaterialRadioGroup,
} from "@mui/material";

export interface RadioGroupProps<T extends string | number> {
  label?: string;
  className?: string;
  options: { label: string | JSX.Element; value: T }[];
  value: T;
  onChange(value: T): void;
  defaultValue?: T;
}

function RadioGroupComponent<T extends string | number>(
  props: RadioGroupProps<T>
): JSX.Element {
  return (
    <RootRadioGroup className={props.className}>
      <InputLabel>{props.label}</InputLabel>
      <MaterialRadioGroup
        {...props}
        onChange={(event) => props.onChange(event.target.value as T)}
      >
        {props.options.map((option) => (
          <FormControlLabel
            key={option.value}
            label={option.label}
            value={option.value}
            control={<Radio />}
          />
        ))}
      </MaterialRadioGroup>
    </RootRadioGroup>
  );
}

const RootRadioGroup = styled.div``;

export const RadioGroup = styled(RadioGroupComponent)``;
