import { Form } from "effector-react-form";
import { Store, Event } from "effector";

export function formField<T, S extends keyof T>(
  form: Form<T>,
  field: S
): Store<T[S] | null> {
  return form.$values.map((values) => values[field] ?? null);
}

export function formFieldChanged<T, S extends keyof T>(
  form: Form<T>,
  field: S
): Event<T[S]> {
  return form.setValue.prepend((value) => ({
    field: field,
    value,
  }));
}
