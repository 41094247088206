import { useMediaQuery } from "react-responsive";

type Breakpoint = {
  width: number;
  type: "min" | "max";
};

export function createBreakpoint(params: {
  width: number;
  type: "min" | "max";
}): Breakpoint {
  return params;
}

export function createMedia(breakpoint: Breakpoint): string {
  return breakpoint.type === "min"
    ? createMediaMaxWidth(breakpoint.width)
    : createMediaMaxWidth(breakpoint.width);
}

export const createMediaMaxWidth = (width: number): string =>
  `@media (max-width: ${width}px)`;
export const createMediaMinWidth = (width: number): string =>
  `@media (min-width: ${width}px)`;

export const screenPhone = 1000;
export const screenDesktop = screenPhone + 1;
export const mediaPhone = createMediaMaxWidth(screenPhone);
export const mediaDesktop = createMediaMinWidth(screenDesktop);

export function useBreakPoint(breakpoint: Breakpoint): boolean {
  return useMediaQuery({
    query: `(${breakpoint.type}-width: ${breakpoint.width}px)`,
  });
}

export function useMedia(): {
  isPhone: boolean;
  isDesktop: boolean;
} {
  const isDesktop = useMediaQuery({
    query: `(min-width: ${screenDesktop}px)`,
  });

  const isPhone = useMediaQuery({
    query: `(max-width: ${screenPhone}px)`,
  });
  return { isPhone, isDesktop };
}
