import { AuthPageTemplate } from "shared/ui/templates/auth-page-template/auth-page-template";
import { SignInForm } from "@admin-app/features/authentication";
import { MetaTags } from "shared/lib/metaTags";

export function SignInPage(): JSX.Element {
  return (
    <>
      <MetaTags title="Sign in" />
      <AuthPageTemplate
        header={"Control Panel Login"}
        renderContent={() => <SignInForm />}
      />
    </>
  );
}
