import styled from "styled-components";
import { Box } from "@mui/material";
import { useStore } from "effector-react";

import {
  FormError,
  FormModalTemplate,
  NumberInputField,
  RadioGroupField,
  SubmitButton,
} from "shared/ui/ecosystems/forms";
import { Form } from "shared/lib/form";

import { Button } from "shared/ui/button";

import { mediaPhone } from "shared/ui/styles/media";

import { ReactComponent as TrashIcon } from "./trash.svg";

import {
  $formSubmitting,
  $serverErrorStatus,
  cancelModalClosed,
  cancelReservationForm,
  $reservation,
  partialRefundFocused,
} from "../model";

export function CancelReservationModal(props: {
  isOpened: boolean;
  onClose(): void;
}): JSX.Element {
  const isSubmitting = useStore($formSubmitting);
  const errorStatus = useStore($serverErrorStatus);
  const reservation = useStore($reservation);
  const error = errorStatus ?? "Unknown error";

  return (
    <FormModalTemplate
      form={cancelReservationForm}
      isOpened={props.isOpened}
      onClose={props.onClose}
      renderTitle={() => "Cancel Reservation?"}
      renderSubtitle={() => (
        <>Are you sure you want to cancel reservation № {reservation.id} ? </>
      )}
      renderContent={() => (
        <>
          {errorStatus && <FormError>{error}</FormError>}
          <Content>
            <Form form={cancelReservationForm}>
              <FormFields>
                <RadioGroupField
                  name={"refundOption"}
                  options={[
                    {
                      value: "full",
                      label: "Cancel and refund 100%",
                    },
                    {
                      value: "partial",
                      label: (
                        <NumberInputField
                          name="partialRefund"
                          label="Cancel and partial refund $"
                          min={0}
                          onFocus={partialRefundFocused}
                        />
                      ),
                    },
                    {
                      value: "none",
                      label: "Cancel without refund",
                    },
                  ]}
                />
              </FormFields>
            </Form>
            <Trash />
          </Content>
        </>
      )}
      renderActions={() => (
        <Actions>
          <Button variant="outlined" onClick={() => cancelModalClosed()}>
            No
          </Button>
          <SubmitButton color="danger" isSubmitting={isSubmitting}>
            Yes, cancel
          </SubmitButton>
        </Actions>
      )}
    />
  );
}

const Trash = styled(TrashIcon)`
  width: 96px;
  height: 96px;
  ${mediaPhone} {
    display: none;
  }
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${Trash} {
    margin-right: 8px;
  }
`;
export const FormFields = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
  ${Button} {
    width: 160px;
  }
`;
