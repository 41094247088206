import { useGate, useStore } from "effector-react";
import styled from "styled-components";

import { mediaDesktop, mediaPhone, useMedia } from "shared/ui/styles/media";
import { AddIcon, TrashIcon } from "shared/ui/icons";
import { LinkButton } from "shared/ui/button";
import { Card as CardComponent } from "shared/ui/card";
import { ActionDelete, ActionEdit, Table, TableActions } from "shared/ui/table";
import { deleteCompany } from "@admin-app/features/manage-companies/delete";

import { MetaTags } from "shared/lib/metaTags";
import { ConfirmModal, useConfirmModal } from "shared/ui/modals";

import { Link } from "react-router-dom";

import {
  $companiesList,
  pageGate,
  itemsPerPageChanged,
  pageChanged,
  $currentPage,
  $companiesTotalCount,
  $itemsPerPage,
} from "./model";

export function CompaniesPage(): JSX.Element {
  useGate(pageGate);
  const { isDesktop } = useMedia();
  const companies = useStore($companiesList);
  const total = useStore($companiesTotalCount);
  const currentPage = useStore($currentPage);
  const rowsPerPage = useStore($itemsPerPage);
  const [confirmDeleting, confirmModalProps] = useConfirmModal(
    (company: typeof companies[number]) => deleteCompany(company.id),
    (company) => ({
      title: "Delete company?",
      message: `Are you sure you want to delete company  ${company.name} ?`,
      Icon: TrashIcon,
      confirmText: "Yes, delete",
    })
  );

  return (
    <>
      <MetaTags title="Companies" />
      <Root>
        <Card>
          <Header>
            <TitleBlock>
              <Title>Companies</Title>
            </TitleBlock>
            <CreateCompanyButton
              as={LinkButton}
              to={"companies-list/add"}
              Icon={AddIcon}
            >
              {isDesktop && "Create company"}
            </CreateCompanyButton>
          </Header>
          {companies && (
            <Table
              items={companies}
              columns={[
                {
                  header: "ID",
                  renderCell: (company) => company?.id ?? "",
                },
                {
                  header: "Company name",
                  renderCell: (company) => company.name,
                  align: "left",
                },
                {
                  header: "Company manager ",
                  renderCell: (company) =>
                    `${company?.firstManager?.firstName ?? ""} ${
                      company?.firstManager?.lastName ?? ""
                    }`,
                  align: "left",
                },
                {
                  header: "Email company manager ",
                  renderCell: (company) => company?.firstManager?.email,
                  align: "left",
                },
                {
                  header: "Action",
                  align: "right",
                  renderCell: (company) => (
                    <TableActions>
                      <Link to={`/companies-list/${company.id}/edit`}>
                        <ActionEdit />
                      </Link>
                      <ActionDelete onClick={() => confirmDeleting(company)} />
                    </TableActions>
                  ),
                },
              ]}
              pagination={{
                total: total,
                onPageChange: pageChanged,
                onItemsPerPageChange: itemsPerPageChanged,
                currentPage: currentPage,
                itemsPerPage: rowsPerPage,
                rowsPerPageOptions: [5, 10, 20],
              }}
            />
          )}
        </Card>
      </Root>
      <ConfirmModal {...confirmModalProps} />
    </>
  );
}

const Title = styled.div`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  ${mediaPhone} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CreateCompanyButton = styled.div`
  white-space: nowrap;
  ${mediaDesktop} {
    width: 180px;
  }
`;

const Card = styled(CardComponent)`
  ${mediaPhone} {
    padding: 0;
  }
`;
const Root = styled.div`
  ${Header} {
    margin-bottom: 32px;
  }
`;
