import { useGate, useStore } from "effector-react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { mediaDesktop, mediaPhone, useMedia } from "shared/ui/styles/media";
import { AddIcon, TrashIcon } from "shared/ui/icons";
import { LinkButton } from "shared/ui/button";
import { Card as CardComponent } from "shared/ui/card";
import { ActionDelete, ActionEdit, Table, TableActions } from "shared/ui/table";
import { deleteCity } from "@admin-app/features/manage-cities/delete";

import { MetaTags } from "shared/lib/metaTags";
import { ConfirmModal, useConfirmModal } from "shared/ui/modals";
import { City } from "shared/api/types";

import {
  $cities,
  pageGate,
  itemsPerPageChanged,
  pageChanged,
  $currentPage,
  $totalCities,
  $itemsPerPage,
} from "./model";

export function CitiesPage(): JSX.Element {
  useGate(pageGate);
  const { isDesktop } = useMedia();

  const cities = useStore($cities);
  const totalCities = useStore($totalCities);
  const currentPage = useStore($currentPage);
  const perPage = useStore($itemsPerPage);

  const [confirmDeleting, confirmModalProps] = useConfirmModal(
    (city: City) => deleteCity(city.id!),
    (city) => ({
      title: "Delete city?",
      message: `Are you sure you want to delete ${city.name}?`,
      Icon: TrashIcon,
      confirmText: "Yes, delete",
    })
  );

  return (
    <>
      <MetaTags title="Cities" />
      <Root>
        <Card>
          <Header>
            <TitleBlock>
              <Title>Cities</Title>
            </TitleBlock>
            <CreateButton as={LinkButton} to="cities/add" Icon={AddIcon}>
              {isDesktop && "Create city"}
            </CreateButton>
          </Header>
          {cities && (
            <Table
              items={cities}
              columns={[
                {
                  header: "ID",
                  renderCell: (city) => city?.id ?? "",
                },
                {
                  header: "City name",
                  renderCell: (city) => city.name,
                  align: "left",
                },
                {
                  header: "Action",
                  align: "right",
                  renderCell: (city) => (
                    <TableActions>
                      <Link to={`/cities/${city.id}/edit`}>
                        <ActionEdit />
                      </Link>
                      <ActionDelete onClick={() => confirmDeleting(city)} />
                    </TableActions>
                  ),
                },
              ]}
              pagination={{
                total: totalCities,
                onPageChange: pageChanged,
                onItemsPerPageChange: itemsPerPageChanged,
                currentPage: currentPage,
                itemsPerPage: perPage,
                rowsPerPageOptions: [5, 10, 20],
              }}
            />
          )}
        </Card>
      </Root>
      <ConfirmModal {...confirmModalProps} />
    </>
  );
}

const Title = styled.div`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  ${mediaPhone} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CreateButton = styled.div`
  white-space: nowrap;
  ${mediaDesktop} {
    width: 180px;
  }
`;

const Card = styled(CardComponent)`
  ${mediaPhone} {
    padding: 0;
  }
`;
const Root = styled.div`
  ${Header} {
    margin-bottom: 32px;
  }
`;
