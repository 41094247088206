import styled from "styled-components";
import { useStore, useStoreMap } from "effector-react";

import { Autocomplete, AutocompleteProps } from "shared/ui/ecosystems/forms";

import { $authorsList, inputChanged, $inputValue } from "../model";

interface Author {
  name: string;
  email: string;
  id: string;
}
type Props = Omit<AutocompleteProps<Author>, "options">;

export function ReservationAuthorSelectorComponent(props: Props): JSX.Element {
  const inputValue = useStore($inputValue);
  const options = useStoreMap($authorsList, (authors) =>
    authors.map((author) => ({
      name: author.name,
      email: author.email,
      id: author.id,
    }))
  );
  return (
    <Autocomplete
      label={props.label}
      freeSolo={false}
      options={options}
      getOptionLabel={(option) => option?.name ?? ""}
      value={props.value}
      onChange={props.onChange}
      inputValue={inputValue}
      onInputChange={inputChanged}
      renderOption={(props, option) => (
        <div {...props}>
          <Option key={option.id}>
            <Name>{option.name}</Name>
            <Email>{option.email}</Email>
          </Option>
        </div>
      )}
    />
  );
}

const Name = styled.div`
  text-align: left;
`;
const Email = styled.div`
  color: #969899;
  font-size: 14px;
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  align-items: start;
`;

export const ReservationAuthorSelector = styled(
  ReservationAuthorSelectorComponent
)``;
