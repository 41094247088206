import { createEffect } from "effector";
import { apiRequestFx } from "shared/api";

type CreateOverrideParams = {
  dock: string;
  loa: number;
  beam: number;
  timeFrom: string;
  timeTo: string;
  beforeTax: string;
  comment: string;
  isCharter: string;
};

export const createOverrideFx = createEffect(
  async (params: CreateOverrideParams) => {
    await apiRequestFx({
      method: "POST",
      path: "/api/manager/simple-reservations",
      body: params,
    });
  }
);

export type EditeOverrideParams = CreateOverrideParams & {
  overrideId: string;
};

export const editOverrideFx = createEffect(
  async ({ overrideId, ...params }: EditeOverrideParams) => {
    await apiRequestFx({
      method: "PUT",
      path: `/api/manager/simple-reservations/${overrideId}`,
      body: params,
    });
  }
);

export const cancelOverrideFx = createEffect(async (overrideId: string) => {
  await apiRequestFx({
    method: "POST",
    path: `/api/manager/reservations/${overrideId}/cancel`,
    body: {
      refundAmount: "0",
    },
  });
});
