import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { mediaDesktop, mediaPhone } from "shared/ui/styles/media";
import { ButtonBase } from "@mui/material";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const NavigationContext = React.createContext<{
  onItemClick(): unknown;
}>({
  onItemClick: () => null,
});

export function NavigationMenuComponent(props: Props): JSX.Element {
  return (
    <NavigationMenuRoot className={props.className}>
      {props.children}
    </NavigationMenuRoot>
  );
}

export const NavigationMenu = styled(NavigationMenuComponent)``;

export const NavigationMenuIcon = styled.div`
  --size: 24px;
  width: var(--size);
  height: var(--size);
`;

export const NavigationMenuItem = styled((props) => {
  const { onItemClick } = useContext(NavigationContext);

  function handleClick() {
    onItemClick();
    props.onClick?.();
  }

  if (props.to)
    return (
      <ButtonBase>
        <NavLink {...props} onClick={handleClick} exact={props.exact} />
      </ButtonBase>
    );
  return (
    <ButtonBase>
      <div {...props} onClick={handleClick} />
    </ButtonBase>
  );
})`
  display: flex;
  align-items: center;

  text-decoration: none;
  font-weight: 500;

  &.active {
    background: #3bb7b6;
    color: #fbfcff;
  }

  ${mediaDesktop} {
    width: 100%;
    height: 40px;
    border-radius: 600px;
    padding: 8px 16px;

    font-size: 16px;
    line-height: 20px;
    color: #fbfcff;

    ${NavigationMenuIcon} {
      margin-right: 12px;
    }
  }

  ${mediaPhone} {
    width: 100%;
    padding: 12px 20px;

    font-size: 16px;
    line-height: 26px;
    color: #2d4fa1;
    border-radius: 0 600px 600px 0;

    ${NavigationMenuIcon} {
      margin-right: 14px;
    }
  }
`;

const NavigationMenuRoot = styled.div`
  display: inline-grid;
  min-width: 210px;
  grid-template-columns: 1fr;

  ${mediaDesktop} {
    grid-gap: 10px;
  }
`;
