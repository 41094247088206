import { createEffect } from "effector";
import { parseByStatus, requestFx } from "shared/api/request";
import { GenericErrors } from "shared/api";
import * as typed from "typed-contracts";

type GetAddonCollection = {
  query?: {
    page?: number;
    itemsPerPage?: number;
    pagination?: boolean;
  };
};

const getAddonCollectionOk = typed.object({
  id: typed.number,
  name: typed.string,
});

type GetAddonCollectionDone = {
  status: "ok";
  answer: typed.Get<typeof getAddonCollectionOk>;
};
type GetAddonCollectionFail = GenericErrors;

export const getAddonCollection = createEffect<
  GetAddonCollection,
  GetAddonCollectionDone,
  GetAddonCollectionFail
>({
  async handler({ query }) {
    const name = "getAddonCollection.body";
    const response = await requestFx({
      path: "/api/addons",
      method: "GET",
      query,
    });
    return parseByStatus(name, response, {
      200: ["ok", getAddonCollectionOk],
    });
  },
});

type PostAddonCollection = {
  body: {
    name: string;
  };
};
type PostAddonCollectionDone = {
  status: "created";
  answer: typed.Get<typeof postAddonCollectionCreated>;
};

type PostAddonCollectionFail =
  | {
      status: "bad_request";
      error: typed.Get<typeof postAddonCollectionBadRequest>;
    }
  | {
      status: "unprocessable_entity";
      error: typed.Get<typeof postAddonCollectionUnprocessableEntity>;
    }
  | GenericErrors;

const postAddonCollectionCreated = typed.object({
  id: typed.number,
  name: typed.string,
});

const postAddonCollectionBadRequest = typed.nul;
const postAddonCollectionUnprocessableEntity = typed.nul;

export const postAddonCollection = createEffect<
  PostAddonCollection,
  PostAddonCollectionDone,
  PostAddonCollectionFail
>({
  async handler({ body }) {
    const name = "postAddonCollection.body";
    const response = await requestFx({
      path: "/api/addons",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      201: ["created", postAddonCollectionCreated],
      400: ["bad_request", postAddonCollectionBadRequest],
      422: ["unprocessable_entity", postAddonCollectionUnprocessableEntity],
    });
  },
});

type PutAddonItem = {
  body: {
    name: string;
  };
  path: {
    id: string;
  };
};

export const putAddonItemOk = typed.object({
  id: typed.number,
  name: typed.string,
});

type PutAddonItemDone = {
  status: "ok";
  answer: typed.Get<typeof putAddonItemOk>;
};

export const putAddonItemBadRequest = typed.nul;

export const putAddonItemNotFound = typed.nul;

export const putAddonItemUnprocessableEntity = typed.nul;
export type PutAddonItemFail =
  | {
      status: "bad_request";
      error: typed.Get<typeof putAddonItemBadRequest>;
    }
  | {
      status: "not_found";
      error: typed.Get<typeof putAddonItemNotFound>;
    }
  | {
      status: "unprocessable_entity";
      error: typed.Get<typeof putAddonItemUnprocessableEntity>;
    }
  | GenericErrors;

export const putAddonItem = createEffect<
  PutAddonItem,
  PutAddonItemDone,
  PutAddonItemFail
>({
  async handler({ body, path }) {
    const name = "putAddonItem.body";
    const response = await requestFx({
      path: `/api/addons/${path.id}`,
      method: "PUT",
      body,
    });
    return parseByStatus(name, response, {
      200: ["ok", putAddonItemOk],
      400: ["bad_request", putAddonItemBadRequest],
      404: ["not_found", putAddonItemNotFound],
      422: ["unprocessable_entity", putAddonItemUnprocessableEntity],
    });
  },
});

export type DeleteAddonItem = {
  path: {
    id: string;
  };
};

export const deleteAddonItemNoContent = typed.nul;
export type DeleteAddonItemDone = {
  status: "no_content";
  answer: typed.Get<typeof deleteAddonItemNoContent>;
};

export const deleteAddonItemNotFound = typed.nul;
export type DeleteAddonItemFail =
  | {
      status: "not_found";
      error: typed.Get<typeof deleteAddonItemNotFound>;
    }
  | GenericErrors;

export const deleteAddonItem = createEffect<
  DeleteAddonItem,
  DeleteAddonItemDone,
  DeleteAddonItemFail
>({
  async handler({ path }) {
    const name = "deleteAddonItem.body";
    const response = await requestFx({
      path: `/api/addons/${path.id}`,
      method: "DELETE",
    });
    return parseByStatus(name, response, {
      204: ["no_content", deleteAddonItemNoContent],
      404: ["not_found", deleteAddonItemNotFound],
    });
  },
});
