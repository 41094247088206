import { attach, createStore, sample } from "effector";
import { createFieldArray, createForm } from "effector-react-form";
import { object, string } from "yup";

import { createOpenAbleState } from "shared/lib/effector-openable-state";
import { createValidator } from "shared/lib/form";
import { requiredFieldValidationError } from "shared/config/error-text";
import { createNewCityFx } from "@admin-app/entities/cities";

import { imageUploaded as heroImageUploaded } from "../ui/upload-hero-image.model";
import { imageUploaded as cardImageUploaded } from "../ui/upload-card-image.model";

export const newCityForm = createForm({
  name: "Create City Form",
  initialValues: {
    name: "",
    state: "",
    description: "",
    heroImage: "",
    cardImage: "",
  },
  validate: createValidator(
    object({
      name: string().required(requiredFieldValidationError),
      state: string().required(requiredFieldValidationError),
      description: string(),
      heroImage: string().required("Please, upload an image."),
      cardImage: string().required("Please, upload an image."),
    })
  ),
  onSubmit: ({ values }) => createCityFx(values),
});

export const fieldArray = createFieldArray({
  form: newCityForm,
});

export const $formSubmitting = createStore(false);

const createCityFx = attach({
  effect: createNewCityFx,
  mapParams: (formData) => ({
    ...formData,
  }),
});

export const [successModal, successModalActions] = createOpenAbleState();
export const [unSuccessModal, unSuccessModalActions] = createOpenAbleState();

export const cityCreated = createCityFx.done;

sample({
  clock: heroImageUploaded,
  fn: (value) => ({ field: "heroImage", value }),
  target: newCityForm.setValue,
});

sample({
  clock: cardImageUploaded,
  fn: (value) => ({ field: "cardImage", value }),
  target: newCityForm.setValue,
});

sample({
  clock: cityCreated,
  target: successModalActions.open,
});
