import React from "react";

import { useMedia } from "shared/ui/styles/media";

import { AuthorizedAdminsPageTemplateDesktop } from "./authorized-admins-page-template.desktop";
import { AuthorizedPageTemplateMobile } from "./authorized-page-template.mobile";

interface Props {
  renderNavigation(): React.ReactNode;
  renderContent(): React.ReactNode;
  userLabel?: string;
  onSignOutClick(): void;
  user: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

export function AuthorizedAdminsPageTemplate(props: Props): JSX.Element {
  const { isDesktop } = useMedia();

  if (isDesktop) {
    return <AuthorizedAdminsPageTemplateDesktop {...props} />;
  } else {
    return <AuthorizedPageTemplateMobile {...props} />;
  }
}
