import { renderRoutes } from "react-router-config";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { useStore } from "effector-react";

import "shared/init";
import { postAdminLogout } from "shared/api";
import {
  AuthorizedAdminsPageTemplate,
  NavigationMenu,
  NavigationMenuIcon as NavIcon,
  NavigationMenuItem,
} from "shared/ui/templates/authorized-page-template";
import {
  UsersAddIcon,
  ListIcon,
  UsersIcon,
  AddOnsIcon,
  CompaniesIcon,
  ContentPageIcon,
} from "shared/ui/icons";
import { AppContext } from "shared/init";
import { syncHistoryWithState } from "shared/lib/effector-router-params";

import { useEffect } from "react";

import { $viewer, logout } from "../entities/viewer";
import { noAuthRoutes, authRoutes } from "./routes";
import { init } from "./init";

const history = createBrowserHistory();
syncHistoryWithState(history, authRoutes);
init();
export function App(): JSX.Element {
  const admin = useStore($viewer);
  return (
    <AppContext>
      <Router history={history}>
        {admin ? <Authorized /> : <NoAuthorized />}
      </Router>
    </AppContext>
  );
}

function NoAuthorized() {
  useEffect(() => {
    return syncHistoryWithState(history, noAuthRoutes);
  }, []);

  return renderRoutes(noAuthRoutes);
}

function Authorized() {
  const admin = useStore($viewer);

  useEffect(() => {
    return syncHistoryWithState(history, authRoutes);
  }, []);

  if (!admin) {
    return null;
  }

  return (
    <AuthorizedAdminsPageTemplate
      user={admin}
      userLabel="Admin"
      onSignOutClick={async () => {
        await postAdminLogout({});
        logout();
      }}
      renderContent={() => renderRoutes(authRoutes)}
      renderNavigation={() => (
        <NavigationMenu>
          <NavigationMenuItem to="/users-list" exact={false}>
            <NavIcon as={UsersIcon} /> Users
          </NavigationMenuItem>
          <NavigationMenuItem to="/reservations-list">
            <NavIcon as={ListIcon} />
            Reservations
          </NavigationMenuItem>
          <NavigationMenuItem to="/add-user">
            <NavIcon as={UsersAddIcon} />
            Add users
          </NavigationMenuItem>
          <NavigationMenuItem to="/add-ons">
            <NavIcon as={AddOnsIcon} />
            Add-ons
          </NavigationMenuItem>
          <NavigationMenuItem to="/companies-list">
            <NavIcon as={CompaniesIcon} />
            Companies
          </NavigationMenuItem>
          <NavigationMenuItem to="/terms-and-conditions/edit">
            <NavIcon as={ContentPageIcon} />
            Terms & Conditions
          </NavigationMenuItem>
          <NavigationMenuItem to="/reports">
            <NavIcon as={ListIcon} />
            Reports
          </NavigationMenuItem>
          <NavigationMenuItem to="/cities">
            <NavIcon as={AddOnsIcon} />
            Cities
          </NavigationMenuItem>
        </NavigationMenu>
      )}
    />
  );
}
