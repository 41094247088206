import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

import { mediaPhone } from "shared/ui/styles/media";
import { FormError } from "shared/ui/ecosystems/forms";

interface Props {
  header: string;
  subHeader: string;
  renderContent(): React.ReactNode;
  renderAction(): React.ReactNode;
  className?: string;
  error?: React.ReactNode;
}

export function FormAuthTemplate(props: Props): JSX.Element {
  return (
    <FormAuthTemplateRoot className={props.className}>
      <Header>{props.header}</Header>
      <SubHeader>{props.subHeader}</SubHeader>
      {props.error && <FormError>{props.error}</FormError>}
      <Content>{props.renderContent && props.renderContent()}</Content>
      <Action>{props.renderAction && props.renderAction()}</Action>
    </FormAuthTemplateRoot>
  );
}

export const FormFields = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`;

const Header = styled.div`
  font-family: "Kanit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  color: #0a1128;
  ${mediaPhone} {
    font-size: 24px;
    line-height: 31px;
  }
`;

const SubHeader = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #5b5b5b;
  ${mediaPhone} {
    font-size: 16px;
    line-height: 24px;
  }
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
`;

const Action = styled.div``;

const FormAuthTemplateRoot = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  ${mediaPhone} {
    min-width: 100%;
  }

  ${Header} {
    margin-bottom: 12px;
    ${mediaPhone} {
      align-self: center;
    }
  }
  ${SubHeader} {
    margin-bottom: 32px;
    ${mediaPhone} {
      text-align: center;
    }
  }

  ${FormError} {
    margin-bottom: 18px;
  }

  ${Action} {
    margin-top: 24px;
  }
`;
