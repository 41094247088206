import { createForm } from "effector-react-form";
import { createEffect, createEvent, createStore } from "effector";
import { object, string } from "yup";

import { createValidator } from "shared/lib/form";
import { createApiErrorStatusStore, fromApi } from "shared/api";
import { postAddonCollection } from "@admin-app/shared/api/addons";

import { requiredFieldValidationError } from "shared/config/error-text";

export const newAddonForm = createForm({
  name: "New Addon Form",
  validate: createValidator(
    object({
      name: string().required(requiredFieldValidationError),
    })
  ),
  onSubmit: ({ values }) => createAddonFx({ body: values }),
});

export const $formSubmitting = postAddonCollection.pending;

const createAddonFx = createEffect(fromApi(postAddonCollection));

export const $serverErrorStatus =
  createApiErrorStatusStore(postAddonCollection);

export const newAddonCreated = createAddonFx.done;

export const buttonAddClicked = createEvent();
export const modalAddClosed = createEvent();

export const $isModalAddOpened = createStore(false);

$isModalAddOpened
  .on(buttonAddClicked, () => true)
  .on(newAddonCreated, () => false)
  .reset(modalAddClosed);
