import { createEffect } from "effector";
import { apiRequestFx } from "shared/api";

type CreateCompanyParams = {
  name: string;
  bookingFee: number;
  bookingFeeType: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
};

export const createNewCompanyFx = createEffect(
  async (company: CreateCompanyParams) => {
    await apiRequestFx({
      method: "POST",
      path: "/api/companies",
      body: company,
    });
  }
);
