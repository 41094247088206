import { attach, createEvent, createStore, sample } from "effector";
import { createGate } from "effector-react";
import { createForm } from "effector-react-form";
import { object, string, InferType } from "yup";

import { createValidator } from "shared/lib/form";
import { postManagerCollection } from "shared/api";
import { phoneValidationError } from "shared/config/error-text";
import { companyCreated } from "@admin-app/entities/companies";

const newManagerFormSchema = object({
  email: string().email().required(" "),
  firstName: string().required(" "),
  lastName: string().required(" "),
  password: string().required(" "),
  company: string().required(" "),
  phone: string().phone("US", phoneValidationError),
});

type ManagerFormData = InferType<typeof newManagerFormSchema>;

export const newManagerForm = createForm({
  name: "New Manager Form",
  initialValues: {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    company: "",
    phone: "",
  },
  validate: createValidator(newManagerFormSchema),
  onSubmit: ({ values }) => submitFormFx(values),
});

const submitFormFx = attach({
  effect: postManagerCollection,
  mapParams: (managerData) => ({ body: managerData }),
});

export const formGate = createGate(newManagerForm);
export const $formSubmitting = postManagerCollection.pending;
export const $invitedManager = createStore<ManagerFormData | null>(null);

export const resetInvitedManager = createEvent();

$invitedManager
  .on(submitFormFx.done, (_, { params }) => params)
  .reset([resetInvitedManager, formGate.close]);

sample({
  clock: companyCreated,
  fn: (company) => ({
    field: "company",
    value: company["@id"],
  }),
  target: newManagerForm.setValue,
});

sample({
  clock: postManagerCollection.done,
  target: newManagerForm.reset,
});
