import { EditCityForm } from "@admin-app/features/manage-cities/edit";
import { CityFormTemplate } from "@admin-app/features/manage-cities/ui/form-template";
import { MetaTags } from "shared/lib/metaTags";

export function CityByIdEditPage(): JSX.Element {
  return (
    <CityFormTemplate title="Edit city">
      <MetaTags title="Edit city" />
      <EditCityForm />
    </CityFormTemplate>
  );
}
