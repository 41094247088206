import { createEffect } from "effector";
import { parseByStatus, requestFx } from "shared/api/request";
import { GenericErrors } from "shared/api";
import * as typed from "typed-contracts";

type GetCompaniesCollection = {
  query?: {
    page?: number;
    itemsPerPage?: number;
    pagination?: boolean;
  };
};

const getCompaniesCollectionOk = typed.object({
  id: typed.number,
  name: typed.string,
  createdAt: typed.string.optional,
  updateAt: typed.string.optional,
  bookingFee: typed.string,
  bookingFeeType: typed.string,
});

type GetCompaniesCollectionDone = {
  status: "ok";
  answer: typed.Get<typeof getCompaniesCollectionOk>;
};
type GetCompaniesCollectionFail = GenericErrors;

export const getCompaniesCollection = createEffect<
  GetCompaniesCollection,
  GetCompaniesCollectionDone,
  GetCompaniesCollectionFail
>({
  async handler({ query }) {
    const name = "getCompaniesCollection.body";
    const response = await requestFx({
      path: "/api/companies",
      method: "GET",
      query,
    });
    return parseByStatus(name, response, {
      200: ["ok", getCompaniesCollectionOk],
    });
  },
});

type PostCompaniesCollection = {
  body: {
    name: string;
    bookingFee: number;
    bookingFeeType: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phone: string;
  };
};
type PostCompaniesCollectionDone = {
  status: "created";
  answer: typed.Get<typeof postCompaniesCollectionCreated>;
};

type PostCompaniesCollectionFail =
  | {
      status: "bad_request";
      error: typed.Get<typeof postCompaniesCollectionBadRequest>;
    }
  | {
      status: "unprocessable_entity";
      error: typed.Get<typeof postCompaniesCollectionUnprocessableEntity>;
    }
  | GenericErrors;

const postCompaniesCollectionCreated = typed.object({
  id: typed.number,
  name: typed.string,
});

const postCompaniesCollectionBadRequest = typed.nul;
const postCompaniesCollectionUnprocessableEntity = typed.nul;

export const postCompaniesCollection = createEffect<
  PostCompaniesCollection,
  PostCompaniesCollectionDone,
  PostCompaniesCollectionFail
>({
  async handler({ body }) {
    const name = "postCompaniesCollection.body";
    const response = await requestFx({
      path: "/api/companies",
      method: "POST",
      body,
    });
    return parseByStatus(name, response, {
      201: ["created", postCompaniesCollectionCreated],
      400: ["bad_request", postCompaniesCollectionBadRequest],
      422: ["unprocessable_entity", postCompaniesCollectionUnprocessableEntity],
    });
  },
});

type GetCompanyItem = {
  path: {
    id: string;
  };
};

const getCompanyItemOk = typed.object({
  id: typed.number,
  name: typed.string,
  bookingFee: typed.string,
  bookingFeeType: typed.string,
  createdAt: typed.string.optional,
  updatedAt: typed.string.maybe,
});

export type GetCompanyItemDone = {
  status: "ok";
  answer: typed.Get<typeof getCompanyItemOk>;
};

export const getCompanyItemNotFound = typed.nul;
export type GetCompanyItemFail =
  | {
      status: "not_found";
      error: typed.Get<typeof getCompanyItemNotFound>;
    }
  | GenericErrors;

export const getCompanyItem = createEffect<
  GetCompanyItem,
  GetCompanyItemDone,
  GetCompanyItemFail
>({
  async handler({ path }) {
    const name = "getCompanyItem.body";
    const response = await requestFx({
      path: `/api/companies/${path.id}`,
      method: "GET",
    });
    return parseByStatus(name, response, {
      200: ["ok", getCompanyItemOk],
      404: ["not_found", getCompanyItemNotFound],
    });
  },
});

type PutCompanyItem = {
  body: {
    name: string;
  };
  path: {
    id: string;
  };
};

export const putCompaniesItemOk = typed.object({
  id: typed.number,
  name: typed.string,
});

type PutCompaniesItemDone = {
  status: "ok";
  answer: typed.Get<typeof putCompaniesItemOk>;
};

export const putCompaniesItemBadRequest = typed.nul;

export const putCompaniesItemNotFound = typed.nul;

export const putCompaniesItemUnprocessableEntity = typed.nul;
export type PutCompaniesItemFail =
  | {
      status: "bad_request";
      error: typed.Get<typeof putCompaniesItemBadRequest>;
    }
  | {
      status: "not_found";
      error: typed.Get<typeof putCompaniesItemNotFound>;
    }
  | {
      status: "unprocessable_entity";
      error: typed.Get<typeof putCompaniesItemUnprocessableEntity>;
    }
  | GenericErrors;

export const putCompaniesItem = createEffect<
  PutCompanyItem,
  PutCompaniesItemDone,
  PutCompaniesItemFail
>({
  async handler({ body, path }) {
    const name = "putCompaniesItem.body";
    const response = await requestFx({
      path: `/api/companies/${path.id}`,
      method: "PUT",
      body,
    });
    return parseByStatus(name, response, {
      200: ["ok", putCompaniesItemOk],
      400: ["bad_request", putCompaniesItemBadRequest],
      404: ["not_found", putCompaniesItemNotFound],
      422: ["unprocessable_entity", putCompaniesItemUnprocessableEntity],
    });
  },
});

export type DeleteCompanyItem = {
  path: {
    id: string;
  };
};

export const deleteCompanyItemNoContent = typed.nul;
export type DeleteCompanyItemDone = {
  status: "no_content";
  answer: typed.Get<typeof deleteCompanyItemNoContent>;
};

export const deleteCompanyItemNotFound = typed.nul;
export type DeleteCompanyItemFail =
  | {
      status: "not_found";
      error: typed.Get<typeof deleteCompanyItemNotFound>;
    }
  | GenericErrors;

export const deleteCompanyItem = createEffect<
  DeleteCompanyItem,
  DeleteCompanyItemDone,
  DeleteCompanyItemFail
>({
  async handler({ path }) {
    const name = "deleteCompanyItem.body";
    const response = await requestFx({
      path: `/api/companies/${path.id}`,
      method: "DELETE",
    });
    return parseByStatus(name, response, {
      204: ["no_content", deleteCompanyItemNoContent],
      404: ["not_found", deleteCompanyItemNotFound],
    });
  },
});

export type Company = typed.Get<typeof getCompaniesCollectionOk>;
