import { createGate } from "effector-react";
import { attach, createEvent, createStore } from "effector";
import { getListFromApi } from "shared/api";
import { getCompaniesCollection } from "@admin-app/shared/api/companies";
import { pagination, spreadListDataData } from "shared/lib/effector-paginatoin";
import { companyDeleted } from "@admin-app/features/manage-companies/delete/model";

interface Filters {
  itemsPerPage: number;
}

export const pageGate = createGate();

export const $companiesList = createStore([]);
export const $companiesTotalCount = createStore(0);

export const $currentPage = createStore(1);
export const $itemsPerPage = createStore(10);
export const $searchQuery = createStore<string | null>(null);

export const pageChanged = createEvent<number>();
export const itemsPerPageChanged = createEvent<number>();
export const searchQueryChanged = createEvent<string>();

const $filters = $itemsPerPage.map((state) => ({ itemsPerPage: state }));

$currentPage.on(pageChanged, (_, page) => page);
$searchQuery.on(searchQueryChanged, (_, value) => value).reset(pageGate.close);
$itemsPerPage.on(itemsPerPageChanged, (_, value) => value);

const getCompaniesListFx = attach({
  // @ts-ignore
  effect: getListFromApi(getCompaniesCollection, { ignoreValidation: true }),
  mapParams: (query: Filters & { page: number }) => ({
    query,
  }),
});

spreadListDataData({
  $items: $companiesList,
  $totalCount: $companiesTotalCount,
  effect: getCompaniesListFx,
});

pagination({
  $page: $currentPage,
  $filters: $filters,
  effect: getCompaniesListFx,
  gate: pageGate,
  reloadTrigger: companyDeleted,
});
