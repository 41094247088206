import { useField } from "shared/lib/form";

import {
  DateInput,
  DateInputProps,
  DateTimeInput,
  DateTimeInputProps,
  TimeInput,
} from "../atoms/date-input";

type FieldProps<T> = Omit<T, "value" | "onChange"> & { name: string };

type Props = FieldProps<DateTimeInputProps>;

export function DateField(props: FieldProps<DateInputProps>): JSX.Element {
  const field = useField({ name: props.name });
  return (
    <DateInput
      {...field.input}
      error={field.isShowError ? field.error : ""}
      {...props}
    />
  );
}

export function TimeField(props: Props): JSX.Element {
  const field = useField({ name: props.name });
  return (
    <TimeInput
      {...field.input}
      error={field.isShowError ? field.error : ""}
      {...props}
    />
  );
}

export function DateTimeField(props: Props): JSX.Element {
  const field = useField({ name: props.name });
  return (
    <DateTimeInput
      {...field.input}
      error={field.isShowError ? field.error : ""}
      {...props}
    />
  );
}
