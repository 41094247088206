import React from "react";
import styled from "styled-components";

import { mediaPhone } from "shared/ui/styles/media";

import { DowntownLogo } from "shared/ui/downtown-logo";

import backgroundDesktopImage from "./background-desktop.jpg";
import backgroundMobileImage from "./background-mobile.jpg";
interface Props {
  header: string;
  renderContent(): React.ReactNode;
}

export function AuthPageTemplate(props: Props): JSX.Element {
  return (
    <AuthPageTemplateRoot>
      <FirstPanel>
        <DowntownLogo />
        <Header>{props.header}</Header>
      </FirstPanel>
      <SecondPanel>
        <Content>{props.renderContent()}</Content>
      </SecondPanel>
    </AuthPageTemplateRoot>
  );
}

const Header = styled.div`
  color: #fbfcff;
  font-weight: 400;
  font-size: 24px;
  line-height: 39px;
  ${mediaPhone} {
    font-size: 20px;
    line-height: 26px;
  }
`;

const FirstPanel = styled.div`
  padding-top: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${DowntownLogo} {
    width: 280px;
    height: 75px;
    margin-bottom: 80px;
  }

  ${mediaPhone} {
    padding-top: 92px;
    ${DowntownLogo} {
      width: 140px;
      height: 44px;
      margin-bottom: 32px;
    }
  }
`;

const Content = styled.div`
  max-width: 420px;
  width: 100%;
  color: black;
`;

const SecondPanel = styled.div`
  background: #f5fafa;
  border-radius: 64px 0 0 64px;

  padding: 160px 64px 0 64px;
  ${mediaPhone} {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 64px 64px 0 0;
    padding: 56px 16px 0 16px;
  }
`;

const AuthPageTemplateRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  min-height: 100vh;
  background-color: #273338;
  background-size: cover;
  background-image: url(${backgroundDesktopImage});

  ${mediaPhone} {
    grid-template-columns: 1fr;
    background-image: url(${backgroundMobileImage});
  }
`;
