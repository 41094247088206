import React from "react";
import styled from "styled-components";
import { Dialog } from "@mui/material";

import { CloseButton } from "../atoms";

interface RenderFunctionParams {
  close?(): void;
}

interface Props {
  isOpened: boolean;
  onClose?(): void;
  renderTitle?(params: RenderFunctionParams): React.ReactNode;
  renderContent(params: RenderFunctionParams): React.ReactNode;
  renderActions?(params: RenderFunctionParams): React.ReactNode;
  className?: string;
}

export function SmallModalTemplate(props: Props): JSX.Element {
  const params = {
    close: props.onClose,
  };

  return (
    <SmallModal
      open={props.isOpened}
      onClose={props.onClose}
      className={props.className}
    >
      {props.onClose && <CloseButton onClick={props.onClose} />}
      {props.renderTitle && <Title>{props.renderTitle(params)}</Title>}
      <Content>{props.renderContent(params)}</Content>
      {props.renderActions && <Actions>{props.renderActions(params)}</Actions>}
    </SmallModal>
  );
}

export const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #0a1128;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5e616c;
`;

export const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const iconColorMap = {
  info: "#B0E9E9",
  danger: "#F1928D",
};

export const ModalIcon = styled.div<{ variant?: "info" | "danger" }>`
  width: 48px;
  height: 48px;
  color: ${(props) => iconColorMap[props.variant ?? "info"]};
`;

export const SmallModal = styled(({ className, ...props }) => (
  <Dialog {...props} classes={{ paper: className }} />
))`
  width: 300px;
  border-radius: 32px !important;
  padding: 20px 16px 16px;

  ${Title} {
    margin-bottom: 30px;
  }

  ${Actions} {
    margin-top: 40px;
  }

  //position: relative;
  ${CloseButton} {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  ${ModalIcon} {
    margin-bottom: 22px;
  }
`;
