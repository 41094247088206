import { attach, createEffect, createStore, sample } from "effector";
import { createGate } from "effector-react";

import { createRouteParamStore } from "shared/lib/effector-router-params";
import {
  fromApi,
  getClientItem,
  getListFromApi,
  getReservationCollection,
} from "shared/api";
import { Client } from "shared/api/types";
export { reservationCanceled } from "features/cancel-reservation";

export const clientPageGate = createGate();

const $clientId = createRouteParamStore({
  name: "customerId",
  gate: clientPageGate,
});

export const getClientsReservationsFx = attach({
  // @ts-ignore
  effect: getListFromApi(getReservationCollection),
  source: $clientId,
  mapParams: (query, clientId) => ({
    query: {
      ...query,
      client: clientId,
      pagination: false,
    },
  }),
});

export const $client = createStore<Client | null>(null);

const getClientInfoFX = attach({
  effect: createEffect(fromApi(getClientItem)),
  source: $clientId,
  mapParams: (_, clientId) => ({ path: { id: clientId as string } }),
});

$client
  .on(getClientInfoFX.doneData, (_, data) => data)
  .reset(clientPageGate.close);

sample({
  clock: clientPageGate.open,
  target: [getClientInfoFX, getClientsReservationsFx],
});
