import { number, object, string } from "yup";
import { createForm } from "effector-react-form";
import { combine, Effect, Store, attach } from "effector";
import { and } from "patronum";

import { createValidator, formField, formFieldChanged } from "shared/lib/form";
import { requiredFieldValidationError } from "shared/config/error-text";

import {
  createCalculatePriceModel,
  createReservationsDateTimeModel,
} from "entities/reservations";
import { $docksList } from "entities/docks";
import { Client } from "shared/api/types";

interface FormParams {
  onSubmit: Effect<any, any>;
  getAvailableItemsFx: Effect<
    { hours: number; monthStartDate: string },
    Record<string, { from: string; to: string }[]>
  >;
  $isFormOpen: Store<boolean>;
}

export function createOverrideForm(params: FormParams) {
  const reservationForm = createForm<{
    loa: string | null;
    beam: string | null;
    dock: string;
    totalPrice: string;
    date: string;
    time: {
      from: string;
      to: string;
    } | null;
    duration: number | null;
    isCharter: boolean;
    isSuperOverride: boolean;
    comment: string;
    customer: Client | null;
  }>({
    validate: managerReservationValidator,
    initialValues: {
      loa: null,
      beam: null,
      dock: "",
      totalPrice: "",
      date: "",
      time: null,
      duration: null,
      isCharter: false,
      isSuperOverride: false,
      comment: "",
      customer: null,
    },
    onSubmit: async () => {
      await formSubmitFx({});
    },
  });

  const $duration = formField(reservationForm, "duration");
  const $selectedDate = formField(reservationForm, "date");
  const $selectedTime = formField(reservationForm, "time");
  const $loa = formField(reservationForm, "loa");
  const $beam = formField(reservationForm, "beam");
  const $dock = formField(reservationForm, "dock");
  const $isCharter = formField(reservationForm, "isCharter");
  const $price = formField(reservationForm, "totalPrice");
  const $comment = formField(reservationForm, "comment");
  const $isSuperOverride = formField(reservationForm, "isSuperOverride");

  const $selectedDock = combine(
    $docksList,
    $dock,
    (docksList, id) => docksList.find((dock) => dock["@id"] === id) ?? null
  );
  const $boatInfo = combine($loa, (loa) => (loa ? { loa } : null));

  const dateTimeModel = createReservationsDateTimeModel({
    $dock: $selectedDock,
    $boat: $boatInfo,
    $duration: $duration,
    $selectedDate,
    $selectedTime,
    $isSuperOverride,
    timeChanged: formFieldChanged(reservationForm, "time"),
    getAvailableItemsFx: params.getAvailableItemsFx,
    getAvailableItemsTrigger: params.onSubmit.fail,
  });

  const { $dockPrice, $bookingFee, $charterFee } = createCalculatePriceModel({
    $dataForCalculation: combine({
      loa: $loa,
      beam: $beam,
      timeFrom: dateTimeModel.$timeFrom,
      timeTo: dateTimeModel.$timeTo,
      dock: $dock,
      isCharter: $isCharter,
    }),
    filter: and(
      $loa,
      $beam,
      dateTimeModel.$timeFrom,
      dateTimeModel.$timeTo,
      $dock
    ),
    priceChanged: formFieldChanged(reservationForm, "totalPrice"),
  });

  const formSubmitFx = attach({
    effect: params.onSubmit,
    source: {
      dock: $dock,
      loa: $loa,
      beam: $beam,
      timeFrom: dateTimeModel.$timeFrom,
      timeTo: dateTimeModel.$timeTo,
      beforeTax: $price.map((price) => price?.toString()),
      comment: $comment,
      isCharter: $isCharter,
      isSuperOverride: $isSuperOverride,
    },
  });

  return {
    form: reservationForm,
    $duration,
    $selectedDate,
    $selectedTime,
    $loa,
    $beam,
    $dock,
    $isCharter,
    $price,
    $comment,
    $selectedDock,
    $dockPrice,
    $bookingFee,
    $charterFee,
    dateTimeModel,
  };
}

const numberSchema = number()
  .required(requiredFieldValidationError)
  .nullable()
  .typeError(" ");

export const managerReservationValidator = createValidator(
  object({
    dock: string().required(requiredFieldValidationError).typeError(" "),
    loa: numberSchema.min(0, " "),
    beam: numberSchema.min(0, " "),
    date: string().required(requiredFieldValidationError).typeError(" "),
    time: object().required(requiredFieldValidationError).typeError(" "),
    duration: numberSchema,
    totalPrice: string().required(requiredFieldValidationError).typeError(" "),
    comment: string(),
  })
);
