import { createForm, ErrorsInline } from "effector-react-form";
import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
  Store,
} from "effector";

import { createApiErrorStatusStore, postCancelReservation } from "shared/api";

import { Reservation } from "shared/api/types";

import { managerCanceledReservationFx } from "./api";

export const $isCancelModalOpened = createStore(false);
export const $reservation = createStore<Reservation | null>(null);
const $refundPrice = $reservation.map(
  (reservation) => reservation?.price?.refundPrice
);
export const $serverErrorStatus = createApiErrorStatusStore(
  postCancelReservation
);

export const cancelReservationForm = createForm({
  name: "Cancel Reservation Form",
  initialValues: {
    refundOption: "full",
    partialRefund: 0,
  },
  onSubmit: (values) => cancelReservationFx(values),
});

const $refundOption: Store<string> = cancelReservationForm.$values.map(
  (values) => values.refundOption
);

const $partialRefund: Store<number> = cancelReservationForm.$values.map(
  (values) => values.partialRefund
);

const $refundAmount = combine(
  $refundOption,
  $partialRefund,
  $refundPrice,
  (refundOption, partialRefund, refundPrice) => {
    switch (refundOption) {
      case "none": {
        return 0;
      }
      case "partial": {
        return partialRefund;
      }
      case "full": {
        return refundPrice as number;
      }
      default: {
        return 0;
      }
    }
  }
);

export const $formSubmitting = postCancelReservation.pending;

export const reservationCanceled = createEvent<Reservation>();
export const partialRefundFocused = createEvent();

export const cancelModalClosed = createEvent();
const setError = createEvent<ErrorsInline>();

const validatePartialRefundFx = createEffect(
  (params: {
    partialRefund: number;
    refundPrice: number | void | undefined;
  }) => {
    const error: ErrorsInline = { field: "partialRefund" };

    if (params.refundPrice && params.partialRefund > params.refundPrice) {
      error.error = `Maximum refund amount ${params.refundPrice} $`;
    }
    setError(error);
  }
);

const cancelReservationFx = attach({
  effect: managerCanceledReservationFx,
  source: combine({ reservation: $reservation, refundAmount: $refundAmount }),
  mapParams: (_, data) => ({
    reservationId: data.reservation!.id,
    refundAmount: data.refundAmount.toString(),
  }),
});

sample({
  source: {
    partialRefund: $partialRefund,
    refundPrice: $refundPrice,
  },
  target: validatePartialRefundFx,
});

sample({
  clock: partialRefundFocused,
  fn: () => ({
    field: "refundOption",
    value: "partial",
  }),
  target: cancelReservationForm.setValue,
});

sample({
  clock: $refundOption,
  filter: (refundOption) => refundOption !== "partial",
  fn: () => ({
    field: "partialRefund",
    value: 0,
  }),
  target: cancelReservationForm.setValue,
});

sample({
  // @ts-ignore
  source: setError,
  target: cancelReservationForm.setOrDeleteOuterError,
});

export const reservationIsCanceled = cancelReservationFx.done;

$reservation.on(reservationCanceled, (_, value) => value);

$isCancelModalOpened.on(reservationIsCanceled, () => false);

$isCancelModalOpened.on(reservationCanceled, () => true);
$isCancelModalOpened.reset(cancelModalClosed);
