import React from "react";
import {
  FormControl,
  Autocomplete as MaterialAutoComplete,
  FormHelperText,
  TextField,
} from "@mui/material";
import styled from "styled-components";

import { getErrorProps } from "./input";

export interface AutocompleteProps<T> {
  label: string;
  className?: string;
  name?: string;
  value: T;
  options: T[];
  onChange(event: React.SyntheticEvent, value: T): void;
  onInputChange?(event: React.SyntheticEvent, value: string): void;
  getOptionLabel?(label: string): string;
  error?: string | null;
  disabled?: boolean;
  freeSolo?: boolean;
}

function AutocompleteComponent<T>(props: AutocompleteProps<T>) {
  const { error, ...autocompleteProps } = props;
  return (
    <FormControl {...getErrorProps(error)} className={props.className}>
      <MaterialAutoComplete
        {...autocompleteProps}
        disablePortal
        freeSolo={autocompleteProps.freeSolo ?? true}
        onInputChange={
          autocompleteProps.onInputChange
            ? (_, value) => {
                autocompleteProps.onInputChange(value);
              }
            : (_, value) => autocompleteProps.onChange(value)
        }
        onChange={(_, value) => autocompleteProps.onChange(value)}
        renderInput={(params) => (
          <TextField {...params} label={autocompleteProps.label} />
        )}
        getOptionLabel={autocompleteProps?.getOptionLabel}
      />
      <FormHelperText id="autocomplete-error-text">
        {getErrorProps(error).helperText}
      </FormHelperText>
    </FormControl>
  );
}

export const Autocomplete = styled(AutocompleteComponent)``;
