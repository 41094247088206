import { attach, createEvent, sample } from "effector";
import { deleteCity as deleteCityApi } from "@admin-app/shared/api/cities";

export const deleteCity = createEvent<number>();
export const cityDeleted = createEvent<number>();

const deleteCityFx = attach({
  effect: deleteCityApi,
  // @ts-ignore
  mapParams: (id: number) => ({ path: { id } }),
});

sample({
  clock: deleteCity,
  target: deleteCityFx,
});

sample({
  clock: deleteCityFx.done,
  fn: ({ params }) => params,
  target: cityDeleted,
});
