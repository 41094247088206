import React from "react";
import styled from "styled-components";

import { DowntownLogo } from "shared/ui/downtown-logo";
import { Avatar } from "shared/ui/avatar/avatar";

import { ReactComponent as LogoutIcon } from "./logout.svg";
import { NavigationMenuIcon, NavigationMenuItem } from "./navigation-menu";

interface Props {
  renderNavigation(): React.ReactNode;
  renderContent(): React.ReactNode;
  userLabel?: string;
  onSignOutClick(): void;
  user: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

export function AuthorizedAdminsPageTemplateDesktop(props: Props): JSX.Element {
  const userName = [props.user.firstName, props.user.lastName].join(" ");

  return (
    <Root>
      <Header>
        <HeaderContent>
          <UserInfo>
            <div>
              <UserName>{userName}</UserName>
              <UserEmail>{props.user.email}</UserEmail>
            </div>
            <Avatar name={userName} />
          </UserInfo>
        </HeaderContent>
      </Header>
      <SideBar>
        <SideBarHeader>
          <DowntownLogo />
          {props.userLabel && <UserTypeLabel>{props.userLabel}</UserTypeLabel>}
        </SideBarHeader>

        <SideBarContent>{props.renderNavigation()}</SideBarContent>
        <LogoutBlock>
          <NavigationMenuItem onClick={props.onSignOutClick}>
            <NavigationMenuIcon as={LogoutIcon} /> Logout
          </NavigationMenuItem>
        </LogoutBlock>
      </SideBar>
      <Content>{props.renderContent()}</Content>
    </Root>
  );
}

const UserName = styled.div`
  font-size: 20px;
  line-height: 26px;
`;

const UserEmail = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #5e616c;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
  font-weight: 500;
  ${Avatar} {
    width: 48px;
    height: 48px;
    font-size: 24px;
    margin-left: 16px;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  padding-left: var(--contentPadding);
  padding-right: var(--contentPadding);
`;

const Header = styled.div`
  height: var(--headerHeight);
  width: 100vw;
  max-width: 100%;
  background: #fbfcff;
  border-bottom: 1px solid #e7ebf7;
  display: flex;

  ${HeaderContent} {
    margin-right: var(--sidebarWidth);
    flex: 1;
  }
`;

const UserTypeLabel = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 4px 32px;
  background: #5e88ef;
  border-radius: 600px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #fbfcff;
`;

const SideBarHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${DowntownLogo} {
    margin-bottom: 24px;
  }
`;

const SideBarContent = styled.div`
  width: 100%;
  padding: 0 var(--contentPadding);
`;

const LogoutBlock = styled.div`
  display: flex;
  align-items: center;

  border-top: 1px solid #2f3895;
  padding: 0 112px 0 60px;
  height: 88px;
  width: 100%;
`;

const SideBar = styled.div`
  width: var(--sideBarWidth, 250px);
  height: 100vh;
  padding-top: 40px;
  background: #070e54;
  border-radius: 0 80px 80px 0;

  ${DowntownLogo} {
    width: 175px;
    height: 55px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  ${SideBarContent} {
    margin-top: 70px;
    flex: 1;
  }
`;

const Content = styled.div`
  padding: 24px var(--contentPadding);
  min-height: calc(100vh - var(--headerHeight));
`;

const Root = styled.div`
  --contentPadding: 32px;
  --headerHeight: 80px;
  --sideBarWidth: 250px;

  background-color: #e5e5e5;

  ${Header} {
    position: sticky;
    top: 0;
    left: 0;
    padding-left: var(--sideBarWidth);
    z-index: 1;
  }

  ${SideBar} {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
  }

  ${Content} {
    margin-left: var(--sideBarWidth);
  }
`;
