import { useUnit } from "effector-react";

import { ConfirmModal } from "shared/ui/modals";

import {
  $isCancelOverrideModalOpened,
  cancelOverrideModalActions,
  overrideCancelConfirmed,
  $override,
} from "../model/cancel-override";

export function ConfirmCancelOverrideModal(): JSX.Element {
  const [isCancelOverrideModalOpened, override] = useUnit([
    $isCancelOverrideModalOpened,
    $override,
  ]);

  return (
    <ConfirmModal
      title="Cancel Override?"
      isOpened={isCancelOverrideModalOpened}
      onCancel={cancelOverrideModalActions.close}
      onConfirm={overrideCancelConfirmed}
      message={
        <>Are you sure you want to cancel reservation № {override?.id} ? </>
      }
    />
  );
}
