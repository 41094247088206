import React from "react";
import styled from "styled-components";
import { InputAdornment, TextField } from "@mui/material";
import { ClockPickerView } from "@mui/x-date-pickers/internals/models";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";

import {
  CalendarIcon,
  ClockIcon,
  CloseIcon as ClearIcon,
} from "shared/ui/icons";

import { useOpenableState } from "shared/lib/use-openable-state";
import { Dayjs } from "dayjs";

import { getErrorProps } from "./input";

export interface DateInputProps {
  value: string | undefined;
  onChange(value: string | undefined): void;
  label?: string;
  error?: string | null;
  disabled?: boolean;
  onMonthChange?(day: Dayjs): void;
  shouldDisableDate?(day: Dayjs): boolean;
}

function DateInputComponent(props: DateInputProps): JSX.Element {
  const openedState = useOpenableState();
  return (
    <DatePicker
      value={props.value}
      onChange={(value) => props.onChange(value?.toString?.())}
      disabled={props.disabled}
      open={openedState.isOpened}
      onClose={openedState.close}
      onMonthChange={props.onMonthChange}
      shouldDisableDate={props.shouldDisableDate}
      renderInput={(params) => (
        <TextField
          onClick={!props.disabled && openedState.open}
          {...params}
          {...getErrorProps(props.error)}
          label={props.label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <Icons>
                  <Icon as={CalendarIcon} />
                </Icons>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}

interface TimeInputProps {
  value: Dayjs;
  onChange(value: Dayjs | null): void;
  label?: string;
  error?: string | null;
}

function TimeInputComponent(props: TimeInputProps): JSX.Element {
  const openedState = useOpenableState();

  return (
    <TimePicker
      value={props.value}
      minutesStep={5}
      onChange={props.onChange}
      open={openedState.isOpened}
      onClose={openedState.close}
      renderInput={(params) => (
        <TextField
          onClick={openedState.open}
          {...params}
          {...getErrorProps(props.error)}
          label={props.label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <Icons>
                  <Icon as={ClockIcon} />
                </Icons>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
export interface DateTimeInputProps {
  value: string | null;
  onChange(value: Dayjs | null): void;
  label?: string;
  error?: string | null;
  minDateTime?: Dayjs;
  minDate?: Dayjs;
  minTime?: Dayjs;
  maxTime?: Dayjs;
  minutesStep?: number;
  shouldDisableDate?(day: Dayjs): boolean;
  shouldDisableTime?(value: number, units: ClockPickerView): boolean;
}

function DateTimeInputComponent(props: DateTimeInputProps): JSX.Element {
  const openedState = useOpenableState();

  const handleClear = (event: React.UIEvent) => {
    event.stopPropagation();
    props.onChange(null);
  };

  const minTime = props.minTime;
  const maxTime = props.maxTime;

  function handleChange(value) {
    if (value) {
      props.onChange(value);
    }
  }

  return (
    <DateTimePicker
      value={props.value}
      onChange={handleChange}
      open={openedState.isOpened}
      onClose={openedState.close}
      minDateTime={props.minDateTime}
      minDate={props.minDate}
      minTime={minTime}
      maxTime={maxTime}
      minutesStep={props.minutesStep}
      shouldDisableDate={props.shouldDisableDate}
      shouldDisableTime={props.shouldDisableTime}
      showToolbar
      PopperProps={{
        placement: "auto",
      }}
      renderInput={(params) => (
        <>
          <TextField
            onClick={openedState.open}
            {...params}
            {...getErrorProps(props.error)}
            label={props.label}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icons>
                    <Clear onClick={handleClear}>
                      <Icon as={ClearIcon} />
                    </Clear>
                    <Icon as={CalendarIcon} />
                    <Icon as={ClockIcon} />
                  </Icons>
                </InputAdornment>
              ),
            }}
          />
        </>
      )}
    />
  );
}

const Clear = styled.div`
  cursor: pointer;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
`;

const Icons = styled.div`
  color: #2d4fa1;
  display: flex;
  gap: 14px;
`;

export const DateInput = styled(DateInputComponent)``;
export const TimeInput = styled(TimeInputComponent)``;
export const DateTimeInput = styled(DateTimeInputComponent)``;
