import styled from "styled-components";

import { mediaPhone } from "shared/ui/styles/media";
import { Card, CardTitle } from "shared/ui/card";
import { MetaTags } from "shared/lib/metaTags";
import { createReservationsTable } from "features/reservation-searching";
import { EditManagerReservationModal } from "@manager-app/features/manage-overide";
import { EditClientReservationModal } from "features/edit-client-reservation";

import { getReservationsListFx } from "./model";

const ReservationsTable = createReservationsTable({
  getReservationsFx: getReservationsListFx,
  filtering: true,
  showCompanyName: true,
  showClientDetails: true,
});

export function ReservationsListPage(): JSX.Element {
  return (
    <Root as={Card}>
      <MetaTags title="Reservations" />
      <Header>
        <Title>List of Reservations</Title>
      </Header>

      <ReservationsTable />
      <EditClientReservationModal />
      <EditManagerReservationModal />
    </Root>
  );
}

const Title = styled(CardTitle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 36px;
  line-height: 48px;
  ${mediaPhone} {
    font-size: 24px;
    line-height: 28px;
  }
`;

const Header = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;
`;

const Root = styled.div``;
