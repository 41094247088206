import { useState } from "react";
import styled from "styled-components";

import { Select } from "shared/ui/ecosystems/forms/atoms/select";
import {
  FormTemplateContainer,
  SubHeader,
} from "shared/ui/templates/form-template";

import { Card } from "shared/ui/card";
import { mediaPhone } from "shared/ui/styles/media";
import { NewAdminForm } from "@admin-app/features/new-admin";
import { NewManagerForm } from "@admin-app/features/manage-managers";
import { MetaTags } from "shared/lib/metaTags";

export function AddUserPage(): JSX.Element {
  const [role, setRole] = useState<"admin" | "manager">("admin");
  const handleChange = (value: "admin" | "manager") => {
    setRole(value);
  };
  return (
    <>
      <MetaTags title="Add user" />
      <Root>
        <Card>
          <FormTemplateContainer>
            <Header>Add Admin or Dock Manager</Header>
            <SubHeader>Fill in the data required for the reservation</SubHeader>

            <UserTypeSelect
              as={Select}
              label="Select role"
              value={role}
              onChange={handleChange}
              options={rolesOptions}
            />

            {(() => {
              if (role === "admin") {
                return <NewAdminForm />;
              } else if (role === "manager") {
                return <NewManagerForm />;
              } else return null;
            })()}
          </FormTemplateContainer>
        </Card>
      </Root>
      {/*<NewAdminSuccessModal isOpened={isModalOpened} onClose={modalClosed} />*/}
    </>
  );
}

const rolesOptions = [
  { label: "Admin", value: "admin" },
  {
    label: "Dock manager",
    value: "manager",
  },
];

const Header = styled.div`
  font-family: "Kanit", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
`;

const UserTypeSelect = styled.div``;

const Root = styled.div`
  ${Card} {
    max-width: 716px;
  }
  ${Header} {
    margin-bottom: 8px;
  }
  ${UserTypeSelect} {
    margin-bottom: 16px;
    width: calc(50% - 12px);
    ${mediaPhone} {
      width: 100%;
    }
  }
`;
