import React from "react";
import { useField } from "shared/lib/form";

import {
  DistanceInput,
  FtInput,
  Input,
  TextAreaInput,
  PhoneInput,
  PriceInput,
  NumberInput,
  TaxInput,
} from "../atoms/input";

type FieldProps<T> = Omit<T, "value" | "onChange"> & { name: string };

export function createFormField<I, O, P>(
  Component: React.FunctionComponent<P>,
  params?: {
    parse?(value: I): O;
    format?(value: O): I;
  }
): React.FunctionComponent<FieldProps<P>> {
  return function FieldComponent(props: FieldProps<P>): JSX.Element {
    const { input, ...field } = useField({ name: props.name });

    const value = input.value;

    return (
      // @ts-ignore
      <Component
        {...props}
        onChange={(value: O) => {
          if (params?.format) {
            input.onChange(params.format(value));
          } else {
            input.onChange(value);
          }
        }}
        value={params?.parse?.(value) ?? value ?? ""}
        error={field.isShowError ? field.error : ""}
      />
    );
  };
}

export const InputField = createFormField(Input);

export const LettersField = createFormField(Input, {
  format: (value: string) => value.replaceAll(/[^a-zA-Z\s]/g, ""),
});

export const NumberField = createFormField(Input, {
  format: formatIntFormValue,
});

export const TextAreaField = createFormField(TextAreaInput);

export const PhoneField = createFormField(PhoneInput);

export const FtInputField = createFormField(FtInput, {
  format: formatIntFormValue,
});

export const NumberInputField = createFormField(NumberInput, {
  format: formatIntFormValue,
});

export const FloatInputField = createFormField(NumberInput, {
  format: formatFloatFormValue,
});

export const PriceField = createFormField(PriceInput, {
  format: formatFloatFormValue,
});

export const TaxField = createFormField(TaxInput, {
  format: formatFloatFormValue,
});

export const DistanceField = createFormField(DistanceInput, {
  format: formatFloatFormValue,
});

function formatFloatFormValue(value: string): number | null {
  const formattingValue = parseFloat(
    value
      .replaceAll(/[^\d.-]|/g, "")
      .replace(/(^00)/, "0.0")
      .replace(/^\./, "0.")
      .replace(/\.(.*)\./, ".$1")
  );
  return !Number.isNaN(formattingValue) ? formattingValue : null;
}

function formatIntFormValue(value: string): number | null {
  const formattingValue = parseInt(value.replaceAll(/\D/g, ""));

  return !Number.isNaN(formattingValue) ? formattingValue : null;
}
