import dayjs, { Dayjs } from "dayjs";

import { Dock } from "shared/api/types";
import { time } from "shared/lib/dayjs-ext";

export const daysKeys = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

type Day = {
  from: Dayjs;
  to: Dayjs;
};
export type FormHours = Record<typeof daysKeys[number], Day>;

export type ApiHours = {
  dayOfWeek: number;
  timeFrom: string;
  timeTo: string;
}[];

export function convertHoursMapToArray(daysMap: FormHours): ApiHours {
  return daysKeys.reduce<any>((operationalHours, dayKey, index) => {
    if (daysMap[dayKey]) {
      operationalHours.push({
        dayOfWeek: index + 1,
        timeFrom: daysMap[dayKey].from.format("HH:mm"),
        timeTo: daysMap[dayKey].to.format("HH:mm"),
      });
    }
    return operationalHours;
  }, []);
}

export function convertHoursFromArrayToMap(
  hoursFromApi: ApiHours,
  convertToDate = true
): FormHours {
  const byDayNumberMap: Record<number, Day> = hoursFromApi.reduce(
    (map, apiItem) => {
      map[apiItem.dayOfWeek] = {
        // @ts-ignore
        from: convertToDate
          ? timeStringToDayJs(apiItem.timeFrom)
          : apiItem.timeFrom,
        // @ts-ignore
        to: convertToDate ? timeStringToDayJs(apiItem.timeTo) : apiItem.timeTo,
      };
      return map;
    },
    {}
  );

  return daysKeys.reduce((map, dayKey, index) => {
    if (!!byDayNumberMap[index + 1]) {
      map[dayKey] = byDayNumberMap[index + 1];
    }
    return map;
  }, {});
}

export function getMaxDayDuration(dock: Dock): number {
  const durations =
    dock.operationalHours?.map((operationalHours) =>
      time(operationalHours.timeTo).diff(
        time(operationalHours.timeFrom),
        "hours"
      )
    ) ?? [];

  return Math.max(...durations);
}

function timeStringToDayJs(time: string): Dayjs {
  const [hours, minutes] = time.split(":");
  return dayjs().set("hour", parseInt(hours)).set("minute", parseInt(minutes));
}
