import { createEvent, createStore, sample } from "effector";
import { reset } from "patronum";

import { createOpenAbleState } from "shared/lib/effector-openable-state";
import { Reservation } from "shared/api/types";

import { cancelOverrideFx } from "../api";

export const [$isCancelOverrideModalOpened, cancelOverrideModalActions] =
  createOpenAbleState();

export const cancelOverride = createEvent<Reservation>();
export const overrideCanceled = createEvent();
export const overrideCancelConfirmed = createEvent();

export const $override = createStore<Reservation | null>(null);

$override.on(cancelOverride, (_, override) => override);

sample({
  clock: cancelOverride,
  target: cancelOverrideModalActions.open,
});

sample({
  clock: overrideCancelConfirmed,
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  source: $override.map((override) => override?.id!),
  target: cancelOverrideFx,
});

sample({
  clock: cancelOverrideFx.done,
  target: [cancelOverrideModalActions.close, overrideCanceled],
});

reset({
  clock: cancelOverrideModalActions.close,
  target: [$override],
});
