import { forwardRef, Ref } from "react";
import styled from "styled-components";

import { Tooltip } from "@mui/material";
import { DangerIcon } from "shared/ui/icons";

export function SuspiciousTooltipComponent(props: {
  className?: string;
}): JSX.Element {
  return (
    <TooltipContent
      as={Tooltip}
      title="Attention! This user is suspicious"
      placement="top"
      arrow
      enterTouchDelay={50}
    >
      <Icon className={props.className} />
    </TooltipContent>
  );
}

const TooltipContent = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
`;

const Icon = styled(
  forwardRef((props, ref: Ref<HTMLElement>) => (
    <span ref={ref}>
      <DangerIcon {...props} />
    </span>
  ))
)`
  color: #de2016;
  margin-top: 6px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
export const SuspiciousTooltip = styled(SuspiciousTooltipComponent)``;
