import { Store, Event } from "effector";
import { attach, createStore, sample } from "effector";
import { debounce } from "patronum";

import { getOverridePriceFx } from "../api";

interface DataForCalculation {
  dock: string;
  loa: number;
  beam: number;
  timeFrom: string;
  timeTo: string;
  totalPrice: string;
  isCharter: boolean;
}

interface CalculatePriceModelParams {
  $dataForCalculation: Store<DataForCalculation>;
  filter: Store<boolean>;
  priceChanged: Event<string>;
}

interface CalculatePriceModel {
  $dockPrice: Store<string>;
  $bookingFee: Store<string>;
  $charterFee: Store<string>;
}

export function createCalculatePriceModel(
  params: CalculatePriceModelParams
): CalculatePriceModel {
  const $dockPrice = createStore<string>("");
  const $bookingFee = createStore<string>("");
  const $charterFee = createStore<string>("");

  const calculatePriceFx = attach({
    effect: getOverridePriceFx,
    source: params.$dataForCalculation,
  });
  $bookingFee.on(
    calculatePriceFx.doneData,
    (_, prices) => prices.bookingFee as string
  );
  $charterFee.on(
    calculatePriceFx.doneData,
    (_, prices) => prices.charterFee as string
  );

  sample({
    clock: calculatePriceFx.doneData.map((data) => data.dockPrice as string),
    target: params.priceChanged,
  });

  sample({
    clock: debounce({ source: params.$dataForCalculation, timeout: 500 }),
    filter: params.filter,
    target: calculatePriceFx,
  });

  return {
    $dockPrice,
    $bookingFee,
    $charterFee,
  };
}
