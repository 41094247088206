import { createStore, Effect, Store } from "effector";

export function createApiErrorStatusStore(
  effect: Effect<any, any, any>
): Store<null | number> {
  const $errorsStatus = createStore<null | number>(null);

  $errorsStatus.on(
    effect.fail.filter({
      fn: (data) => data.error.isAxiosError,
    }),
    (_, data) => data.error.response.status
  );

  $errorsStatus.reset(effect);

  return $errorsStatus;
}
