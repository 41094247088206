import { useGate, useUnit, createGate } from "effector-react";
import { combine, createEvent, createStore, Effect } from "effector";

import { pagination, spreadListDataData } from "shared/lib/effector-paginatoin";
import { Order, Reservation, Sorting } from "shared/api/types";
import {
  managerReservationEdited,
  newReservationCreated,
  overrideCanceled,
} from "@manager-app/features/manage-overide";
import { reservationIsCanceled } from "features/cancel-reservation";
import { clientReservationUpdated } from "features/edit-client-reservation/model";
import { convertToApi } from "shared/lib/dayjs-ext/dayjs-ext";
import { Range } from "shared/ui/ecosystems/forms/atoms/date-range";
import { dayjs } from "shared/lib/dayjs-ext/dayjs-ext";

import { ReservationsTable } from "./ui/reservations-table";

export function createReservationsTable(params: {
  getReservationsFx: Effect<any, any>;
  filtering?: boolean;
  showCompanyName?: boolean;
  showClientDetails?: boolean;
  hidePagination?: boolean;
}): () => JSX.Element {
  const reservationTableGate = createGate();

  const $reservationsList = createStore<Reservation[]>([]);
  const $reservationsTotalCount = createStore(0);

  const $currentPage = createStore(1);
  const $itemsPerPage = createStore(10);
  const $searchQuery = createStore<string | null>(null);
  const $dateOfReservation = createStore<Range>({
    startDate: null,
    endDate: null,
  });
  const $author = createStore<{ name: string; id: string } | null>(null);
  const $dock = createStore<string | null>(null);
  const $paidAt = createStore<Range>({
    startDate: null,
    endDate: null,
  });
  const $sorting = createStore<Sorting>({
    orderBy: null,
    order: null,
  });
  const $authorURI = $author.map((author) => author?.id ?? null);

  const pageChanged = createEvent<number>();
  const itemsPerPageChanged = createEvent<number>();
  const searchQueryChanged = createEvent<string>();
  const sortChanged = createEvent<string>();
  const orderChanged = createEvent<Order>();
  const dateOfReservationChanged = createEvent<Range>();
  const authorChanged = createEvent<{ name: string; id: string }>();
  const dockChanged = createEvent<string>();
  const paidAtChanged = createEvent<Range>();

  const $filters = combine(
    $itemsPerPage,
    $searchQuery,
    $dateOfReservation,
    $authorURI,
    $dock,
    $paidAt,
    $sorting,
    (
      itemsPerPage,
      searchQuery,
      dateOfReservation,
      author,
      dock,
      paidAt,
      sorting
    ) => ({
      itemsPerPage,
      keyword: searchQuery,
      "timeFrom[strictly_before]": dateOfReservation.endDate
        ? convertToApi(dayjs(dateOfReservation.endDate).add(1, "day"))
        : null,
      "timeTo[after]": dateOfReservation.startDate
        ? convertToApi(dateOfReservation.startDate)
        : null,
      author,
      dock,
      "paidAt[strictly_before]": paidAt.endDate
        ? convertToApi(dayjs(paidAt.endDate).add(1, "day"))
        : null,
      "paidAt[after]": paidAt.startDate ? convertToApi(paidAt.startDate) : null,
      [`order[${sorting.orderBy}]`]: sorting.order,
    })
  );

  const $activeFilters = combine(
    $dock,
    $authorURI,
    $dateOfReservation,
    $paidAt,
    (dock, author, dateOfReservation, paidAt) => ({
      "dock.name": !!dock,
      author: !!author,
      timeFrom: !!(dateOfReservation.startDate && dateOfReservation.endDate),
      paidAt: !!(paidAt.startDate && paidAt.endDate),
    })
  );

  $currentPage.on(pageChanged, (_, page) => page);
  $searchQuery
    .on(searchQueryChanged, (_, value) => value)
    .reset(reservationTableGate.close);
  $itemsPerPage.on(itemsPerPageChanged, (_, value) => value);
  $dateOfReservation.on(dateOfReservationChanged, (_, value) => value);
  $author.on(authorChanged, (_, value) => value);
  $dock.on(dockChanged, (_, value) => value);
  $paidAt.on(paidAtChanged, (_, value) => value);

  function invertSortDirection(direction: Order): Order {
    if (direction === "asc") return "desc";
    else return "asc";
  }

  $sorting
    .on(sortChanged, (oldValue, value) => {
      if (oldValue.orderBy === value) {
        return { orderBy: value, order: invertSortDirection(oldValue.order) };
      } else return { orderBy: value, order: "asc" };
    })
    .on(orderChanged, (oldValue, value) => {
      return {
        orderBy: oldValue.orderBy,
        order: value,
      };
    });

  spreadListDataData({
    $items: $reservationsList,
    $totalCount: $reservationsTotalCount,
    effect: params.getReservationsFx,
  });

  pagination({
    $page: $currentPage,
    $filters: $filters,
    effect: params.getReservationsFx,
    gate: reservationTableGate,
    reloadTrigger: [
      reservationIsCanceled,
      newReservationCreated,
      clientReservationUpdated,
      managerReservationEdited,
      overrideCanceled,
    ],
  });

  return function Reservations() {
    useGate(reservationTableGate);

    const [
      reservations,
      searchQuery,
      total,
      currentPage,
      itemsPerPage,
      sorting,
      dock,
      author,
      dateOfReservation,
      paidAt,
      activeFilters,
    ] = useUnit([
      $reservationsList,
      $searchQuery,
      $reservationsTotalCount,
      $currentPage,
      $itemsPerPage,
      $sorting,
      $dock,
      $author,
      $dateOfReservation,
      $paidAt,
      $activeFilters,
    ]);

    return (
      <>
        <ReservationsTable
          reservations={reservations}
          pagination={{
            total: total,
            onPageChange: pageChanged,
            onItemsPerPageChange: itemsPerPageChanged,
            currentPage: currentPage,
            itemsPerPage: itemsPerPage,
            rowsPerPageOptions: [5, 10, 20],
          }}
          showClientDetail={params.showClientDetails}
          showCompanyName={params.showCompanyName}
          hidePagination={params.hidePagination}
          onSortChange={sortChanged}
          onSortOrderChange={orderChanged}
          sorting={sorting}
          filtering={params.filtering}
          filters={{
            onDockChange: dockChanged,
            dock: dock,
            onAuthorChange: authorChanged,
            author: author,
            dateOfReservation: dateOfReservation,
            onDateOfReservationChanged: dateOfReservationChanged,
            paidAt: paidAt,
            onPaidAtChanged: paidAtChanged,
            searchQuery: searchQuery,
            onSearchQueryChanged: searchQueryChanged,
          }}
          activeFilters={activeFilters}
        />
      </>
    );
  };
}
