import React from "react";
import styled from "styled-components";

type Props = {
  header: string;
  subHeader: string;
  renderContent(): React.ReactNode;
  renderAction(): React.ReactNode;
  withChildren: undefined;
};
export function FormTemplate(props: Props): JSX.Element {
  return (
    <FormTemplateContainer>
      <Header> {props.header}</Header>
      <SubHeader>{props.subHeader}</SubHeader>
      <Content>{props.renderContent()}</Content>
      <Action>{props.renderAction()}</Action>
    </FormTemplateContainer>
  );
}

export const Header = styled.div`
  font-family: "Kanit", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 130%;
  color: #0a1128;
`;

export const SubHeader = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #5e616c;
`;

export const Content = styled.div`
  flex: 1;
`;

export const Action = styled.div``;

export const FormTemplateContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  ${Header} {
    margin-bottom: 12px;
  }
  ${SubHeader} {
    margin-bottom: 32px;
  }
  ${Action} {
    margin-top: 24px;
  }
`;
