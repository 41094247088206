import { createGate } from "effector-react";
import { attach, createEvent, createStore } from "effector";
import { getCityCollection, getListFromApi } from "shared/api";
import { pagination, spreadListDataData } from "shared/lib/effector-paginatoin";
import { City } from "shared/api/types";
import { cityDeleted } from "@admin-app/features/manage-cities/delete";

interface Filters {
  itemsPerPage: number;
}

export const pageGate = createGate();

export const $cities = createStore<City[]>([]);
export const $totalCities = createStore(0);

export const $currentPage = createStore(1);
export const $itemsPerPage = createStore(10);
export const $searchQuery = createStore<string | null>(null);

export const pageChanged = createEvent<number>();
export const itemsPerPageChanged = createEvent<number>();
export const searchQueryChanged = createEvent<string>();

const $filters = $itemsPerPage.map((state) => ({ itemsPerPage: state }));

$currentPage.on(pageChanged, (_, page) => page);
$searchQuery.on(searchQueryChanged, (_, value) => value).reset(pageGate.close);
$itemsPerPage.on(itemsPerPageChanged, (_, value) => value);

const getCitiesFx = attach({
  // @ts-ignore
  effect: getListFromApi(getCityCollection, { ignoreValidation: true }),
  mapParams: (query: Filters & { page: number }) => ({
    query,
  }),
});

spreadListDataData({
  $items: $cities,
  $totalCount: $totalCities,
  effect: getCitiesFx,
});

pagination({
  $page: $currentPage,
  $filters: $filters,
  effect: getCitiesFx,
  gate: pageGate,
  reloadTrigger: cityDeleted,
});
