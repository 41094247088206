import styled from "styled-components";
import {
  FormAuthTemplate,
  FormFields,
} from "shared/ui/templates/auth-form-template";
import { SubmitButton, InputField } from "shared/ui/ecosystems/forms";

import { Form } from "shared/lib/form";

import { useStore } from "effector-react";

import { loginApiErrorsByStatuses } from "shared/api";

import { signInForm, $formSubmitting, $serverErrorStatus } from "../../model";

export function SignInForm(): JSX.Element {
  const isSubmitting = useStore($formSubmitting);
  const errorStatus = useStore($serverErrorStatus);

  return (
    <Form form={signInForm}>
      <FormAuthTemplate
        error={
          errorStatus &&
          (loginApiErrorsByStatuses[errorStatus] ?? "Unknown error")
        }
        header="Admin panel"
        subHeader="Please enter your email and password."
        renderContent={() => (
          <FormFields>
            <InputField
              name="email"
              type="email"
              label="Email"
              placeholder="Enter email"
            />
            <InputField
              name="password"
              type="password"
              label="Enter password"
              placeholder="Enter password"
            />
          </FormFields>
        )}
        renderAction={() => (
          <Actions>
            <SubmitButton isSubmitting={isSubmitting}>Sign In</SubmitButton>
          </Actions>
        )}
      />
    </Form>
  );
}
const Actions = styled.div`
  display: flex;
  flex-direction: column;
`;
