import { useGate, useUnit } from "effector-react";

import {
  FloatInputField,
  InputField,
  SelectField,
  SubmitButton,
} from "shared/ui/ecosystems/forms";
import { Form } from "shared/lib/form";

import styled from "styled-components";
import { mediaPhone } from "shared/ui/styles/media";

import {
  $formSubmitting,
  editCompanyForm,
  editFormGate,
  successModal,
  successModalActions,
  unSuccessModal,
  unSuccessModalActions,
  $isTypePercent,
} from "../model";
import { SuccessModal, UnSuccessModal } from "../../ui/modal";

export function EditCompanyForm(): JSX.Element {
  useGate(editFormGate);
  const [
    isFormSubmitting,
    isSuccessModalOpened,
    isUnSuccessModalOpened,
    isTypePercent,
  ] = useUnit([$formSubmitting, successModal, unSuccessModal, $isTypePercent]);
  return (
    <>
      <Form form={editCompanyForm}>
        <FormRoot>
          <FormLine>
            <InputField
              name="name"
              type="string"
              label="Enter company name *"
              maxLength={50}
            />
          </FormLine>
          <FormLine>
            <FloatInputField name="bookingFee" label="Booking fee *" />
            <SelectField
              name="bookingFeeType"
              label="Price type"
              options={[
                { label: "Dollar", value: "constant" },
                { label: "Percent", value: "percent" },
              ]}
            />
          </FormLine>
          <FormLine>
            {isTypePercent && (
              <FloatInputField
                name="minimumBookingFee"
                label="Minimal booking fee"
              />
            )}
            <InputField name="stripeAccountId" label="Stripe Account ID *" />
          </FormLine>
        </FormRoot>
        <Actions>
          <SubmitButton isSubmitting={isFormSubmitting}>
            Update company
          </SubmitButton>
        </Actions>
      </Form>
      <SuccessModal
        isOpened={isSuccessModalOpened}
        onClose={successModalActions.close}
        message="Company updated successfully!"
      />
      <UnSuccessModal
        isOpened={isUnSuccessModalOpened}
        onClose={unSuccessModalActions.close}
      />
    </>
  );
}

const Actions = styled.div`
  display: flex;
  justify-content: center;
  ${SubmitButton} {
    margin-top: 32px;
    width: 322px;
  }
`;

const FormLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  ${mediaPhone} {
    grid-template-columns: 1fr;
  }
`;

const FormRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
