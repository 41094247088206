import {
  combine,
  createStore,
  Effect,
  Event,
  sample,
  Store,
  Unit,
} from "effector";
import { Gate } from "effector-react";
import { and, debounce } from "patronum";

export function pagination<T>(params: {
  $page: Store<number>;
  $filters: Store<T>;
  effect: Effect<T & { page: number }, unknown>;
  reloadTrigger?: Unit<unknown> | Unit<unknown>[];
  gate: Gate<unknown>;
  $active?: Store<boolean>;
}): void {
  const $effectPayload = combine(
    params.$page,
    params.$filters,
    (page, filters) => ({
      page,
      ...filters,
    })
  );

  const $active = params.$active ?? createStore(true);
  sample({
    clock: params.gate.open,
    source: $effectPayload,
    filter: $active,
    target: params.effect,
  });

  sample({
    clock: params.$page,
    source: $effectPayload,
    filter: $active,
    target: params.effect,
  });

  sample({
    clock: params.$filters,
    source: createStore(1),
    target: params.$page,
  });

  sample({
    clock: debounce({
      source: params.$filters,
      timeout: 500,
    }) as Event<T>,
    filter: and(params.gate.status, $active),
    source: $effectPayload,
    target: params.effect,
  });

  if (params.reloadTrigger) {
    // @ts-ignore
    sample({
      clock: params.reloadTrigger,
      source: $effectPayload,
      filter: $active,
      target: params.effect,
    });
  }
}

export function spreadListDataData<T, D extends T, S>(params: {
  $items?: Store<T[]>;
  $totalCount?: Store<number>;
  effect: Effect<S, { items: D[]; totalCount: number }>;
}): void {
  if (params.$items) {
    params.$items.on(params.effect.doneData, (_, { items }) => items);
  }

  if (params.$totalCount) {
    params.$totalCount.on(
      params.effect.doneData,
      (_, { totalCount }) => totalCount
    );
  }
}
