import { useStore } from "effector-react";
import styled from "styled-components";
import { Box } from "@mui/material";

import {
  FormError,
  FormModalTemplate,
  InputField,
  SubmitButton,
} from "shared/ui/ecosystems/forms";
import { Form } from "shared/lib/form";
import { Button } from "shared/ui/button";

import { $formSubmitting, $serverErrorStatus, newAddonForm } from "../model";

export function NewAddonModal(props: {
  isOpened: boolean;
  onClose(): void;
}): JSX.Element {
  const isSubmitting = useStore($formSubmitting);
  const errorStatus = useStore($serverErrorStatus);

  return (
    <FormModal
      form={newAddonForm}
      isOpened={props.isOpened}
      onClose={props.onClose}
      renderTitle={() => <Title>Create add-on</Title>}
      renderContent={() => (
        <>
          {errorStatus && <FormError>Unknown error</FormError>}
          <Form form={newAddonForm}>
            <FormFields>
              <InputField
                name="name"
                type="string"
                label="Enter add-on name"
                maxLength={35}
              />
            </FormFields>
          </Form>
        </>
      )}
      renderActions={() => (
        <Actions>
          <Button variant={"outlined"} onClick={props.onClose}>
            Cancel
          </Button>
          <SubmitButton isSubmitting={isSubmitting}>Create</SubmitButton>
        </Actions>
      )}
    />
  );
}

const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
`;

const Actions = styled.div`
  display: flex;
  gap: 8px;
  ${Button} {
    width: 135px;
  }
`;

const FormFields = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
`;

const FormModal = styled(FormModalTemplate)`
  .MuiDialog-paper {
    width: 312px;
    padding: 19px 16px 16px;
    border-radius: 24px;
  }
`;
