import { createEffect } from "effector";

import { apiRequestFx } from "shared/api";
import { Boat, Client, Dock } from "shared/api/types";

type ReservationWithClientPriceParams = {
  dock: string;
  loa: number;
  beam?: number;
  timeFrom: string;
  timeTo: string;
  isCharter?: true;
  isSuperOverride?: true;
  dockAddons?: string[];
  beforeTax?: string;
};

type AddonPrice = {
  addonName: string;
  addonDescription: string;
  addonPrice: string;
  addonPriceType: string;
  addonTax: string;
  addonTaxType: string;
  price: string;
  tax: string;
};

export type ReservationPrice = {
  bookingFee: string;
  charterFee: string | null;
  companyBookingFee: string;
  companyBookingFeeType: string;
  dockPrice: string;
  dockPricePerHour: string;
  hours: number;
  tax: string;
  taxRate: string;
  totalPrice: string;
  addons: AddonPrice[];
};

export const getReservationWithClientPriceFx = createEffect(
  async (params: ReservationWithClientPriceParams) => {
    const response: ReservationPrice = await apiRequestFx({
      method: "POST",
      path: "/api/reservations/price",
      body: {
        ...params,
        beam: params.beam ?? 1,
      },
    });
    return response;
  }
);

export const getOverridePriceFx = createEffect(
  async (params: ReservationWithClientPriceParams) => {
    const response: ReservationPrice = await apiRequestFx({
      method: "POST",
      path: "/api/reservations/price",
      body: {
        ...params,
        beam: params.beam ?? 1,
      },
    });
    return response;
  }
);

export const postPayReservationAtDockFx = createEffect(
  async (path: { id: string }) => {
    return await apiRequestFx({
      method: "POST",
      path: `/api/manager/reservations/${path.id}/pay-at-dock`,
    });
  }
);

export type Billing = {
  reservation: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
};

export type Reservation = {
  id: string;
  client: Client;
  dock: Dock;
  boat: Boat;
  price: ReservationPrice;
  billing: Billing;
  loa: number;
  beam?: number;
  isCharter: boolean;
};

export const getReservationItem = createEffect(async (path: { id: string }) => {
  const response: Reservation = await apiRequestFx({
    method: "GET",
    path: `/api/reservations/${path.id}`,
  });
  return response;
});
