import React, { useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MaterialSelectComponent,
  FormHelperText,
  IconButton,
} from "@mui/material";
import styled from "styled-components";

import { CloseIcon as ClearIcon } from "shared/ui/icons";

import { getErrorProps } from "./input";

type Item<T> = {
  value: T;
  label: string | Record<string, string>;
  key?: unknown;
};

export interface SelectProps<T> {
  label: string;
  className?: string;
  name?: string;
  options: Item<T>[];
  renderValue?(label: string | Record<string, string>): React.ReactNode;
  value: T | null;
  onChange(value: T | null): void;
  error?: string | null;
  placeholder?: string;
  disabled?: boolean;
  clearable?: boolean;
  setFirstValueAsDefault?: boolean;
}

function SelectComponent<T>(props: SelectProps<T>) {
  const { error, ...selectProps } = props;
  const showClearButton = selectProps.clearable && selectProps.value;
  useEffect(() => {
    if (selectProps.setFirstValueAsDefault && !selectProps.value) {
      selectProps.onChange(selectProps?.options[0]?.value);
    }
  }, [selectProps.options]);
  return (
    <FormControl {...getErrorProps(error)} className={props.className}>
      <InputLabel shrink={!!selectProps.value}>{props.label}</InputLabel>
      <MaterialSelect
        {...selectProps}
        onChange={(event) => props.onChange(event.target.value as T)}
        placeholder={props.placeholder}
        endAdornment={
          showClearButton && (
            <IconButton size="medium" onClick={() => props.onChange(null)}>
              <ClearIcon />
            </IconButton>
          )
        }
        showClearButton={showClearButton}
      >
        {props.options.map((option) => (
          <MenuItem key={option.key ?? option.value} value={option.value}>
            {props.renderValue ? props.renderValue(option.value) : option.label}
          </MenuItem>
        ))}
      </MaterialSelect>
      <FormHelperText id="select-error-text">
        {getErrorProps(error).helperText}
      </FormHelperText>
    </FormControl>
  );
}

const MaterialSelect = styled(MaterialSelectComponent)<{
  showClearButton: boolean;
}>`
  & > .MuiSelect-icon {
    margin-right: ${(props) => (props.showClearButton ? "48px" : "0")};
  }
  & > .MuiInput-input {
    padding-right: ${(props) =>
      props.showClearButton ? "32px !important" : "24px"};
  }
`;

export const Select = styled(SelectComponent)``;
