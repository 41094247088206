import { createGate } from "effector-react";
import { attach, createEvent, createStore } from "effector";
import { getListFromApi } from "shared/api";
import { getAddonCollection } from "@admin-app/shared/api/addons";

import { pagination, spreadListDataData } from "shared/lib/effector-paginatoin";

import { newAddonCreated } from "@admin-app/features/manage-addons/add";
import { addonEdited } from "@admin-app/features/manage-addons/edit";
import { addonDeleted } from "@admin-app/features/manage-addons/delete";

interface Addon {
  id: number;
  name: string;
}

interface Filters {
  itemsPerPage: number;
}

export const pageGate = createGate();

export const $addonsList = createStore<Addon[]>([]);
export const $addonsTotalCount = createStore(0);

export const $currentPage = createStore(1);
export const $itemsPerPage = createStore(10);
export const $searchQuery = createStore<string | null>(null);

export const pageChanged = createEvent<number>();
export const itemsPerPageChanged = createEvent<number>();
export const searchQueryChanged = createEvent<string>();

const $filters = $itemsPerPage.map((state) => ({ itemsPerPage: state }));

$currentPage.on(pageChanged, (_, page) => page);
$searchQuery.on(searchQueryChanged, (_, value) => value).reset(pageGate.close);
$itemsPerPage.on(itemsPerPageChanged, (_, value) => value);

const getAddonsListFx = attach({
  // @ts-ignore
  effect: getListFromApi(getAddonCollection, { ignoreValidation: true }),
  mapParams: (query: Filters & { page: number }) => ({
    query,
  }),
});

spreadListDataData({
  $items: $addonsList,
  $totalCount: $addonsTotalCount,
  effect: getAddonsListFx,
});

pagination({
  $page: $currentPage,
  $filters: $filters,
  effect: getAddonsListFx,
  gate: pageGate,
  reloadTrigger: [newAddonCreated, addonEdited, addonDeleted],
});
