import { createEvent, createStore, createEffect } from "effector";

import { getAdminMe, fromApi } from "shared/api";

export interface Admin {
  email: string;
  firstName: string;
  lastName: string;
  createdAt?: string;
  updatedAt?: string;
}

export const logout = createEvent();

export const getAdminDataFx = createEffect(fromApi(getAdminMe));

export const $viewer = createStore<Admin | null>(null);

$viewer.on(getAdminDataFx.doneData, (_, data) => data as Admin).reset(logout);
