import React from "react";

import styled from "styled-components";

import { ArrowLeftIcon } from "shared/ui/icons";
import { Card } from "shared/ui/card";
import { mediaPhone } from "shared/ui/styles/media";

import { LinkButton } from "shared/ui/button";

export function CompanyFormTemplate(props: {
  title: string;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <Root>
      <BackButton as={LinkButton} to="/companies-list/">
        <BackIcon as={ArrowLeftIcon} />
      </BackButton>

      <Content as={Card}>
        <Title>{props.title}</Title>
        {props.children}
      </Content>
    </Root>
  );
}

const BackIcon = styled.div`
  width: 24px;
  height: 24px;
`;

const BackButton = styled.div`
  width: 44px !important;
  & > * {
    padding: 0 !important;
    min-width: 0 !important;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  color: #0a1128;
  font-family: "Kanit", sans-serif;
`;
const Content = styled.div`
  width: 716px;

  ${mediaPhone} {
    width: calc(100vw - 32px);
  }
`;

const Root = styled.div`
  ${BackButton} {
    margin-bottom: 24px;
  }
  ${Title} {
    margin-bottom: 30px;
  }
`;
