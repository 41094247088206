import { useField } from "shared/lib/form";

import { Select, SelectProps } from "../atoms/select";
import { StateSelect, StateSelectProps } from "../atoms/state-select";

type FieldProps<T> = Omit<T, "value" | "onChange"> & { name: string };

type Props<T> = FieldProps<SelectProps<T>>;

export function SelectField<T>(props: Props<T>): JSX.Element {
  const field = useField({ name: props.name });
  return (
    <Select
      {...field.input}
      {...props}
      error={field.isShowError ? field.error : ""}
    />
  );
}

export function StateField(props: FieldProps<StateSelectProps>): JSX.Element {
  const field = useField({ name: props.name });
  return (
    <StateSelect
      {...field.input}
      {...props}
      error={field.isShowError ? field.error : ""}
    />
  );
}
