import { createEffect, createEvent, createStore, sample } from "effector";
import { useStoreMap } from "effector-react";
import { http } from "shared/api";

interface Image {
  "@id": "string";
  url: "string";
}

export const $imagesMap = createStore<Record<string, Image>>({});
export const $selectedImageError = createStore<string>("");
export const largeImageSelected = createEvent();
export const imageSelected = createEvent<Blob>();
export const imageUploaded = createEvent<string>();
export const addImage = createEvent<Image>();

$selectedImageError
  .on(largeImageSelected, () => "Please, select another picture.")
  .reset(imageSelected);

export const uploadDockImageFx = createEffect(
  async (file: Blob): Promise<Image> => {
    const formData = new FormData();
    formData.append("file", file);
    const { data } = await http.post("/api/dock-images", formData);
    return data;
  }
);

$imagesMap.on([uploadDockImageFx.doneData, addImage], (map, image) => ({
  ...map,
  [image["@id"]]: image,
}));

sample({
  clock: imageSelected,
  target: uploadDockImageFx,
});

sample({
  clock: uploadDockImageFx.doneData.map((image) => image["@id"]),
  target: imageUploaded,
});

export function useImage(id: string): Image {
  return useStoreMap({ store: $imagesMap, fn: (map) => map[id], keys: [id] });
}

export const $isImageUploading = uploadDockImageFx.pending;
