import {
  createEvent,
  createEffect,
  createStore,
  sample,
  combine,
  attach,
} from "effector";
import { createForm } from "effector-react-form";

import { Reservation } from "shared/api/types";
import { createOpenAbleState } from "shared/lib/effector-openable-state";
import {
  createCalculatePriceModel,
  createReservationsDateTimeModel,
  getReservationDuration,
} from "entities/reservations";

import { formField, formFieldChanged } from "shared/lib/form";
import {
  apiRequestFx,
  createApiErrorStatusStore,
  fromApi,
  getReservationSchedule,
} from "shared/api";

import { createClientsBoatsList } from "entities/cients";
import { $docksList } from "entities/docks/model";
import { and } from "patronum";

export const editForm = createForm<{
  boat: string;
  dock: string;
  duration: number | null;
  date: string;
  time: {
    from: string;
    to: string;
  } | null;
  isCharter: boolean;
}>({
  onSubmit: () => formSubmitted(),
});

export const [editModal, editModalActions] = createOpenAbleState();
export const $reservation = createStore<Reservation | null>(null);

const $clientId = $reservation.map(
  (reservation) => reservation?.client.id ?? null
);

export const $clientBoats = createClientsBoatsList($clientId);

const $selectedBoatIRI = formField(editForm, "boat");
const $dockIRI = formField(editForm, "dock");
const $duration = formField(editForm, "duration");
const $selectedDate = formField(editForm, "date");
const $selectedTime = formField(editForm, "time");
const $isCharter = formField(editForm, "isCharter");
export const $price = createStore("");

const $selectedBoat = combine(
  $clientBoats,
  $selectedBoatIRI,
  (boats, id) => boats.find((boat) => boat["@id"] === id) ?? null
);

export const $selectedDock = combine(
  $docksList,
  $dockIRI,
  (docks, id) => docks.find((dock) => dock["@id"] === id) ?? null
);

const formSubmitted = createEvent();
export const clientReservationEditingStart = createEvent<Reservation>();

const getAvailableItemsFx = attach({
  effect: createEffect(fromApi(getReservationSchedule)),
  source: $reservation.map((reservation) => reservation?.id),
  mapParams: ({ query }, reservationId) => ({
    query: { ...query, reservationId },
  }),
});

export const editClientReservationFx = createEffect(
  async ({ reservationId, ...reservationData }) => {
    await apiRequestFx({
      method: "PUT",
      path: `/api/manager/reservations/${reservationId}`,
      body: reservationData,
    });
  }
);

export const durationModel = createReservationsDateTimeModel({
  $boat: $selectedBoat.map((boat) =>
    boat ? { boatId: boat.id as number } : null
  ),
  $dock: $selectedDock,
  $duration,
  $selectedDate,
  $selectedTime,
  timeChanged: formFieldChanged(editForm, "time"),
  getAvailableItemsFx,
  getAvailableItemsTrigger: editClientReservationFx.fail,
  reset: editModalActions.close,
});

export const $errorStatus = createApiErrorStatusStore(editClientReservationFx);
export const $isReservationUpdating = editClientReservationFx.pending;
export const clientReservationUpdated = editClientReservationFx.done;

export const { $bookingFee, $charterFee } = createCalculatePriceModel({
  $dataForCalculation: combine({
    dock: $dockIRI,
    loa: $selectedBoat.map((boat) => boat?.loa ?? null),
    beam: $selectedBoat.map((boat) => boat?.beam ?? null),
    timeFrom: durationModel.$timeFrom,
    timeTo: durationModel.$timeTo,
    isCharter: $isCharter,
  }),
  filter: and(
    $selectedBoat,
    durationModel.$timeFrom,
    durationModel.$timeTo,
    $dockIRI
  ),
  priceChanged: $price,
});

$reservation.on(clientReservationEditingStart, (_, reservation) => reservation);

sample({
  clock: clientReservationEditingStart,
  fn: (reservation) => ({
    dock: reservation.dock["@id"],
    boat: reservation.boat["@id"],
    duration: getReservationDuration(reservation),
    date: new Date(reservation.timeFrom),
    time: {
      from: reservation.timeFrom,
      to: reservation.timeTo,
    },
    isSuperOverride: reservation.isSuperOverride,
    isCharter: reservation.isCharter,
  }),
  target: [editModalActions.open, editForm.setValues],
});

sample({
  clock: clientReservationEditingStart.map(
    (reservation) => reservation.price.dockPrice
  ),
  target: [$price],
});

sample({
  clock: clientReservationEditingStart.map(
    (reservation) => reservation.price.bookingFee
  ),
  target: [$bookingFee],
});

sample({
  clock: clientReservationEditingStart.map(
    (reservation) => reservation.price.charterFee
  ),
  target: [$charterFee],
});

sample({
  clock: formSubmitted,
  source: {
    reservationId: $reservation.map((reservation) => reservation?.id ?? null),
    dock: $dockIRI,
    boat: $selectedBoatIRI,
    isCharter: $isCharter,
    timeFrom: durationModel.$timeFrom,
    timeTo: durationModel.$timeTo,
    isSuperOverride: false,
    dockAddons: [],
  },
  target: editClientReservationFx,
});

sample({
  clock: clientReservationUpdated,
  target: [editModalActions.close],
});
