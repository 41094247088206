import styled, { css, StyledComponent } from "styled-components";
import { useStore } from "effector-react";
import { Link } from "react-router-dom";

import {
  CancelReservationModal,
  cancelModalClosed,
  $isCancelModalOpened,
  reservationCanceled,
} from "features/cancel-reservation";
import { DockSelector } from "entities/docks";
import { ReservationAuthorSelector } from "entities/reservation-author";

import { DateRangeSelector } from "shared/ui/ecosystems/forms";
import { SearchInput } from "shared/ui";
import { Pagination, Table } from "shared/ui/table";
import { dayjs, removeTimeZone } from "shared/lib/dayjs-ext/dayjs-ext";
import { Client, Order, Reservation, Sorting } from "shared/api/types";
import { mediaPhone, useMedia } from "shared/ui/styles/media";
import { SmallTable, SmallTableMobile } from "shared/ui/small-table";
import { Button } from "shared/ui/button";
import { useOpenableState } from "shared/lib/use-openable-state";
import { CancelIcon, EditIcon } from "shared/ui/icons";
import {
  isReservationCanceled,
  isOverrideReservation,
} from "entities/reservations";
import { clientReservationEditingStart } from "features/edit-client-reservation";
import {
  ConfirmCancelOverrideModal,
  cancelOverride,
  overrideEditingStarted,
} from "@manager-app/features/manage-overide";
import { SuspiciousTooltip } from "@manager-app/features/manage-suspicious-users";

import { ModalFilter } from "./filter";
import { ReservationFilters } from "../types";

interface Props {
  reservations: Reservation[];
  pagination?: Pagination;
  cancelReservation?: (reservation: Reservation) => void;
  showClientDetail?: boolean;
  showCompanyName?: boolean;
  hidePagination?: boolean;
  filtering?: boolean;
  sorting: Sorting;
  onSortChange(id: string): void;
  onSortOrderChange(id: Order): void;
  filters: ReservationFilters;
  activeFilters: Record<string, boolean>;
  className?: string;
}
const isCharterColor = "#E4F7F7";

export function ReservationsTable(props: Props): JSX.Element {
  const modalFilter = useOpenableState();

  const reservations = props.reservations;
  const { isPhone } = useMedia();
  const isCancelModalOpened = useStore($isCancelModalOpened);

  return (
    <Root>
      <Filters>
        <Search
          searchQuery={props.filters.searchQuery}
          onSearchQueryChanged={props.filters.onSearchQueryChanged}
        />
        {isPhone && (
          <FilterButton onClick={modalFilter.open}>Filter & Sort</FilterButton>
        )}

        <ModalFilter
          isOpened={modalFilter.isOpened}
          onClose={modalFilter.close}
          fieldsList={[
            { value: "id", label: "Reservation №" },
            { value: "author", label: "Created by" },
            { value: "timeFrom", label: "Date and Time" },
            { value: "dock.name", label: "Dock Name" },
            { value: "price.totalPrice", label: "Total Price" },
            { value: "paidAt", label: "Reservation paid" },
          ]}
          filters={props.filters}
          onSortChange={props.onSortChange}
          onSortOrderChange={props.onSortOrderChange}
          sorting={props.sorting}
        />
      </Filters>

      <Table
        items={reservations}
        columns={[
          {
            id: "id",
            header: isPhone ? "Reservation №" : "№",
            renderCell: (reservation: Reservation) => <>{reservation.id}</>,
            sortable: true,
          },
          {
            id: "author",
            header: "Created by",
            sortable: true,
            renderFilter: (close) => (
              <ReservationAuthorSelector
                className={props.className}
                label="Created by"
                onChange={(value) => {
                  props.filters.onAuthorChange(value as any);
                  if (value) {
                    close();
                  }
                }}
                value={(props.filters.author as any) ?? {}}
              />
            ),
            renderCell: (reservation: Reservation) => {
              if (reservation.client) {
                const client = reservation.client as Client;

                return (
                  <Contact as={Link} to={`/customers-list/${client.id}`}>
                    <NameLine>
                      <ClientName isSuspicious={!!client.isSuspicious}>
                        {`${client.firstName} ${client.lastName}`}
                      </ClientName>
                      {client.isSuspicious! ? <SuspiciousTooltip /> : null}
                    </NameLine>
                  </Contact>
                );
              }

              if (reservation.manager) {
                return (
                  <>{`${reservation.manager.firstName} ${reservation.manager.lastName}`}</>
                );
              }
              return null;
            },
          },
          {
            id: "timeFrom",
            header: "Date and Time",
            sortable: true,
            renderFilter: (close) => (
              <DateRangeSelector
                onChange={(value) => {
                  props.filters.onDateOfReservationChanged(value);
                  close();
                }}
                value={props.filters.dateOfReservation}
              />
            ),
            renderCell: (reservation: Reservation) =>
              `${dateFormat(reservation.timeFrom)} ${timeFormat(
                reservation.timeFrom
              )} - ${timeFormat(reservation.timeTo)}`,
          },
          {
            id: "dockCompany",
            header: "Dock Company",
            hide: !props.showCompanyName,
            sortable: true,
            renderCell: (reservation: Reservation) =>
              reservation.dock.company?.name ?? "-",
          },
          {
            id: "dock.name",
            header: "Dock Name",
            sortable: true,
            renderFilter: (close) => (
              <DockSelector
                className={props.className}
                label="Dock Name"
                clearable
                onChange={(value) => {
                  props.filters.onDockChange(value);
                  close();
                }}
                value={props.filters.dock ?? ""}
              />
            ),
            renderCell: (reservation: Reservation) =>
              reservation.dock.name ?? "-",
          },
          {
            id: "price.totalPrice",
            header: "Total Price",
            sortable: true,
            renderCell: (reservation: Reservation) =>
              reservation.price ? `$${reservation.price.totalPrice}` : "",
          },
          {
            id: "paidAt",
            header: "Reservation paid",
            sortable: true,
            renderFilter: (close) => (
              <DateRangeSelector
                className={props.className}
                onChange={(value) => {
                  props.filters.onPaidAtChanged(value);
                  close();
                }}
                value={props.filters.paidAt}
              />
            ),
            renderCell: (reservation: Reservation) =>
              reservation.paidAt &&
              `${dateFormat(reservation.paidAt)} ${timeFormat(
                reservation.paidAt
              )}`,
          },
          {
            header: "Action",
            renderCell: (reservation: Reservation) => {
              if (isReservationCanceled(reservation)) {
                return "Cancelled";
              } else {
                return (
                  <Actions>
                    <EditButton
                      onClick={() => {
                        if (isOverrideReservation(reservation)) {
                          overrideEditingStarted(reservation);
                        } else {
                          clientReservationEditingStart(reservation);
                        }
                      }}
                    ></EditButton>

                    <CancelButton
                      onClick={() => {
                        if (isOverrideReservation(reservation)) {
                          cancelOverride(reservation);
                        } else {
                          reservationCanceled(reservation);
                        }
                      }}
                    >
                      Cancel
                    </CancelButton>
                  </Actions>
                );
              }
            },
          },
        ]}
        renderCollapsedContent={(reservation) => {
          return (
            <CollapsedContentRoot>
              {props.showClientDetail && (
                <ClientContacts>
                  <ClientContactsTable reservation={reservation} />
                </ClientContacts>
              )}
              <BoatTable
                boat={[
                  {
                    name: reservation.boat?.name,
                    manufacturer: reservation.boat?.manufacturer,
                    model: reservation.boat?.model,
                    loa: reservation?.loa,
                    beam: reservation?.beam,
                    comment: reservation?.comment,
                  },
                ]}
              />
            </CollapsedContentRoot>
          );
        }}
        pagination={
          !props.hidePagination && props.pagination
            ? props.pagination
            : undefined
        }
        getRowStyle={(reservation: Reservation) => {
          if (reservation.isCharter) {
            return { background: isCharterColor };
          }

          return { background: "" };
        }}
        sorting={props.sorting}
        onSortChange={props.onSortChange}
        filtering={props.filtering}
        activeFilters={props.activeFilters}
      />
      <CancelReservationModal
        isOpened={isCancelModalOpened}
        onClose={cancelModalClosed}
      />
      <ConfirmCancelOverrideModal />
    </Root>
  );
}

function dateFormat(date: string) {
  return dayjs(removeTimeZone(date)).format("MM/DD/YYYY");
}

function timeFormat(time: string) {
  return dayjs(removeTimeZone(time)).format("hh:mm A");
}

function BoatTable(props: {
  boat: {
    name: string;
    manufacturer: string;
    model: string;
    loa?: number | void;
    beam?: number | void;
    comment?: string | void | null;
  }[];
}): JSX.Element {
  const { isPhone } = useMedia();
  const TableComponent = isPhone ? SmallTableMobile : SmallTable;
  return (
    <TableComponent
      items={props.boat}
      name="Boat"
      columns={[
        {
          header: "Boat Name",
          renderCell: (boat) => boat.name ?? "—",
        },
        {
          header: "Manufacturer",
          renderCell: (boat) => boat.manufacturer ?? "—",
        },
        {
          header: "Model",
          renderCell: (boat) => boat.model ?? "—",
        },
        {
          header: "LOA",
          renderCell: (boat) => `${boat.loa} ft`,
        },
        {
          header: "Beam",
          renderCell: (boat) => `${boat.beam} ft`,
        },
      ]}
    />
  );
}

function ClientContactsTable(props: { reservation: Reservation }): JSX.Element {
  const { isPhone } = useMedia();
  const TableComponent = isPhone ? SmallTableMobile : SmallTable;

  return (
    <TableComponent
      items={[props.reservation]}
      name="Client contacts"
      columns={[
        {
          header: "Email",
          renderCell: (reservation) =>
            reservation.client?.email ? (
              <Contact href={`mailto:${reservation.client.email}`}>
                {reservation.client.email}
              </Contact>
            ) : (
              "—"
            ),
        },
        {
          header: "Phone",
          renderCell: (reservation) =>
            reservation.client?.phone ? (
              <Contact href={`tel:${reservation.client.phone}`}>
                {reservation.client.phone}
              </Contact>
            ) : (
              "—"
            ),
        },
        {
          header: "Notes",
          renderCell: (reservation) => reservation.comment ?? null,
        },
      ]}
    />
  );
}

function SearchComponent(props: {
  className?: string;
  searchQuery: string | null;
  onSearchQueryChanged(value: string): void;
}): JSX.Element {
  const searchQuery = props.searchQuery;
  return (
    <SearchInput
      className={props.className}
      label="Search"
      value={searchQuery ? searchQuery : ""}
      onChange={props.onSearchQueryChanged}
    />
  );
}

const Search = styled(SearchComponent)`
  width: 290px;
`;

const FilterButton = styled(Button)`
  width: 128px;
  white-space: nowrap;
`;

const Filters = styled.div`
  display: flex;
  gap: 24px;
  justify-content: space-between;
  ${mediaPhone} {
    gap: 16px;
  }
`;

const ClientName = styled.div<{ isSuspicious: boolean }>`
  display: flex;
  ${(props) =>
    props.isSuspicious &&
    css`
      color: #de2016;
    `}
`;

const NameLine = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${SuspiciousTooltip} {
    width: 20px;
    height: 20px;
  }
`;

const Contact = styled.a`
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0a1128;
`;

const ClientContacts = styled.div`
  max-width: 600px;
`;

const EditButton = styled(EditIcon)`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

const CancelButton = styled(CancelIcon)`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

const CollapsedContentRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Root = styled.div`
  ${Table as StyledComponent<any, any>} {
    ${mediaPhone} {
      margin: 0 calc(var(--pageContentPadding) * -1);
    }
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 12px;
`;
