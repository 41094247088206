import { apiRequestFx } from "shared/api";

import { editClientReservationFx } from "features/edit-client-reservation";
import {
  cancelOverrideFx,
  EditeOverrideParams,
  editOverrideFx,
} from "@manager-app/features/manage-overide/api";

import { managerCanceledReservationFx } from "../../../features/cancel-reservation/api";

export function init(): void {
  editClientReservationFx.use(async ({ reservationId, ...reservationData }) => {
    await apiRequestFx({
      method: "PUT",
      path: `/api/admin/reservations/${reservationId}`,
      body: reservationData,
    });
  });

  managerCanceledReservationFx.use(
    async (params: { reservationId: number; refundAmount: string }) => {
      await apiRequestFx({
        method: "POST",
        path: `/api/admin/reservations/${params.reservationId}/cancel`,
        body: {
          refundAmount: params.refundAmount,
        },
      });
    }
  );

  editOverrideFx.use(async ({ overrideId, ...params }: EditeOverrideParams) => {
    await apiRequestFx({
      method: "PUT",
      path: `/api/admin/simple-reservations/${overrideId}`,
      body: params,
    });
  });

  cancelOverrideFx.use(async (overrideId: string) => {
    await apiRequestFx({
      method: "POST",
      path: `/api/admin/reservations/${overrideId}/cancel`,
      body: {
        refundAmount: "0",
      },
    });
  });
}
