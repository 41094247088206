import { createStore, createEvent, sample, Store } from "effector";
import { Gate } from "effector-react";
import qs, { ParsedQs } from "qs";
import { History } from "history";
import { matchRoutes, RouteConfig } from "react-router-config";

type RouterParams = { [key: string]: string };

export const $params = createStore<RouterParams>({});
export const $queryParams = createStore<ParsedQs>({});

export const paramsChanged = createEvent<RouterParams>();
export const queryParamsChanged = createEvent<ParsedQs>();

$params.on(paramsChanged, (_, params) => params);
$queryParams.on(queryParamsChanged, (_, queryParams) => queryParams);

const fnDefault = (param: any) => param;

export function createQueryParamStore<P = string>(opts: {
  name: string;
  fn?: (param: unknown) => P | null;
}): Store<P | null> {
  const fn = opts.fn ?? fnDefault;
  const $queryParam = createStore<P | null>(null, {
    name: `${opts.name} query param `,
  });

  const paramChanged = sample({
    clock: [$queryParams],
    source: $queryParams.map((params) => params[opts.name] ?? null),
  });

  $queryParam.on(paramChanged, (_, value) => fn(value));
  return $queryParam;
}

export function createRouteParamStore<P = string>(opts: {
  name: string;
  fn?: (param: unknown) => P | null;
  gate: Gate<unknown>;
}): Store<P | null> {
  const fn = opts.fn ?? fnDefault;
  const $routeParam = createStore<P | null>(null, {
    name: `${opts.name} route param `,
  });

  const paramChanged = sample({
    clock: [$params, opts.gate.status],
    source: $params.map((params) => params[opts.name] ?? null),
    filter: opts.gate.status,
  });

  $routeParam.on(paramChanged, (_, value) => fn(value));
  $routeParam.reset(opts.gate.close);
  return $routeParam;
}

export function syncHistoryWithState(
  history: History,
  routes: RouteConfig
): () => void {
  function setParams(pathname: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const matchedRoutes = matchRoutes(routes, pathname);

    const params = matchedRoutes.reduce((result, route) => {
      return Object.assign(result, route.match.params);
    }, {});

    paramsChanged(params);
  }

  function setQueryParamsParams(search: string) {
    const queryParams = qs.parse(search, { ignoreQueryPrefix: true });
    queryParamsChanged(queryParams);
  }

  let lastPathname: string, lastSearch: string;

  const {
    location: { pathname, search },
  } = history;

  lastPathname = pathname;
  lastSearch = search;

  setQueryParamsParams(search);
  setParams(pathname);

  return history.listen(({ pathname, search }) => {
    if (pathname !== lastPathname) {
      lastPathname = pathname;
      setParams(pathname);
    }
    if (search !== lastSearch) {
      lastSearch = search;
      setQueryParamsParams(search);
    }
  });
}
