import { Helmet } from "react-helmet-async";

interface MetaTagsProps {
  title?: string;
}

export function MetaTags(props: MetaTagsProps): JSX.Element {
  const title = props.title ? `${props.title} | DownTown Docks` : null;

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {title && <meta name="title" content={title} />}
    </Helmet>
  );
}
