import { useUnit } from "effector-react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { Form } from "shared/lib/form";
import {
  InputField,
  StateField,
  SubmitButton,
  TextAreaField,
} from "shared/ui/ecosystems/forms";
import { mediaPhone } from "shared/ui/styles/media";

import {
  $formSubmitting,
  newCityForm,
  successModal,
  successModalActions,
  unSuccessModal,
  unSuccessModalActions,
} from "../model";

import { SuccessModal, UnSuccessModal } from "../../ui/modal";
import { HeroImageField } from "../../ui/image-field/hero-image-field";
import { CardImageField } from "../../ui/image-field/card-image-field";

export function NewCityForm(): JSX.Element {
  const [isFormSubmitting, isSuccessModalOpened, isUnSuccessModalOpened] =
    useUnit([$formSubmitting, successModal, unSuccessModal]);

  const history = useHistory();

  return (
    <>
      <Form form={newCityForm}>
        <FormRoot>
          <FormSection>
            <FormLine>
              <InputField
                name="name"
                type="string"
                label="Enter city name *"
                maxLength={50}
              />
              <StateField name="state" label="Select state *" />
            </FormLine>

            <TextAreaField
              name="description"
              label="City description"
              minRows={3}
              maxRows={3}
            />

            <HeroImageField />
            <CardImageField />
          </FormSection>
          <Actions>
            <SubmitButton isSubmitting={isFormSubmitting}>
              Create city
            </SubmitButton>
          </Actions>
        </FormRoot>
      </Form>
      <SuccessModal
        isOpened={isSuccessModalOpened}
        onClose={() => {
          history.push(`/cities`);
          successModalActions.close();
        }}
        message="City created successfully!"
      />
      <UnSuccessModal
        isOpened={isUnSuccessModalOpened}
        onClose={unSuccessModalActions.close}
      />
    </>
  );
}

const Actions = styled.div`
  display: flex;
  justify-content: center;
  ${SubmitButton} {
    width: 322px;
  }
`;

const FormLine = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;

  ${mediaPhone} {
    grid-template-columns: 1fr;
  }
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const FormRoot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
