import styled from "styled-components";
import { useStore } from "effector-react";
import { Box } from "@mui/material";

import { Form } from "shared/lib/form";
import { SubmitButton, InputField } from "shared/ui/ecosystems/forms";
import { mediaPhone } from "shared/ui/styles/media";
import { FormError } from "shared/ui/ecosystems/forms";
import { addUserApiErrorsByStatuses } from "shared/api";

import { InviteSentModal } from "@admin-app/shared/ui/invite-sent-modal";

import {
  newAdminForm,
  $formSubmitting,
  $invitedAdmin,
  resetInvitedAmin,
} from "../model";
import { $serverErrorStatus } from "../model";

export function NewAdminForm(): JSX.Element {
  const isSubmitting = useStore($formSubmitting);
  const errorStatus = useStore($serverErrorStatus);
  const invitedAdmin = useStore($invitedAdmin);

  const error =
    (errorStatus && addUserApiErrorsByStatuses[errorStatus]) ?? "Unknown error";

  return (
    <Root>
      {errorStatus && <FormError>{error}</FormError>}
      <Form form={newAdminForm}>
        <FormFields>
          <InputField
            name="firstName"
            type="string"
            label="First name"
            placeholder="Enter first name *"
          />
          <InputField
            name="lastName"
            type="string"
            label="Last name"
            placeholder="Enter last name *"
          />
          <InputField
            name="email"
            type="email"
            label="Email"
            placeholder="Enter email *"
          />
          <InputField
            name="password"
            type="password"
            label="Password"
            placeholder="Enter password"
          />
        </FormFields>

        <Actions>
          <SubmitButton isSubmitting={isSubmitting}>
            Send invitation
          </SubmitButton>
        </Actions>
      </Form>

      {invitedAdmin && (
        <InviteSentModal
          isOpened
          onClose={resetInvitedAmin}
          email={invitedAdmin.email}
        />
      )}
    </Root>
  );
}

export const FormFields = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px 24px;
  ${mediaPhone} {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${SubmitButton} {
    width: 322px;
  }
  ${mediaPhone} {
    ${SubmitButton} {
      width: 100%;
    }
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  ${FormError} {
    margin-bottom: 18px;
  }
  ${Actions} {
    margin-top: 41px;
  }
  ${mediaPhone} {
    ${Actions} {
      margin-top: 32px;
    }
  }
`;
