import {
  attach,
  createEffect,
  createEvent,
  createStore,
  sample,
} from "effector";

import {
  createApiErrorStatusStore,
  fromApi,
  getReservationSchedule,
  postReservationCollection,
} from "shared/api";
import { Reservation } from "shared/api/types";
import { createOpenAbleState } from "shared/lib/effector-openable-state";
import { getReservationDuration } from "entities/reservations";

import { createOverrideForm } from "./form";
import { editOverrideFx } from "../api";

export const [$reservationEditModalOpened, reservationEditModalActions] =
  createOpenAbleState();
const $reservation = createStore<Reservation | null>(null);
export const $serverErrorStatus = createApiErrorStatusStore(
  postReservationCollection
);

export const overrideEditingStarted = createEvent<Reservation>();
export const modalAddReservationClosed = createEvent();

const SubmitFormFx = attach({
  effect: editOverrideFx,
  source: $reservation.map((reservation) => reservation?.id),
  mapParams: (override, overrideId) => ({
    overrideId,
    ...override,
  }),
});
export const managerReservationEdited = SubmitFormFx.done;
export const $formSubmitting = SubmitFormFx.pending;

$reservation.on(overrideEditingStarted, (_, reservation) => reservation);

const getAvailableItemsFx = attach({
  effect: createEffect(fromApi(getReservationSchedule)),
  source: $reservation.map((reservation) => reservation?.id),
  mapParams: ({ query }, reservationId) => ({
    query: { ...query, reservationId },
  }),
});

export const {
  form: editReservationForm,
  $price,
  $selectedDock,
  $bookingFee,
  $charterFee,
  dateTimeModel,
} = createOverrideForm({
  onSubmit: SubmitFormFx,
  getAvailableItemsFx: getAvailableItemsFx,
  $isFormOpen: $reservationEditModalOpened,
});

sample({
  clock: overrideEditingStarted,
  fn: (reservation) => ({
    dock: reservation.dock["@id"],
    loa: reservation.loa,
    beam: reservation.beam,
    duration: getReservationDuration(reservation),
    date: new Date(reservation.timeFrom),
    isSuperOverride: reservation.isSuperOverride,
    beforeTax: reservation.price.dockPrice,
    time: {
      from: reservation.timeFrom,
      to: reservation.timeTo,
    },
    isCharter: reservation.isCharter,
    comment: reservation.comment,
  }),
  target: [reservationEditModalActions.open, editReservationForm.setValues],
});

sample({
  clock: managerReservationEdited,
  target: reservationEditModalActions.close,
});
